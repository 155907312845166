import { Box, Flex, Icon, Input, useColorModeValue, Badge, Stack } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import Tag from "./Tag";

export interface ITaskTags {
  editMode: boolean;
  setEditMode: any;
  tags: string[];
  setTags: (tags: string[]) => void;
}

const TaskTags: React.FC<ITaskTags> = ({ editMode, setEditMode, tags, setTags }) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
  const iconColor = useColorModeValue('brand.500', 'white');
  // Holds the currently typed tag before hitting space / enter.
  const [currentTag, setCurrentTag] = useState<string>('');
  // Holds the list of all tags.
  const [allTags, setAllTags] = useState<string[]>(!!tags?.length ? tags : []);
  // Holds the reference to the input field used for adding new tags.
  const inputRef = useRef(null);
  /**
   * Handle the event of adding a new tag.
   */
  const handleAddTag = (event: any) => {
    if (!event.target.value) {
      setEditMode(false);
      return;
    };
    setCurrentTag(event.target.value);
  }
  /**
   * Handle the ENTER key press.
   */
  const handleKeyPress = (event: any) => {
    if ((event.charCode == 13 || event.code === 'Enter') && !event.shiftKey) {
      if (!event.target.value) {
        setEditMode(false);
        return;
      };
      setAllTags([...allTags, currentTag]);
      setCurrentTag('');
    }
    if ((event.keyCode == 27 || event.code === 'Escape') && !event.shiftKey) {
      setEditMode(false);
      setTags(allTags);
    }
  }
  useEffect(() => {
    setTimeout(() => {
      inputRef && inputRef.current && inputRef.current.focus();
    }, 0);
  }, [editMode]);

  if ((!tags || !tags.length) && !editMode) {
    return <></>
  }

  return (
    <Flex
      direction='row'
      justifyContent='flex-start'
      alignItems='center'
      w='100%'
      borderTopColor={iconColor}
      onClick={() => setEditMode(true)}
    >
      {/* <Text color={textColor} fontSize='md' fontWeight='700' lineHeight='100%' mr='8px'>Tags:</Text> */}
      {editMode &&
        <Flex direction='row' justifyContent='flex-start' alignItems='center' w='100%'>
          {/* The list of tags. */}
          <Stack direction='row' mr='8px'>
          {!!allTags?.length && allTags.map((tag: string) =>
            <Tag tag={tag} allTags={allTags} setAllTags={setAllTags} />
          )}
          </Stack>
          <Input
            type="text"
            size='sm'
            fontSize='sm'
            borderRadius='10px'
            ref={inputRef}
            value={currentTag}
            onChange={(e: any) => handleAddTag(e)}
            onKeyDown={(e: any) => handleKeyPress(e)}
          />
        </Flex>
      }
      {!editMode &&
        <Stack direction='row'>

          {!editMode && !!allTags?.length && allTags.map((tag: string) =>
            <Tag key={tag} tag={tag} allTags={allTags} setAllTags={setAllTags} />
          )}

        </Stack>
      }
    </Flex>
  )
}

export default TaskTags