// Chakra imports
import { Button, Flex, FormControl, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { useAuth } from 'state/useAuthState';

export default function ChangeEmail() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { personalData, updateProfileEmail } = useAuth();
  const [email, setEmail] = useState<string>('');
  const toast = useToast();
  // Update the user email address.
  const emailUpdate = () => {
    updateProfileEmail(email).then(() => {
      toast({
        title: 'Email verification sent',
        description: "Your email address will be changed as soon as you verified by clicking on the link sent on your current email.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }).catch((e: any) => console.error(e));
  }

  useEffect(() => {
    if (!personalData) return;
    setEmail(personalData.email);
  }, [personalData]);

  if (!personalData) {
    return <></>
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='40px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Change email
        </Text>
        <Text fontSize='md' color={textColorSecondary}>
          For security reasons, changing the email works by sending a link to your current email address for verification. Please follow the instructions in the received email.
        </Text>
      </Flex>
      <FormControl>
        <Flex flexDirection='column'>
          <InputField type='string' mb='25px' id='email' label='Email Address' placeholder='mail@example.com' value={email} onChange={(e: any) => setEmail(e.target.value)} />
        </Flex>
      </FormControl>
      <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => emailUpdate()}>
        Change Email
      </Button>
    </Card>
  );
}
