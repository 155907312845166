/* eslint-disable */ 

import { Box, Flex, Table, Tbody, Text, Thead, Tr, useColorModeValue} from '@chakra-ui/react';
// Custom components  
import Card from 'components/card/Card';
import { useCallback, useEffect, useState } from 'react';
import { IContract } from 'state/Contracts/ContractsService';
import { IContractInvoice } from 'state/Invoices/Dto';
import { InvoicesDb } from 'state/Invoices/InvoicesDb';
import { invoicesService } from 'state/Invoices/InvoicesService';
import { IEntry } from 'state/Tasks/TasksDTO';
import { useAuth } from 'state/useAuthState';
import ContractInvoicesButtons from './ContractInvoicesButtons';
// Assets 
import ContractInvoicesListHead from './ContractInvoicesListHead';
import ContractInvoicesListRow from './ContractInvoicesListRow';
import ContractInvoicesMenu from './ContractInvoicesMenu';

export default function ContractInvoicesList(props: { contract: IContract; tasks: IEntry[] }) {
	// const [ sorting, setSorting ] = React.useState<SortingState>([]);
  const textColor = useColorModeValue("navy.700", "white");
	// const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  const [invoices, setInvoices] = useState<IContractInvoice[]>([]);
  const {usersDisplaynames, personalData} = useAuth();

  const saveList = async (invoices: IContractInvoice[]) => {
    invoicesService.saveInvoices(props.contract.id, invoices)
      .then(() => getInvoices());
  }

  /**
   * It gets all the invoices for the current contract, and
   * then sets the state of the invoices to the result of
   * the function.
   */
  const getInvoices = useCallback(async () => {
    const allInvoices = await invoicesService.getInvoices(
      props.contract,
      usersDisplaynames,
      personalData.userId,
      props.tasks
    );
    setInvoices(allInvoices);
  }, [props.contract, props.tasks]);

  /* A hook that is called when the component is mounted. */
  useEffect(() => {
    if (!usersDisplaynames || !personalData) return;
    // Get the list of invoices.
    getInvoices();
  }, [usersDisplaynames, personalData, props.tasks]);

	return (
		<Card>
			<Flex direction='column' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
				<Flex align={{ lg: 'center' }} justify={{ base: 'space-between' }} w='100%' px='20px' mb='20px'>

          {/* The title. */}
					<Text color={textColor} fontSize='xl' fontWeight='600' lineHeight='100%'>
						Invoices
					</Text>

          <Flex direction='row' justifyContent='space-between' alignItems='center' gap={2}>

            {/* The buttons. */}
            <ContractInvoicesButtons
              invoices={invoices}
              saveListCallback={saveList}
            />
            {/* The menu. */}
            <ContractInvoicesMenu />

          </Flex>
				</Flex>
				<Box>
					<Table variant='simple' color='gray.500'  mt="12px">
						<Thead>
              <Tr>
                <ContractInvoicesListHead label='Description' />
                <ContractInvoicesListHead label='Invoiced date' />
                <ContractInvoicesListHead label='Status' />
                <ContractInvoicesListHead label='Invoiced time' />
                <ContractInvoicesListHead label='Amount' />
                <ContractInvoicesListHead label='Actions' />
              </Tr>
						</Thead>
						<Tbody>
              {invoices.map((invoice: IContractInvoice) =>
                <ContractInvoicesListRow
                  key={invoice.id}
                  contractId={props.contract.id}
                  invoice={invoice}
                  allInvoices={invoices}
                  refreshListCallback={getInvoices}
                />
              )}
						</Tbody>
					</Table>
				</Box>
			</Flex>
		</Card>
	);
}
  