// Chakra imports
import { Flex, Skeleton, Stack } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { TasksHelper } from 'logic/Tasks.helper';
import { TimeHelper } from 'logic/Time.helper';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IContract } from 'state/Contracts/ContractsService';
import { ContractInvoiceStatusEnum, IContractInvoice } from 'state/Invoices/Dto';
import { invoicesService } from 'state/Invoices/InvoicesService';
import { IEntry } from 'state/Tasks/TasksDTO';
import { TasksStore } from 'state/Tasks/TasksStore';
import { IUserDisplayname, useAuth } from 'state/useAuthState';
import { useContracts } from 'state/useContractsState';
import { IUserProfile, usersService } from 'state/Users/UsersService';
import InvoiceBanner from './components/InvoiceBanner';
import InvoiceContent, { IInvoiceItem } from './components/InvoiceContent';
import { addDays } from 'date-fns';

export default function ContractInvoiceView() {
  const [invoice, setInvoice] = useState<IContractInvoice>(null);
  const [contract, setContract] = useState<IContract>(null);
  const [tasks, setTasks] = useState<IEntry[]>([]);
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [status, setStatus] = useState<ContractInvoiceStatusEnum>(null);
  const [sellerInformation, setSellerInformation] = useState<any>(null);
  const [total, setTotal] = useState<number>(12384);
  const [dueDate, setDueDate] = useState<number>(1682948259000);
  const [buyerInformation, setBuyerInformation] = useState<any>(null);
  const [items, setItems] = useState<IInvoiceItem[]>([]);
  const [isReady, setIsReady] = useState<boolean>(false);

  const { allContracts } = useContracts();
  const { usersDisplaynames, personalData } = useAuth();

  let { contractId, year, month } = useParams();

  const tasksStore = new TasksStore();
  const timeHelper = new TimeHelper();
  const tasksHelper = new TasksHelper();

  const getInformation = (userId: string, setter: any) => {
    // Get the name to be displayed for the current contract.
    usersService.getUserData(userId)
      .then((userData: IUserProfile) => {
        setter({
          businessName: userData.businessName,
          fullName: userData.displayName,
          street: userData.street,
          city: userData.city,
          country: userData.country,
          zip: userData.zip,
          companyRegistrarNr: userData.companyRegistrarNr,
          vatCode: userData.vatCode,
          iban: userData.iban,
          bankName: userData.bankName,
          bankSwift: userData.bankSwift,
        })
      });
  }

  /**
   * Get all the tasks for the provided
   * date period.
   *
   * @param date Date
   * @returns void
   */
  const getTasksForDate = useCallback(async (date: Date) => {
    // Get the start and end dates for the current tasks period.
    const period = timeHelper.getPeriodForProvidedDate(date);
    const allTasks = await tasksStore.getTasksByContractForPeriod(contractId, period[0], period[1])
    const tasksWithTotalTimeSpent = tasksHelper.cumulateTodoMinutesForTasks(allTasks);
    setTasks(tasksWithTotalTimeSpent);
    // Find the current contract.
    const foundContract = allContracts.find((c: IContract) => c.id === contractId);
    setContract(foundContract);
  }, [contractId, month, year, allContracts]);

  const getMissingZeroes = (missing: number): string => {
    let result = '';
    for (let i = 0; i < missing; i++) {
      result += 0;
    }
    return result;
  }

  /**
   * Get the required data for the current
   * invoice and current seller and buyer.
   */
  const getInvoiceData = async (contract: IContract, usersDisplaynames: IUserDisplayname[], userId: string, tasks: IEntry[]) => {
    invoicesService
      .getInvoice(contract, usersDisplaynames, userId, tasks)
      .then((invoiceData: IContractInvoice) => {
        setInvoice(invoiceData);
        const number = `${invoiceData.totalInvoices}`;
        const missingDigits = 4 - number.length;
        setInvoiceNumber(`${personalData.invoicePrefix}${getMissingZeroes(missingDigits)}${number}`);
        setStatus(invoiceData.status);
        setTotal(invoiceData.amount);
        setDueDate(addDays(invoiceData.invoiceDate, 30).getTime());
        // Get the seller and buyer infos.
        getInformation(contract.sellerId, setSellerInformation);
        getInformation(contract.buyerId, setBuyerInformation);
        // Generate the items to show on the invoice (for now only one).
        const item = {
          id: 1,
          label: personalData.invoiceItemLabel,
          quantity: invoiceData.timeSpent,
          rate: invoiceData.rate
        };
        setItems([item]);
        setIsReady(true);
      })
  }

  useEffect(() => {
    if (!contract || !tasks.length) return;
    getInvoiceData(contract, usersDisplaynames, personalData.userId, tasks);
  }, [contract, tasks]);

  /**
   * Get the contract object and the list of
   * tasks required to generate the invoice.
   */
  useEffect(() => {
    getTasksForDate(new Date(`${year}-${month}-1`))
  }, [contractId, month, year]);

  if (!isReady) {
    return (
      <Card mt={{ base: '130px', md: '80px', xl: '80px' }} maxW='920px' mx='auto'>
        <Stack width="100%" gap={5}>
          <Skeleton height='20px' />
          <Skeleton height='20px' />
          <Skeleton height='20px' />
        </Stack>
      </Card>
    )
  }

  console.log(invoice);

  return (
    <Card mt={{ base: '130px', md: '80px', xl: '80px' }} maxW='920px' mx='auto'>
      <Flex direction='column' width='stretch'>

        <InvoiceBanner
          number={invoiceNumber}
          status={status}
          sellerInformation={sellerInformation}
        />

        <InvoiceContent
          total={total}
          dueDate={dueDate}
          buyerInformation={buyerInformation}
          items={items}
          note={personalData.invoiceNote || '---'}
        />

      </Flex>
    </Card>
  );
}
