import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { useAuth } from "./useAuthState";
import { onSnapshot, QuerySnapshot, DocumentData, Unsubscribe } from "firebase/firestore";
import { ContractsDB } from "./Contracts/ContractsDb";
import { IContract } from "./Contracts/ContractsService";
import ContractHelper from "logic/contract.helper";
import { preferenceService } from "./LocalStorage/preference.service";

export const ContractsContext = createContext(null);
export const useContracts = () => useContext(ContractsContext);

const ContractProvider = (props: { children: any }) => {
  const CONTRACT_KEY = '_selected_contract';
  // Helps with quantifier DB operations.
  const contractsDB = new ContractsDB();
  // Helps with contract methods.
  const contractHelper = new ContractHelper();
  /**
   * Holds the list of all contracts on which the current
   * user is listed as a seller of a service.
   */
  const [contracts, setContracts] = useState<IContract[]>([]);
  // Holds the newly requested service contracts.
  const [newRequests, setNewRequests] = useState<IContract[]>([]);
  // Holds the currently selected quantifier.
  const [currentContract, setCurrentContract] = useState<IContract>(null);
  // Holds all the contracts (buyer and seller) for current user.
  const [allContracts, setAllContracts] = useState<IContract[]>([]);
  // Get the currently logged in user.
  const { currentUser, personalData } = useAuth();
  // Indicates if the current user is a BUYER of services.
  const [buyerContracts, setBuyerContracts] = useState<IContract[]>([]);
  // Indicates if the current user is a SELLER of services.
  const [sellerContracts, setSellerContracts] = useState<IContract[]>([]);
  /**
   * Holds the list of all subscriptions from
   * which we need to unsubscribe when this
   * provider unloads.
   */
  const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);

  /**
   * Read the list of quantifiers and setup a
   * snapshot so we can have live updates.
   */
  useEffect(() => {
    if (!currentUser) {
      return
    }

    const queryBySeller = contractsDB.getQueryBySellerId(currentUser.uid);
    const queryByBuyer = contractsDB.getQueryByBuyerId(currentUser.uid);

    /**
     * Create the callback that subscribes to changes on
     * the current user as a SELLER on the contracts collection.
     */
    const unsubscribeFromSellerListener = onSnapshot(queryBySeller, (snapshot: QuerySnapshot<DocumentData>) => {
      const foundContracts = snapshot.docs.map((contract: any) => ({
        ...contract.data(),
        id: contract.id
      }));
      setContracts(foundContracts);
      setSellerContracts(foundContracts);
      /**
       * Fetch the default quantifier.
       */
      const preselected = getSelectedContract(currentUser.uid, foundContracts);
      setCurrentContract(preselected || foundContracts[0]);
    });
    
    /**
     * Create the callback that subscribes to changes on
     * the current user as a SELLER on the contracts collection.
     */
    const unsubscribeFromBuyerListener = onSnapshot(queryByBuyer, (snapshot: QuerySnapshot<DocumentData>) => {
      const foundContracts = snapshot.docs.map((contract: any) => ({
        ...contract.data(),
        id: contract.id
      }));
      setBuyerContracts(foundContracts);
    });

    setSubscriptions([unsubscribeFromSellerListener, unsubscribeFromBuyerListener]);

    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [currentUser, personalData]);

  useEffect(() => {
    setAllContracts([...buyerContracts, ...sellerContracts]);
  }, [buyerContracts, sellerContracts]);

  /**
   * Make sure to save locally the currently
   * selected quantifier each time it changes.
   */
  useEffect(() => {
    if (!currentContract) return;
    preferenceService.savePreference(currentUser.uid, CONTRACT_KEY, currentContract);
  }, [currentContract]);
  /**
   * Fetch the locally stored quantifier or if not there
   * then the quantifier marked as default inside the DB.
   *
   * @param uid string
   * @param allQuantifiers IQuantifier[]
   * @returns IQuantifier
   */
  const getSelectedContract = (uid: string, allContracts: IContract[]): IContract => {
    let preselectedContract: IContract = null;
    // Check if there is a quantifier stored as preference in the browser localstorage.
    preselectedContract = preferenceService.getPreference(uid, CONTRACT_KEY);
    // Check if there is a quantifier marked as default in the system.
    if (!preselectedContract) {
      preselectedContract = allContracts.find((c: IContract) => !!c.default);
    }
    return preselectedContract;
  }

  const value: {
    currentContract: IContract,
    setCurrentContract: any,
    contracts: IContract[],
    buyerContracts: IContract[],
    sellerContracts: IContract[],
    allContracts: IContract[],
    newRequests: IContract[],
  } = {
    currentContract,
    setCurrentContract,
    contracts,
    buyerContracts,
    sellerContracts,
    allContracts,
    newRequests,
  }

  return (
    <ContractsContext.Provider value={value}>
      {props.children}
    </ContractsContext.Provider>
  )
}

export default ContractProvider