export class DateChecker {
  private lastDate: Date | null = null;
  private lastResult: boolean | null = null;

  hasDateChanged(newDate: Date): boolean {
    // If this is the first time the method is called, set the lastDate and return false.
    if (this.lastDate === null) {
      this.lastDate = newDate;
      this.lastResult = false;
      return false;
    }

    // Compare the newDate with the lastDate.
    const hasChanged = newDate.getTime() !== this.lastDate.getTime();

    // Update the lastDate with the newDate.
    this.lastDate = newDate;

    // If the date has changed, return true or false alternatively.
    if (hasChanged) {
      this.lastResult = !this.lastResult;
      return this.lastResult;
    }

    // If the date hasn't changed, return the last result.
    return this.lastResult;
  }
}