import { Box, Flex, FormControl, Select, FormLabel, Icon, Input, Table, Tbody, Td, Text, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import { useContracts } from 'state/useContractsState';
import { IUserProfile, usersService } from 'state/Users/UsersService';
import { ChangeTasksActions } from './ChangeTasksActions';
import { ChangeTasksForm } from './ChangeTasksForm';
import { ListOfTasks } from './ListOfTasks';
import { ListOfTasksByMonth } from './ListOfTasksByMonth';

// const columns = columnsDataCheck;
export default function ChangeTasks() {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColorError = useColorModeValue('red.500', 'white');
  const iconColorSuccess = useColorModeValue('green.500', 'white');
  const { contracts } = useContracts();
  // Holds the list of fetched tasks.
  const [tasks, setTasks] = useState<IEntry[]>([]);
  // Holds the entered contractId to be replaced.
  const [oldContractId, setOldContractId] = useState<string>('');
  // Holds the userId for which we want to change the tasks form.
  const [userId, setUserId] = useState<string>('');
  // Holds the list of all existing users.
  const [users, setUsers] = useState<IUserProfile[]>([]);
  // Holds the contractId we want to use as replacement.
  const [newContractId, setNewContractId] = useState<string>('');
  // Holds the list of tasks that will be changed.
  const [tasksToChange, setTasksToChange] = useState<IEntry[]>([]);

  const getAllUsers = () => {
    usersService.fetchAll().then((allUsers: IUserProfile[]) => {
      setUsers(allUsers);
    });
  }

  useEffect(() => {
    getAllUsers();
  }, [contracts]);

  return (
    <Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
      <Flex px='25px' mb="8px" justifyContent='space-between' align='center' direction={{ sm: 'column', md: 'row' }}>
        <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
          Change the contract to which tasks are linked to
        </Text>

        {/* Shows the available action buttons. */}
        <ChangeTasksActions
          newContractId={newContractId}
          setNewContractId={setNewContractId}
          oldContractId={oldContractId}
          setOldContractId={setOldContractId}
          userId={userId}
          setUserId={setUserId}
          tasks={tasks}
          setTasks={setTasks}
          tasksToChange={tasksToChange}
          setTasksToChange={setTasksToChange}
        />
      </Flex>

      {/* Contains the input fields. */}
      <ChangeTasksForm
        oldContractId={oldContractId}
        newContractId={newContractId}
        contracts={contracts}
        users={users}
        userId={userId}
        setUserId={setUserId}
        setOldContractId={setOldContractId}
        setNewContractId={setNewContractId}
      />

      {/* Shows the total amount of tasks found with the provided criteria. */}
      <Flex px='25px' mb="16px" flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Text color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>
          Found possible batch changes: {tasks?.length}
        </Text>
      </Flex>

      {/* Shows the stats for the tasks that are marked for change. */}
      <Flex px='25px' mb="16px" flexDirection='row' justifyContent='space-between' alignItems='center'>
        <Text color={textColor} fontSize='14px' fontWeight='700' lineHeight='100%'>
          Number of tasks marked for change: {tasksToChange?.length}
        </Text>
      </Flex>


      {tasks?.length !== 0 &&
        <ListOfTasksByMonth
          tasks={tasks}
          newContractId={newContractId}
          tasksToChange={tasksToChange}
          setTasksToChange={setTasksToChange}
        />
      }
    </Card>
  );
}
