import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  where,
  deleteDoc,
  Query,
  onSnapshot,
  QuerySnapshot,
  getDoc,
} from 'firebase/firestore';
import { TasksStore } from 'state/Tasks/TasksStore';
import { db } from '../firebase';
import { ContractsQueries } from './ContractsQueries';
import { IContract } from './ContractsService';

export class ContractsDB extends ContractsQueries {

  constructor() {
    super();
  }

  /**
   * Mark the provided contract id as accepted by
   * the seller of the service.
   *
   * @param contractId string
   * @returns Promise<void>
   */
  public async acceptRequestInDb(contractId: string): Promise<void> {
    const docRef = doc(db, 'contracts', contractId);
    return await updateDoc(docRef, { acceptedDate: new Date().getTime(), accepted: true });
  }

  /**
   * Mark the provided contract id as rejected by
   * the seller of the service.
   *
   * @param contractId string
   * @returns Promise<void>
   */
  public async rejectRequestInDb(contractId: string): Promise<void> {
    const docRef = doc(db, 'contracts', contractId);
    return await updateDoc(docRef, { rejectedDate: new Date().getTime(), accepted: false });
  }

  /**
   * Get the list of contracts that match the provided buyerId
   * and sellerId.
   *
   * @param buyerId string
   * @param sellerId string
   * @returns Promise<IContract[]>
   */
  public async getContractsByBuyerAndSellerId(buyerId: string, sellerId: string): Promise<IContract[]> {
    const query = this.getQueryByBuyerAndSellerId(buyerId, sellerId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((contract: any) => ({ ...contract.data(), id: contract.id }))
    );
  }

  public async findDuplicateContracts(buyerId: string, sellerId: string, rate: number, hoursPerDay: number): Promise<IContract[]> {
    const query = this.getQueryByBuyerSellerRateAndHoursPerDay(buyerId, sellerId, rate, hoursPerDay);
    return await getDocs(query).then((response: any) =>
      response.docs.map((contract: any) => ({ ...contract.data(), id: contract.id }))
    );
  }

  /**
   * Return the list of buyer contracts the provided
   * userId has.
   * 
   * @param userId string
   * @returns 
   */
  public async getBuyContracts(userId: string): Promise<IContract[]> {
    const query = this.getQueryByBuyerId(userId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((contract: any) => ({ ...contract.data(), id: contract.id }))
    );
  }

  /**
   * Return the list of seller contracts the provided
   * userId has.
   * 
   * @param userId string
   * @returns 
   */
  public async getSellContracts(userId: string): Promise<IContract[]> {
    const query = this.getQueryBySellerId(userId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((contract: any) => ({ ...contract.data(), id: contract.id }))
    );
  }

  /**
   * Fetch a contract using the provided
   * seller id.
   *
   * @param string string
   * @returns Promise<DocumentData>
   */
  public async fetchBySellerId(id: string): Promise<IContract[]> {
    // Fetch the data using the timestamp.
    return await getDocs(this.getQueryBySellerId(id))
      .then((response: any) => {
        const foundContract = response.docs.find((doc: any) => doc.data().sellerId === id);
        return foundContract ? { ...foundContract.data(), uid: foundContract.id } : null;
      })
      .catch((error: any) => console.error(error));
  }

  /**
   * Fetch a contract using the
   * provided buyer id.
   *
   * @param id string
   * @returns Promise<DocumentData>
   */
  public async fetchByBuyerId(id: string): Promise<IContract[]> {
    // Fetch the data using the timestamp.
    return await getDocs(this.getQueryByBuyerId(id))
      .then((response: any) => {
        const foundContract = response.docs.find((doc: any) => doc.data().buyerId === id);
        return foundContract ? { ...foundContract.data(), uid: foundContract.id } : null;
      })
      .catch((error: any) => console.error(error));
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async addInDb(contract: IContract): Promise<any> {
    const collectionRef = this.getCollection();
    return await addDoc(collectionRef, contract)
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateInDb(id: string, contract: IContract): Promise<any> {
    const docRef = doc(db, 'contracts', id);
    return await updateDoc(docRef, { ...contract });
  }

  /**
   * Delete the note with the provided id.
   *
   * @param id string
   * @returns Promise<any>
   */
  public async deleteInDb(id: string): Promise<any> {
    const docRef = doc(db, 'contracts', id);
    return await deleteDoc(docRef);
  }
}
