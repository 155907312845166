// Chakra imports
import {
	Avatar,
	Box,
	Button,
	Flex,
	Icon,
	IconButton,
	Image,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Text,
	useColorModeValue,
	useDisclosure
} from '@chakra-ui/react';
import { IoPaperPlaneOutline } from 'react-icons/io5';
import { MdOutlineFavoriteBorder } from 'react-icons/md';

export default function Participant(props: {
	username?: string;
	sum?: string | number;
	avatar?: string;
	name?: string;
	action: () => any;
	image?: string;
	[x:string]:any
}) {
	const { username, sum, avatar, name, action, image, ...rest } = props;

	const { isOpen, onOpen, onClose } = useDisclosure();
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const borderColor = useColorModeValue('white !important', '#111c44 !important');
	return (
		<Button
			bg='transparent'
			variant='no-hover'
			fontWeight='700'
			display='flex'
			h='max-content'
			w='max-content'
			minW='max-content'
			boxShadow='unset'
			onClick={onOpen}
			{...rest}>
			<Flex direction='column' justifyContent='center' alignItems='center' w='100%'>
				<Flex
					mx='auto'
					h='max-content'
					w='max-content'
					p='2px'
					borderRadius='50%'
					bg='linear-gradient(109.6deg, #FF9966 17.44%, #FF5E62 78.63%)'>
					<Avatar border='3px solid' borderColor={borderColor} h='50px' w='50px' src={avatar} />
				</Flex>
				{name ? (
					<Text mt='10px' textAlign='center' color={textColor} fontSize='sm' fontWeight='500'>
						{name}
					</Text>
				) : null}
			</Flex>
		</Button>
	);
}
