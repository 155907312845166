import { Badge, Button, Flex, Icon, Stack } from "@chakra-ui/react"
import { useEffect, useState } from "react";
import { MdDownload, MdPaid } from 'react-icons/md';
import { ContractInvoiceStatusEnum } from "state/Invoices/Dto";
import { IInvoiceStatusDefinition, invoicesService } from "state/Invoices/InvoicesService";

export const InvoiceBannerActions = (props: {
  downloadCallback: any,
  paidCallback: any,
  status: ContractInvoiceStatusEnum,
}) => {
  const bgButton = 'rgba(255,255,255,0.12)';
  const bgHover = { bg: 'whiteAlpha.50' };
  const bgFocus = { bg: 'rgba(255,255,255,0.12)' };

  // Get the status definition for the current status.
  const [definition, setDefinition] = useState<IInvoiceStatusDefinition>(null);
  

  useEffect(() => {
    if (!props.status) return;
    setDefinition(invoicesService.getStatusDefinition(props.status));
  }, [props.status]);

  if (!definition) {
    return <></>;
  }

  console.log('definition:', definition);

  return (
    <Flex
      direction={{ sm: 'column', md: 'row' }}
      justifyContent={{ sm: 'flex-start', md: 'space-between' }}
      alignItems={{ sm: 'flex-start', md: 'center' }}
    >
      <Badge
        w='max-content'
        mb='10px'
        fontSize='sm'
        colorScheme={definition.color}
        fontWeight='bold'>
        {definition.label}
      </Badge>

      {/* Holds the action buttons. */}
      <Stack direction="row" justifyContent="flex-end" alignItems="center">
        <Button
          alignItems='center'
          me='10px'
          justifyContent='center'
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          p='7px'
          minW='unset'
          h='32px'
          lineHeight='100%'
          borderRadius='10px'
          onClick={() => props.downloadCallback()}>
          <Icon as={MdDownload} color='white' w='18px' h='18px' />
        </Button>
        <Button
          alignItems='center'
          justifyContent='center'
          bg='linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)'
          _hover={{
            bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)'
          }}
          _focus={{
            bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)'
          }}
          _active={{
            bg: 'linear-gradient(293.45deg, #FA709A 0%, #FEE140 92.27%)'
          }}
          p='7px'
          minW='unset'
          h='32px'
          lineHeight='100%'
          borderRadius='10px'
          onClick={() => props.paidCallback()}>
          <Icon as={MdPaid} color='white' w='18px' h='18px' />
        </Button>
      </Stack>
    </Flex>
  )
}