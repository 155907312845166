import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  where,
  deleteDoc,
  Query,
  writeBatch,
  WriteBatch,
} from "firebase/firestore";
import { db } from "../firebase";
import { IEntry, IEntryTodo } from "./TasksDTO";
import { TasksQueries } from "./TasksQueries";

export class TasksDB extends TasksQueries {
  constructor() {
    super();
  }

  /**
   * Get the notes query by created at.
   *
   * @returns Query<DocumentData>
   */
  public async getNotesByContractForPeriod(
    contractId: string,
    start: number,
    end: number
  ): Promise<DocumentData> {
    const query = this.getNotesQueryByContractForPeriod(contractId, start, end);
    // Fetch the data using the timestamp.
    return await getDocs(query)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Fetch the document data from
   * the database. This data still
   * needs to be formatted.
   *
   * @returns Promise<DocumentData>
   */
  public async fetchAllInDb(): Promise<DocumentData> {
    const notesCollectionRef = collection(db, "notes");
    const queryByDate = query(notesCollectionRef, orderBy("created", "desc"));
    // Fetch the data using the timestamp.
    return await getDocs(queryByDate)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Fetch the document data from
   * the database. This data still
   * needs to be formatted.
   *
   * @returns Promise<DocumentData>
   */
  public async fetchByContract(contractId: string): Promise<DocumentData> {
    const notesCollectionRef = collection(db, "notes");
    const queryByDate = query(
      notesCollectionRef,
      where("quantifierId", "==", contractId),
      orderBy("created", "desc")
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByDate)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Fetch all notes for a given contract and user
   * @param {string} contractId - The id of the contract that the note is associated with.
   * @param {string} userId - The userId of the user who created the note.
   * @returns An array of objects.
   */
  public async fetchByContractAndUser(
    contractId: string,
    userId: string
  ): Promise<DocumentData> {
    const notesCollectionRef = collection(db, "notes");
    const queryByDate = query(
      notesCollectionRef,
      where("quantifierId", "==", contractId),
      where("userId", "==", userId),
      orderBy("created", "desc")
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByDate)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Fetch all notes for a given contract and user
   * @param {string} contractId - The id of the contract that the note is associated with.
   * @param {string} userId - The userId of the user who created the note.
   * @returns An array of objects.
   */
  public async fetchByIds(
    ids: string[],
  ): Promise<DocumentData> {
    const notesCollectionRef = collection(db, "notes");
    const queryByDate = query(
      notesCollectionRef,
      where("quantifierId", "in", ids),
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByDate)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Fetch the list of notes that where created during the
   * provided date.
   *
   * @param date Date
   * @returns Promise<DocumentData>
   */
  public async fetchByDayInDb(date: Date): Promise<DocumentData> {
    const notesCollectionRef = collection(db, "notes");
    // Get the date with hour 00:00.
    const startTimestamp = date.setHours(0, 0, 0, 0);
    const endTimestamp = date.setHours(23, 59, 59);
    // Create our query to fetch the notes by the provided date.
    const queryContraints = [];
    queryContraints.push(where("created", ">", startTimestamp));
    queryContraints.push(where("created", "<", endTimestamp));
    const queryByDate = query(
      notesCollectionRef,
      ...queryContraints,
      orderBy("created", "desc")
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByDate)
      .then((response: any) => response)
      .catch((error: any) => console.log(error));
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async addInDb(note: IEntry): Promise<any> {
    const notesCollectionRef = collection(db, "notes");
    return await addDoc(notesCollectionRef, note)
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateInDb(noteId: string, data: any): Promise<any> {
    const docRef = doc(db, "notes", noteId);
    return await updateDoc(docRef, { ...data });
  }

  /**
   * Batch operation for updating multiple
   * NOTES documents in the db.
   *
   * @param notes IEntry[]
   * @param oldContractId string
   * @param userId string
   * @returns Promise<any>
   */
  public async updateMultipleInDb(
    notes: IEntry[],
    oldContractId: string,
    userId: string,
    isTest?: boolean
  ): Promise<any> {
    const batches: WriteBatch[] = [];
    batches.push(writeBatch(db));
    // Prepare a list of note ids.
    const ids = notes.map((note: IEntry) => note.id);
    // Get the list of notes from the DB.
    return this.fetchByContractAndUser(oldContractId, userId)
      .then((response: DocumentData) => {
        // Parse through the document data of each returned note from DB.
        const requiredNotesFromDb = response.docs.filter((note: any) => ids.includes(note.id));
        requiredNotesFromDb.forEach((noteFromDb: any, index: number) => {
          // If we passed the limit of 500 then re-init the batch object.
          if (index === 500) {
            batches.push(writeBatch(db));
          }
          // Find the corresponding note object in the provided array.
          const foundNote = notes.find(
            (currentNote: IEntry) => currentNote.id === noteFromDb.id
          );
          foundNote.batchJobComplete = true;
          if (!isTest) {
            batches[batches.length - 1].update(noteFromDb.ref, {
              ...foundNote,
            });
          }
        });
        if (!isTest) {
          // Parse through the batches and commit them one by one.
          batches.forEach((currentBatch: WriteBatch) =>
            currentBatch.commit().catch((err) => console.error(err))
          );
        }
      })
      .catch((error) => console.error(error));
  }

  /**
   * Batch operation for updating multiple
   * NOTES documents in the db.
   *
   * @param notes IEntry[]
   * @param oldContractId string
   * @param userId string
   * @returns Promise<any>
   */
  public async updateContractIdInDb(
    notes: IEntry[],
    oldContractId: string,
    userId: string,
    isTest?: boolean
  ): Promise<any> {
    const batches: WriteBatch[] = [];
    batches.push(writeBatch(db));
    return this.fetchByContractAndUser(oldContractId, userId)
      .then((response: DocumentData) => {
        // Parse through the document data of each returned note from DB.
        response.docs.forEach((note: any, index: number) => {
          // If we passed the limit of 500 then re-init the batch object.
          if (index === 500) {
            batches.push(writeBatch(db));
          }
          // Find the corresponding note object in the provided array.
          const currentNote = notes.find(
            (currentNote: IEntry) => currentNote.id === note.id
          );
          currentNote.batchJobComplete = true;
          if (!isTest) {
            batches[batches.length - 1].update(note.ref, { ...currentNote });
          }
        });
        if (!isTest) {
          // Parse through the batches and commit them one by one.
          batches.forEach((currentBatch: WriteBatch) =>
            currentBatch.commit().catch((err) => console.error(err))
          );
        }
      })
      .catch((error) => console.error(error));
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateCreatedDateInDb(note: IEntry): Promise<any> {
    const docRef = doc(db, "notes", note.id);
    return await updateDoc(docRef, { created: note.created });
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateTodoInDb(
    noteId: string,
    todos: IEntryTodo[]
  ): Promise<any> {
    const docRef = doc(db, "notes", noteId);
    return await updateDoc(docRef, { todos });
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateCompletedInDb(
    noteId: string,
    completed: boolean
  ): Promise<any> {
    const docRef = doc(db, "notes", noteId);
    return await updateDoc(docRef, { completed });
  }

  /**
   * Update the timeSpent property for the
   * provided note id.
   *
   * @param id string
   * @param minutes number
   * @returns Promise<boolean>
   */
  public async updateTimeSpentInDb(id: string, minutes: number): Promise<any> {
    const docRef = doc(db, "notes", id);
    return await updateDoc(docRef, { timeSpent: minutes });
  }

  /**
   * Delete the note with the provided id.
   *
   * @param id string
   * @returns Promise<any>
   */
  public async deleteInDb(id: string): Promise<any> {
    const docRef = doc(db, "notes", id);
    return await deleteDoc(docRef);
  }
}
