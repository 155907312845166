// Chakra imports
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';

// Assets
import { useEffect, useState } from 'react';
import { InvoiceContentFooter } from './InvoiceContentFooter';
import { InvoiceContentHeader } from './InvoiceContentHeader';
import InvoiceTable from './InvoiceTable/InvoiceTable';

export interface IInvoiceItem {
  id: number;
  label: string;
  quantity: number;
  rate: number;
  total?: number;
}

export interface IInvoiceViewContentProps {
  total: number;
  dueDate: number;
  items: IInvoiceItem[];
  note: string;
  buyerInformation: any;
}

const InvoiceContent: React.FC<IInvoiceViewContentProps> = ({
  total,
  dueDate,
  items,
  note,
  buyerInformation
}) => {
	// Chakra Color Mode
	const [processedItems, setProcessedItems] = useState<IInvoiceItem[]>([]);

  useEffect(() => {
    if (!items.length) return;
    setProcessedItems(items.map((item: IInvoiceItem) => ({ ...item, total: (item.quantity / 60) * item.rate })));
  }, [items]);

	return (
		<Flex direction='column' p={{ base: '10px', md: '60px' }} justifyContent="space-between">

      {buyerInformation &&
        <InvoiceContentHeader
          total={total}
          dueDate={dueDate}
          buyerInformation={buyerInformation}
        />
      }

      {/* The list of items. */}
			{processedItems && <InvoiceTable items={processedItems} />}

			<InvoiceContentFooter
        note={note}
        paid={0}
        total={total}
      />

		</Flex>
	);
}

export default InvoiceContent;
