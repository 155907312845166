import { DocumentData, onSnapshot, QuerySnapshot, Unsubscribe } from "firebase/firestore";
import { IUserProfile, usersService } from "state/Users/UsersService";
import { IProject } from "./Dto";
import { ProjectsDb } from "./ProjectsDb";
import { db } from '../firebase';

class ProjectsService extends ProjectsDb {

  constructor() {
    super();
  }

  public async get(id: string): Promise<IProject> {
    return await this.getByIdInDb(id);
  }

  /**
   * Get a list of projects for which the provided
   * userId is being allowed access to.
   *
   * @param userId string
   * @returns Promise<IProject[]>
   */
  public async getByUserId(userId: string): Promise<IProject[]> {
    return await this.getByUserIdInDb(userId);
  }

  public async getAllowedUsers(id: string): Promise<IUserProfile[]> {
    // Get the list of user ids from the db.
    const project = await this.getByIdInDb(id);
    const userIds = project.userIds;
    // Parse through the list of users and get each user.
    return await usersService.fetchByUserIdsInDb(userIds);
  }

  public async getProjectsSnapshotForUserId(userId: string): Promise<Unsubscribe> {
    const query = this.getQueryProjectsByUserId(userId);
    return onSnapshot(query, (snapshot: QuerySnapshot<DocumentData>) => {
      const foundProjects = snapshot.docs.map(async (project: any) => {

        // db.collection('users').child(doc.data().uid).get().then((userDoc) => {
        //   loadedPosts[doc.id].userName = userDoc.data().name;
        // });
        // Get the user objects.
        usersService.fetchByUserIdsInDb(project.userIds)
         .then((users: IUserProfile[]) => console.log('users on project:', users));

        const currentProject = ({
          id: project.id,
          ...project.data(),
        });

        return currentProject;
      });
      // foundProjects.forEach((project: Promise<IProject>) =>
      //   project.then((proj: IProject) => {
      //     setProjects([...projects, proj])
      //   })
      // );
    });
  }

  public async addProject(contractId: string, project: IProject): Promise<any> {
    return await this.addInDb(contractId, project);
  }

  public async updateProject(id: string, project: IProject): Promise<any> {
    return await this.updateInDb(id, project);
  }

  public async deleteProject(id: string): Promise<any> {
    return await this.deleteInDb(id);
  }

}

export const projectsService = new ProjectsService();