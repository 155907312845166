// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
// Assets
import { useAppContext } from 'state/useAppState';
import { useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import { useAuth } from 'state/useAuthState';
import ChangeTasks from 'components/AdminComponents/ChangeContractIdForNotes/ChangeTasks';

export default function AdministratorDashboard() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate: filteredTasks } = useAppContext();
  const { roles } = useAuth();
  const [tasks, setTasks] = useState<IEntry[]>([]);

  useEffect(() => {
    if (!filteredTasks) return;
    setTasks(filteredTasks);
  }, [filteredTasks]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      {roles.includes('admin')  &&
        <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap='20px' mb='20px'>

          {/* Show the list of buyer contracts the current user has with some statistics. */}
          <ChangeTasks />

        </SimpleGrid>
      }

    </Box>
  );
}
