import { TicketsQueries } from "./TicketsQueries";
import { addDoc, deleteDoc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { ITicket } from "./Dto";

export class TicketsDb extends TicketsQueries {

  constructor() {
    super();
  }

  /**
   * It gets a document from the database, and then returns a project object
   * @param {string} id - string - the id of the project you want to get
   * @returns A promise of an IProject
   */
  public async getByIdInDb(id: string): Promise<ITicket> {
    const docSnapshot = await getDoc(this.getQueryDocById(id));
    const docData = docSnapshot.data();
    return this.mapTicket(id, docData);
  }

  public async getAllByProjectIdInDb(projectId: string): Promise<ITicket[]> {
    const query = this.getQueryAllByProjectId(projectId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((ticket: any) => this.mapTicket(ticket.id, ticket.data()))
    );
  }

  public async getAllByProjectsIdInDb(projectIds: string[]): Promise<ITicket[]> {
    const query = this.getQueryAllByProjectIds(projectIds);
    return await getDocs(query).then((response: any) =>
      response.docs.map((ticket: any) => this.mapTicket(ticket.id, ticket.data()))
    );
  }

  public async getAllByParentIdInDb(id: string): Promise<ITicket[]> {
    const query = this.getQueryAllByParentId(id);
    return await getDocs(query).then((response: any) =>
      response.docs.map((ticket: any) => this.mapTicket(ticket.id, ticket.data()))
    );
  }

  public async getAllBlockedByIdsInDb(ids: string[]): Promise<ITicket[]> {
    const query = this.getQueryAllByBlockingIds(ids);
    return await getDocs(query).then((response: any) =>
      response.docs.map((ticket: any) => this.mapTicket(ticket.id, ticket.data()))
    );
  }

  public async getAllByStateIdInDb(stateId: string): Promise<ITicket[]> {
    const query = this.getQueryAllByStateId(stateId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((ticket: any) => this.mapTicket(ticket.id, ticket.data()))
    );
  }

  public async addInDb(projectId: string, ticket: ITicket): Promise<any> {
    return await addDoc(this.getCollection(), { ...ticket, projectId })
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  public async updateInDb(id: string, ticket: ITicket): Promise<any> {
    return await updateDoc(this.getQueryDocById(id), { ...ticket });
  }

  public async deleteInDb(id: string): Promise<any> {
    return await deleteDoc(this.getQueryDocById(id));
  }

  private mapTicket(id: string, docData: any): ITicket {
    return ({
      id,
      ...docData
    })
  }
}