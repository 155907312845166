import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
  useToast
} from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useEffect, useState } from 'react';
import { ContractsService, IContract } from 'state/Contracts/ContractsService';
import { useAppContext } from 'state/useAppState';
import { useAuth } from 'state/useAuthState';
import { useContracts } from 'state/useContractsState';
import { IUserProfile, usersService } from 'state/Users/UsersService';
import { SingleDatepicker } from 'chakra-dayzed-datepicker';
import { addMonths } from 'date-fns';

const ContractEdit = () => {
  // Set the text color.
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const { contracts } = useContracts();
  const { setModalState } = useAppContext();
  const contractsService = new ContractsService();
  // Get the current user object.
  const { personalData } = useAuth();
  // Holds the list of all existing users.
  const [sellers, setSellers] = useState<IUserProfile[]>([]);
  // Indicates if the contract between the buyer and seller already exists.
  const [message, setMessage] = useState<string>('');
  // Holds the set values.
  const [name, setName] = useState<string>('');
  // Holds the userId for which we want to change the tasks form.
  const [sellerId, setSellerId] = useState<string>('');
  // Holds the hourly rate.
  const [rate, setRate] = useState<string>('');
  // Holds the hours per day.
  const [hoursPerDay, setHoursPerDay] = useState<string>('');
  // Holds the contract end date.
  const [endDate, setEndDate] = useState<Date>(addMonths(new Date(), 6));
  const toast = useToast();

  /**
   * Get the list of all the existing service
   * sellers that are a valid option for the
   * current buyer.
   */
  const getAllUsers = () => {
    usersService.fetchAll().then((allUsers: IUserProfile[]) => {
      setSellers(allUsers);
    });
  }

  /**
   * Wait for the contracts context to load
   * then fetch the list of all available
   * users.
   */
  useEffect(() => {
    getAllUsers();
  }, [contracts]);

  const showSuccessToast = () => {
    toast({
      title: 'New service requested',
      description: "We've created your service request.",
      status: 'success',
      duration: 9000,
      isClosable: true,
    });
  }

  /**
   * Submits the new contract data to the backend
   * and the contracted user will receive a notification.
   */
  const handleSubmit = async () => {
    if (!sellerId || !rate || !hoursPerDay) {
      toast({
        title: 'Incomplete',
        description: "Please fill out all required fields",
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    }
    // Create the contract object to submit.
    const newContract: IContract = {
      buyerId: personalData.userId,
      sellerId,
      created: new Date().getTime(),
      rate: parseInt(rate, 10),
      hoursPerDay: parseInt(hoursPerDay, 10),
      name,
      default: false,
      type: 'service request',
      acceptedDate: 0,
      endDate: endDate ? endDate.getTime() : 0,
    };
    /**
     * Check if the new contract with the selected
     * buyerId and sellerId exists already and warn
     * the user about it.
     */
    const foundSimilarContracts = await contractsService.findDuplicate(newContract.buyerId, newContract.sellerId, newContract.rate, newContract.hoursPerDay);
    // Set the alert if it's the case.
    setMessage(foundSimilarContracts && `You already have ${foundSimilarContracts.length} contract with the selected seller!`);
    console.log('found similar:', foundSimilarContracts);
    console.log(newContract);
    if (foundSimilarContracts.length) {
      return;
    }
    // Save the data into the DB.
    contractsService.add(newContract);
    // Show the success toast.
    showSuccessToast();
    // Close the modal.
    setModalState(null);
  }

  return (
    <Card p='20px'>
      {/* <Text color={textColor} fontSize='2xl' fontWeight='700' mb='20px'>
        General
      </Text> */}
      {/* Show alerts if any. */}
      {message &&
        <Alert borderRadius='8px' status='error' variant='solid' mb='20px'>
          <AlertIcon />
          <AlertTitle mr='14px'>{message}</AlertTitle>
          <CloseButton
            position='absolute'
            fontSize={{ sm: '8px', md: '12px' }}
            right={{ sm: '-4px', md: '8px' }}
            top={{ sm: '-4px', md: '8px' }}
            onClick={() => setMessage('')}
          />
        </Alert>
      }

      {/* The explanations. */}
      <Flex direction='column' bg={boxBg} p='16px 20px' borderRadius='14px' mb='20px'>
        <Text fontSize='sm' fontWeight='700' color={textColor}>
          Requesting a paid / unpaid service from a provider.
        </Text>
        <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
          The new contract will be created between you and a service provider entity (freelance, etc.).
          After the contract has been created, the service provider will have the chance to review and accept it.
        </Text>
      </Flex>

      <Flex direction='column' w='100%'>

        <Stack direction='column' spacing='20px'>
          <SimpleGrid columns={{ base: 1, md: 1 }} gap='20px'>

            {/* The name of the new contract. */}
            <InputField
              type='string'
              mb='0px'
              id='name'
              placeholder='Contract name...'
              label='Name*:'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
            />

            {/* The seller with which the contract is made. */}
            <FormControl mr='8px'>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                alignItems='center'
                mb='8px'>Select an existing seller*:
              </FormLabel>
              <Select
                fontSize='sm'
                ms={{ base: "0px", md: "0px" }}
                fontWeight='500'
                size='lg'
                placeholder='Select seller'
                value={sellerId}
                onChange={(e: any) => {
                  console.log(e);
                  setSellerId(e.target.value);
                }}
              >
                {sellers.map((user: IUserProfile) =>
                  <option key={user.uid} value={user.userId}>{user.firstName} {user.lastName} {user.businessName && `(${user.businessName})`}</option>
                )}
              </Select>
            </FormControl>

            {/* The hourly rate field. */}
            <InputField
              type='string'
              id='rate'
              placeholder='45€'
              label='Hourly rate*:'
              mb='0px'
              value={rate}
              onChange={(e: any) => setRate(e.target.value)}
            />

            {/* The number of hours per day field. */}
            <InputField
              type='string'
              id='hours_per_day'
              placeholder='8'
              label='Hours per day* (in hours):'
              mb='0px'
              value={hoursPerDay}
              onChange={(e: any) => setHoursPerDay(e.target.value)}
            />

            {/* The end date. */}
            <FormControl mr='8px'>
              <FormLabel
                display='flex'
                ms='4px'
                fontSize='sm'
                fontWeight='500'
                color={textColor}
                alignItems='center'
                mb='8px'>End date:
              </FormLabel>
              <SingleDatepicker
                name='End date'
                date={endDate}
                onDateChange={(d: Date) => setEndDate(d)}
              />
            </FormControl>

          </SimpleGrid>
        </Stack>

        <Flex justify='space-between' mt='24px'>

          {/* Cancel button. */}
          <Button
            variant='light'
            fontSize='sm'
            borderRadius='16px'
            w={{ base: '128px', md: '148px' }}
            h='46px'
            onClick={() => setModalState(null)}>
            Cancel
          </Button>

          {/* Save button. */}
          <Button
            variant='darkBrand'
            fontSize='sm'
            borderRadius='16px'
            w={{ base: '128px', md: '148px' }}
            h='46px'
            ms='auto'
            onClick={() => handleSubmit()}>
            Finish
          </Button>
        </Flex>

      </Flex>
    </Card>
  )
}

export default ContractEdit;