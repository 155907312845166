import MiniStatistics from 'components/card/MiniStatistics';
import IconBox from 'components/icons/IconBox';
import { Icon } from '@chakra-ui/react';
import { MdAddTask } from 'react-icons/md';
import { TasksHelper } from 'logic/Tasks.helper';
import { useAppContext } from 'state/useAppState';
import { useContracts } from 'state/useContractsState';
import { useEffect, useState } from 'react';
import { TimeHelper } from 'logic/Time.helper';
import { IContract } from 'state/Contracts/ContractsService';

export default function TotalTimeLogged(props: { period: 'day' | 'month', forAllContracts?: boolean; }) {
  const tasksHelper = new TasksHelper();
  const timeHelper = new TimeHelper();
  // Used to get the amount of timeSpent for the current month.
  const { sellerTasks, hideAmounts, currentDate } = useAppContext();
  const { currentContract, contracts } = useContracts();
  // Holds the total amount generated for each of the quantifiers for the current month.
  const [total, setTotal] = useState<number>(0);
  /**
   * Get the list of all quantifiers from
   * the firebase db.
   */
  useEffect(() => {
    if (!sellerTasks?.length || !currentContract || !currentDate) return;
    let totalTime = 0;
    if (!props.forAllContracts) {
      totalTime = tasksHelper.filterTimeSpentForContractForPeriod(sellerTasks, currentDate, currentContract.id, props.period);
    } else {
      const listOfCurrentContracts = contracts.map((c: IContract) => c.id);
      totalTime = tasksHelper.getTimespentForContractsForPeriod(listOfCurrentContracts, sellerTasks, new Date(), 'month');
    }
    setTotal(totalTime);
  }, [sellerTasks, currentContract, currentDate, contracts]);

  return (
    <MiniStatistics
      startContent={
        <IconBox
          w='56px'
          h='56px'
          bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
          icon={<Icon w='28px' h='28px' as={MdAddTask} color='white' />}
        />
      }
      name='Logged hours'
      value={timeHelper.humanize(total)}
    />
  )
}