import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton/CustomButton';
// Custom components
import { TimeHelper } from 'logic/Time.helper';
import { TodoHelper } from 'logic/todo.helper';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { MdPlaylistAdd } from 'react-icons/md';
import { IEntry, IEntryTodo } from 'state/Tasks/TasksDTO';
import { TasksStore } from 'state/Tasks/TasksStore';
import TodoItem from './TodoItem';

interface Props {
  entry: IEntry;
}

export const TodosList: React.FC<Props> = ({ entry }) => {
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  // Helps with different todo list operations.
  const helper = new TodoHelper();
  // The time helper class.
  const timeHelper = new TimeHelper();
  // Helps with updating DB data.
  const store = new TasksStore();
  // Holds the list of all the todos for the current task.
  const [todos, setTodos] = useState<IEntryTodo[]>(entry.todos);
  // Indicates if a DB update should be triggered.
  const [shouldUpdateList, setShouldUpdateList] = useState<number>(0);
  /**
   * Trigger actions based on the changes
   * done to the current list of todos.
   * (updates, saves and delete).
   */
  useEffect(() => {
    if (!shouldUpdateList) {
      return;
    }
    // If there are no todos then we ignore list changes.
    store.updateNoteTodos(entry.id, todos);
  }, [shouldUpdateList]);

  /**
   * Handle the saving of a new todo item.
   *
   * @param todo IEntryTodo
   * @returns void
   */
  const handleTodoSave = (todo: IEntryTodo) => {
    /**
     * Update the list of todos accordingly based
     * on the fact that we provided a new todo
     * or an already existing one that was simply
     * updated.
     */
    const removedMarkerFromTodo = helper.removeEditMarker([todo]);
    const removedMarkerFromTodos = helper.removeEditMarker(todos);
    const updatedList = helper.updateList(removedMarkerFromTodos, removedMarkerFromTodo[0]);
    setTodos(updatedList);
    triggerUpdate();
  }
  /**
   * Save the duration set on a specific todo
   * given the provided todoId and the duration
   * in minutes.
   *
   * @param todoId string
   * @param duration number
   * @returns void
   */
  const handleTodoDurationChange = (todoId: string) => {
    return (event: any) => {
      // Convert the provided duration to minutes.
      const minutes = timeHelper.deHumanize(event);
      if (!minutes) {
        return;
      }
      // updateTodoTimeSpent(entry.id, minutes);
      const newListOfTodos =
        entry.todos.map(
          (todo: IEntryTodo) => todo.id === todoId ?
            ({ ...todo, timeSpent: minutes }) :
            todo
        )
      store.updateNoteTodos(entry.id, newListOfTodos)
    }
  }
  /**
   * Add the edit marker to do that the user wants to
   * edit. This way we can actually produce a change
   * in the list of todos so it will refresh once the
   * state changes.
   *
   * @param todoId string
   * @returns void
   */
  const handleTodoEdit = (todoId: string) => {
    // Find the todo that needs to be edited.
    setTodos([...helper.addEditMarker(todos, todoId)]);
  }
  /**
   * Handles the click of a todo items
   * checkbox.
   *
   * @param event any
   * @param todoId string
   * @returns void
   */
  const handleTodoCheck = (todoId: string) => {
    setTodos(helper.check(todos, todoId));
    triggerUpdate();
  }
  /**
   * Cancel the creation or editing of a todo.
   * 
   * @param todoId string
   * @returns void
   */
  const handleTodoCancel = () => {
    // Remove the incomplete todo from the list of todos.
    setTodos([...helper.removeEditMarker(todos)]);
  }
  /**
   * Delete the todo with the provided id.
   *
   * @param todoId string
   */
  const handleTodoDelete = (todoId: string) => {
    setTodos(helper.delete(todos, todoId));
    triggerUpdate();
  }
  /**
   * Will trigger the update of the DB and
   * fetch the new list of todos.
   */
  const triggerUpdate = () => {
    setShouldUpdateList(Math.round(Math.random() * 10000));
  }

  /**
   * Add a new todo item to the list of todos
   * of the current task.
   */
  const addTodo = () => {
    /**
     * Check if there is already a todo that
     * is being edited or created.
     */
    if (!!todos && todos.length && todos.find((t: IEntryTodo) => !t.id)) {
      return;
    }
    /**
     * Update the todo list with a new empty
     * todo object that later can be saved.
     */
    setTodos([...todos || [], {
      id: '_editing',
      description: '',
      completed: false
    }]);
  }

  if (!todos?.length) {
    return (
      <Flex direction='row' justifyContent='flex-start' alignItems='center'>
        No todos added yet. Click
        <CustomButton
          icon={MdPlaylistAdd}
          callback={() => addTodo()}
          ml='8px'
          mr='8px'
        />
        to add one.
      </Flex>
    )
  }

  return (
    <Flex direction='column' bg={boxBg} p='16px 20px' borderRadius='14px' mt='10px' w='100%'>

      <Text fontSize='md' fontWeight='700' color={textColor} mb='10px'>
        TODOS
      </Text>

      {todos.map((todo: IEntryTodo) =>
        <TodoItem
          key={todo.id}
          ticket={entry}
          todo={todo}
          handleTodoSave={handleTodoSave}
          handleTodoDurationChange={handleTodoDurationChange(todo.id)}
          handleTodoEdit={handleTodoEdit}
          handleTodoCheck={handleTodoCheck}
          handleTodoCancel={handleTodoCancel}
          handleTodoDelete={handleTodoDelete}
        />
      )}
      <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
        When todos are added, only the cumulative time of the todos is being shown on the task.
      </Text>
      <Flex
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        fontSize='sm'
        fontWeight='500'
        color='secondaryGray.600'>
        Click
        <CustomButton
          icon={MdPlaylistAdd}
          callback={() => addTodo()}
          ml='8px'
          mr='8px'
        />
        to add a new todo item.
      </Flex>
    </Flex>
  )
}