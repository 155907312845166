import { IUserDisplayname } from "state/useAuthState";
import { IQuantifier } from "../Quantifiers/quantifiers.service";
import { UsersDB } from "./UsersDb";

export interface IUserProfile {
  uid?: string;
  /**
   * The complete name the user chose himself
   * to be displayed to all other users.
   */
  displayName: string;
  firstName: string;
  lastName: string;
  email: string;
  businessName: string;
  businessLocation: string;
  street?: string;
  city?: string;
  country?: string;
  zip?: string;
  quantifier: IQuantifier;
  userId: string;
  role?: string;
  jobName?: string;
  bio?: string;
  /**
   * The remote url to the users
   * profile image.
   */
  avatarPromise?: Promise<any>;
  avatar?: string;
  /**
   * Invoicing settings.
   */
  invoicePrefix?: string;
  invoiceItemLabel?: string;
  invoicePaymentDueDays?: number;
  invoiceNote?: string;
  /**
   * Company business specific details
   * for the invoicing.
   */
  companyRegistrarNr?: string;
  vatCode?: string;
  iban?: string;
  bankName?: string;
  bankSwift?: string;
}

class UsersService extends UsersDB {

  /**
   * Get the display name for the provided
   * userId.
   * 
   * @param userId string
   * @returns Promise<string>
   */
  public async getDisplayName(userId: string): Promise<string> {
    const theUser = await this.fetchByUserId(userId);
    return theUser.displayName || `${theUser.firstName} ${theUser.lastName}`;
  }

  /**
   * Get the user profile information using the
   * provided userId.
   * 
   * @param userId string
   * @returns IUserProfile
   */
  public async getUserData(userId: string): Promise<IUserProfile> {
    return await this.fetchByUserId(userId);
  }

  /**
   * Get the list of all user display
   * names currently in the database.
   * 
   * @returns Promise<IUserDisplayname[]>
   */
  public async getListOfUserDisplayNames(): Promise<IUserDisplayname[]> {
    return this.fetchUsersDisplaynames();
  }

  /**
   * Get a list of users with their avatars
   * using the provided list of userIds.
   *
   * @param userIds string[]
   * @returns Promise<IUserProfile[]>
   */
  public async getUsersAndAvatarsByUserIds(userIds: string[]): Promise<IUserProfile[]> {
    return await this.fetchByUserAndAvatarWithUserIdsInDb(userIds);
  }

  /**
   * Get a list of users with their avatars
   * using the provided list of userIds.
   *
   * @param userIds string[]
   * @returns Promise<IUserProfile[]>
   */
  public async getUserAndAvatarByUserIds(userIds: string[]): Promise<IUserProfile[]> {
    // Get the list of user objects with unresolved avatar promises.
    return await this.fetchByUserAndAvatarWithUserIdsInDb(userIds);
  }

  public async attachAvatarsToUsers(users: IUserProfile[]): Promise<IUserProfile[]> {
    // Create a new promise to return.
    return await new Promise((resolve: any) => {
      // Parse through the list of provided users.
      const usersWithAvatars = users.map(async (user: IUserProfile) =>
        ({ ...user, avatar: await this.getAvatarInStorage(user.userId) })
      );
      // Return the resolved list.
      resolve(usersWithAvatars);
    })
  }
}

export const usersService = new UsersService();