import { hoursToMilliseconds } from 'date-fns';
import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  where,
  Query,
} from 'firebase/firestore';
import { db } from '../firebase';

export class ContractsQueries {

  /**
   * Returns the contracts collection reference
   * so we can use it outside of this service.
   *
   * @returns CollectionReference<DocumentData>
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'contracts');
  }

  /**
   * Get a list with all the contracts where
   * the provided userId is marked as a seller
   * of services.
   * 
   * @param userId string
   * @returns Query<DocumentData>
   */
  public getQueryBySellerId(userId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("sellerId", "==", userId),
    );
  }

  /**
   * Get a list with all the contracts where
   * the provided userId is marked as a buyer
   * of services.
   * 
   * @param userId string
   * @returns Query<DocumentData>
   */
  public getQueryByBuyerId(userId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("buyerId", "==", userId),
    );
  }

  /**
   * Get a list with all the contracts where
   * the provided userId is marked as a seller
   * of services.
   * 
   * @param userId string
   * @returns Query<DocumentData>
   */
  public getQueryByBuyerAndSellerId(buyerId: string, sellerId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("buyerId", "==", buyerId),
      where("sellerId", "==", sellerId),
    );
  }

  /**
   * Get a list of all the contracts that have the provided
   * buyer, seller, rate and hoursPerDay values.
   *
   * @param buyerId string
   * @param sellerId string
   * @param rate number
   * @param hoursPerDay number
   * @returns Query<DocumentData>
   */
  public getQueryByBuyerSellerRateAndHoursPerDay(buyerId: string, sellerId: string, rate: number, hoursPerDay: number): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("buyerId", "==", buyerId),
      where("sellerId", "==", sellerId),
      where("rate", "==", rate),
      where("hoursPerDay", "==", hoursPerDay),
    );
  }
}