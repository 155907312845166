import { Avatar, Flex, useToast } from "@chakra-ui/react"
import { useAuth } from "state/useAuthState";

const ProfileImage = (props: { avatar: string; }) => {
  const { avatar } = props;
  const toast = useToast();
  // Get some methods for updating the profile avatar.
  const { updateProfileImage, profileImageUrl } = useAuth();
  // Upload a new avatar and replace the previous one.
  const uploadProfileImage = (event: any) => {
    updateProfileImage(event.target.files[0])
      .then((result: any) => toast({
        title: 'Profile image uploaded!',
        description: "Your new profile image was applied.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }))
      .catch((error: any) => console.error(error));
  }

  return (
    <Flex>
      <Avatar
        mx='auto'
        src={profileImageUrl || avatar}
        h='87px'
        w='87px'
        mt='-43px'
        mb='15px'
      />
      <input
        type="file"
        style={{ opacity: 0, width: '87px', height: '87px', marginLeft: '-87px', marginTop: '-40px' }}
        onChange={(e: any) => uploadProfileImage(e)}
      />
    </Flex>
  )
}

export default ProfileImage;