import { useEffect, useState } from "react";
import { IEntry } from "../../state/Tasks/TasksDTO";
import { TasksStore } from "../../state/Tasks/TasksStore";
import { useAppContext } from "../../state/useAppState"

interface IStatus {
  label: string;
  bgColor: string;
  textColor: string;
}

interface IMarkableEntry extends IEntry {
  marked: boolean;
  status: IStatus;
}

const TasksMoveWizard = () => {
  const store = new TasksStore();
  // The app wide modal state props.
  const { modalState, setModalState } = useAppContext();
  // Holds the list of entries that the user checked for moving.
  const [entries, setEntries] = useState<IMarkableEntry[]>([]);
  // Holds the selected date for the entries to be moved to.
  const [date, setDate] = useState<Date>(new Date());
  // Indicates if the move button is enabled or not.
  const [selectedEntries, setSelectedEntries] = useState<number>(0);
  /**
   * Set the list of entries received
   * via the modal params.
   */
  useEffect(() => {
    const marked = modalState.props
      // Add the new check property to know which tasks were selected by the user.
      .map((entry: IEntry) => ({ ...entry, marked: false }))
      /**
       * Add the new status property to indicate what is the current task status.
       * (done, not done, unfinished todos...)
       */
      .map((entry: IEntry) => ({ ...entry, status: getStatus(entry) }));
    setEntries(marked);
  }, []);
  /**
   * Monitor the changes in the list of
   * entries so we can identify if the
   * move button should be enabled or not.
   */
  useEffect(() => {
    const selectedEntries = entries.filter((entry: IMarkableEntry) => !!entry.marked);
    setSelectedEntries(selectedEntries.length);
  }, [entries]);
  /**
   * Get the status for the provided task.
   *
   * @param entry IEntry
   * @returns string
   */
  const getStatus = (entry: IEntry): IStatus => {
    const nrIncompletTasks = store.hasTodosIncomplete(entry);
    if (store.isTaskIncomplete(entry) || nrIncompletTasks) {
      return {
        label: 'Incomplete',
        bgColor: 'bg-red-100',
        textColor: 'text-red-800'
      };
    }
    if (store.isTaskComplete(entry)) {
      return {
        label: 'Complete',
        bgColor: 'bg-green-100',
        textColor: 'text-green-800'
      };
    }
  }
  /**
   * Get tips displayed under the task labels showing the
   * number of completed and incomplete todos in the current
   * task.
   *
   * @param entry IEntry
   * @returns string[]
   */
  const getTips = (entry: IEntry): IStatus[] => {
    const tips = [];
    const nrOfIncompletTasks = store.hasTodosIncomplete(entry);
    const nrOfCompleteTasks = store.hasTodosComplete(entry);
    if (nrOfIncompletTasks) {
      tips.push({
        label: `${nrOfIncompletTasks} remaining`,
        bgColor: 'bg-yellow-100',
        textColor: 'text-yellow-800'
      });
    }
    if (nrOfCompleteTasks) {
      tips.push({
        label: `${nrOfCompleteTasks} complete`,
        bgColor: 'bg-blue-100',
        textColor: 'text-blue-800'
      });
    }
    return tips;
  }
  /**
   * Mark all tasks that have the incomplete
   * status.
   */
  const markAllIncomplete = () => {
    const marked = entries.map((entry: IMarkableEntry) => entry.status.label === 'Incomplete' ? ({ ...entry, marked: true }) : entry);
    setEntries(marked);
  }
  /**
   * Mark all tasks that contain incomplete
   * todos.
   */
  const markAllWithIncompleteTodos = () => {
    const marked = entries.map((entry: IMarkableEntry) => getTips(entry).find((status: IStatus) => status.bgColor === 'bg-yellow-100') ? ({ ...entry, marked: true }) : entry);
    setEntries(marked);
  }
  /**
   * Unmark all previously marked entries.
   */
  const unmarkAll = () => {
    const unmarked = entries.map((entry: IMarkableEntry) => ({ ...entry, marked: false }));
    setEntries(unmarked);
  }
  /**
   * 
   */
  const moveEntries = (): void => {
    const markedEntries = entries
      // Select the marked entries.
      .filter((entry: IMarkableEntry) => !!entry.marked)
      // Change the date on the entries.
      .map((entry: IMarkableEntry) => {
        // Change the date.
        const e = ({ ...entry, created: date.getTime() });
        delete e.marked;
        return e;
      })
    // Call the store method to update the dates.
    store.updateCreatedDateForNotes(markedEntries);
    // Close the modal after action completed.
    setModalState(null);
  }

  return (
    // <!-- Modal backdrop. This what you want to place close to the closing body tag -->
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      {/* <!-- Modal --> */}
      <div
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
        id="modal"
      >

        {/* <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. --> */}
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
            aria-label="close"
            onClick={() => setModalState(null)}
          >
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </button>
        </header>

        {/* <!-- Modal body --> */}
        <div className="mt-4 mb-6">
          {/* <!-- Modal title --> */}
          <p
            className="-mt-10 mb-2 text-lg font-semibold text-black text-center"
          >
            {modalState.title}
          </p>
          {/* <!-- Modal description --> */}
          <div
            className="px-4 py-3 mb-8 bg-white rounded-lg flex flex-col items-center"
          >

            {/* The explanation text. */}
            <span className="text-md mb-10">Please choose the task you would like to move to TODAY:</span>

            {/* Toolbar with quick action buttons. */}
            <div className="flex flex-row justify-start items-center text-sm mb-3">
              {/* Button for marking incomplete entries */}
              <button
                type="button"
                className="rounded-lg bg-blue-100 py-0.5 px-1 mr-1"
                onClick={() => markAllIncomplete()}
              >Mark all incomplete</button>

              {/* Button for marking entries with incomplete todos. */}
              <button
                type="button"
                className="rounded-lg bg-blue-100 py-0.5 px-1 mr-1"
                onClick={() => markAllWithIncompleteTodos()}
              >Mark all with todos remaining</button>

              {/* Button for unmarking all todos. */}
              <button
                type="button"
                className="rounded-lg bg-blue-100 py-0.5 px-1 mr-1"
                onClick={() => unmarkAll()}
              >Unmark all</button>
            </div>

            <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200 h-96 overflow-y-auto">
              {entries && entries.map((entry: IMarkableEntry, index: number) =>
                <li
                  key={entry.id}
                  className={`py-2 px-4 w-full ${index === 0 ? 'rounded-t-lg' : ''}
                  ${index === (modalState.props.length - 1) ? 'rounded-b-lg' : ''}
                  border-b border-gray-200`}
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col justify-start w-full">

                      {/* The checkbox and the task label. */}
                      <div className="flex flex-row items-center">
                        {/* The checkbox. */}
                        <input
                          type="checkbox"
                          className="w-4 h-4 shrink-0 text-blue-600 bg-gray-100 rounded border-gray-300"
                          checked={entry.marked}
                          onChange={(event: any) => entry.marked = !entry.marked}
                        />
                        {/* The task label. */}
                        <label className="ml-2 text-sm font-medium text-gray-900">{entry.label}</label>
                      </div>

                      {/* The badges indicating various useful info. */}
                      <div className="flex flex-row items-center ml-6 mt-1">
                        {getTips(entry).map((status: IStatus) =>
                          <span key={status.label} className={`${status.bgColor} ${status.textColor} text-xs font-semibold px-2.5 py-0.5 rounded mr-1`}>{status.label}</span>
                        )}
                      </div>
                    </div>
                    {/* The status indicator. */}
                    <span className={`${entry.status.bgColor} ${entry.status.textColor} text-xs font-semibold px-2.5 py-0.5 rounded`}>{entry.status.label}</span>
                  </div>
                </li>
              )}
            </ul>

          </div>
        </div>

        {/* The modal footer. */}
        <footer
          className="flex flex-col items-center justify-between px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50"
        >

          {/* Left side: for indicators. */}
          <div className="">
            {selectedEntries > 0 && <span className="">{selectedEntries} tasks selected for moving</span>}
          </div>

          {/* Right side: for buttons. */}
          <div className="">
            {/* The cancel button. */}
            <button
              type="button"
              className={`w-full px-5 py-3 text-sm font-medium leading-5 transition-colors duration-150 border border-slate-500 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray`}
              // className="w-full px-5 py-3 text-sm font-medium leading-5 text-black transition-colors duration-150 border border-slate-200 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              onClick={() => setModalState(null)}
            >
              Cancel
            </button>

            {/* The move button */}
            <button
              type="button"
              className={`w-full px-5 py-3 text-sm font-medium leading-5 transition-colors
                        duration-150 border border-slate-500 rounded-lg sm:px-4 sm:py-2
                        sm:w-auto active:bg-transparent hover:border-gray-500 bg-blue-500
                        focus:border-gray-500 active:text-gray-500 focus:outline-none
                        focus:shadow-outline-gray disabled:bg-slate-100 disalbed:text-slate-300
                        disabled:border-slate-200 ml-3`}
              // className="w-full px-5 py-3 text-sm font-medium leading-5 text-black transition-colors duration-150 border border-slate-200 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
              onClick={() => moveEntries()}
              disabled={selectedEntries === 0}
            >Move</button>
          </div>

        </footer>

      </div>
    </div>

  )
}

export default TasksMoveWizard