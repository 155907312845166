// Chakra imports
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
// Custom components
import { useAppContext } from 'state/useAppState';
import { useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
// import profile from 'assets/img/crm/vbz.png';
import InvoicedContracts from './invoice/components/InvoicedContracts';
import InvoicesSituation from './invoice/components/InvoicesSituation';

export default function InvoicesView() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate } = useAppContext();
  const [tasks, setTasks] = useState<IEntry[]>([]);
  
  useEffect(() => {
    if (!tasksForSelectedDate) return;
    setTasks(tasksForSelectedDate);
  }, [tasksForSelectedDate]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        
        <Flex direction='column'>
          <InvoicedContracts />
        </Flex>

        <Flex direction='column'>
          <InvoicesSituation />
        </Flex>

      </SimpleGrid>
    </Box>
  );
}
