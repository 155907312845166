import { endOfMonth, startOfMonth } from "date-fns";
import { IContract } from "state/Contracts/ContractsService";
import { IEntry } from "state/Tasks/TasksDTO";
import { IUserDisplayname } from "state/useAuthState";
import { ContractInvoiceStatusEnum, IContractInvoice } from "./Dto";
import { v4 as uuid } from 'uuid';
import ContractHelper from "logic/contract.helper";
import { TasksHelper } from "logic/Tasks.helper";

class InvoiceGenerator {
  private tasksHelper = new TasksHelper();
  private contractHelper = new ContractHelper();

  /**
   * Generate a list of invoices for the provided contractId
   * using the provided list of tasks.
   *
   * @param contractId string
   * @param tasks IEntry[]
   * @param date Date
   * @returns IContractInvoice[]
   */
  public generateInvoices(
    contract: IContract,
    allTasks: IEntry[],
    displayNames: IUserDisplayname[],
    currentUserId: string,
    forMonths: Date[],
    applyReduction?: number
  ): IContractInvoice[] {
    // Get the tasks that belong only to the provided contractId.
    const contractTasks = this.tasksHelper.filterTasksByContracts(allTasks, [contract.id]);
    // Detect the months (as dates) that are available from the list of tasks provided.
    const months = this.tasksHelper.getListOfDatesFromTasks(contractTasks);
    // Parse through all the months and get the time spent for each of them as a list.
    // Returns: [1233, 2343]
    return forMonths.map((month: Date) => {
      // Get the time spent on the tasks for this month.
      const timeSpent = this.tasksHelper.getTimeSpentFromTasksForPeriod(contractTasks, month, applyReduction);
      // Figure out if we need the buyer name or the seller name.
      const propName = this.contractHelper.getContractType(contract, currentUserId) === 'buyer' ? 'sellerId' : 'buyerId';
      // Get the required display name for the current invoice.
      const displayName = displayNames.find((u: IUserDisplayname) => u.userId === contract[propName]);
      return ({
        id: uuid(),
        contractId: contract.id,
        for: displayName,
        invoiceDate: endOfMonth(month).getTime(),
        accepted: null,
        timeSpent,
        rate: contract.rate,
        amount: (timeSpent / 60) * contract.rate,
        status: this.getInvoiceStatus(month, null),
        reductionApplied: applyReduction || 0
      })
    });
  }

  /**
   * Get the status of the provided invoice.
   *
   * @param invoice IContractInvoice
   */
  private getInvoiceStatus(month: Date, accepted: boolean): ContractInvoiceStatusEnum {
    const isPastDueDate = month.getTime() < startOfMonth(new Date()).getTime();
    // Invoice has been finished but not accepted so invoice is FINISHED.
    if (isPastDueDate && accepted !== true) {
      return ContractInvoiceStatusEnum.PAID;
    }
    // Client accepted so invoice is CLOSED.
    if (isPastDueDate && accepted === true) {
      return ContractInvoiceStatusEnum.CLOSED;
    }
    // Due date not passed so invoice is IN PROGRESS.
    if (!isPastDueDate && accepted === null) {
      return ContractInvoiceStatusEnum.IN_PROGRESS;
    }
    // Past due date and client didn't reject or accept so it requires ATTENTION.
    if (isPastDueDate && accepted === null) {
      return ContractInvoiceStatusEnum.ATTENTION;
    }
  }
}

export const invoiceGenerator = new InvoiceGenerator();