import { ITaskType } from "./type.helper";

class ChartHelper {

  /**
   * Get the Pie chart configuration
   * object given the provided task
   * types.
   *
   * @param types ITaskType[]
   * @returns any
   */
  public getPieChartOptions(types: ITaskType[]): any {
    return {
      labels: types.map((type: ITaskType) => type.type),
      colors: types.map((type: ITaskType) => type.color),
      chart: {
        width: '50px'
      },
      states: {
        hover: {
          filter: {
            type: 'none'
          }
        }
      },
      legend: {
        show: false
      },
      dataLabels: {
        enabled: true,
        dropShadow: {
          enabled: false
        },

      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false
            }
          }
        }
      },
      fill: {
        colors: types.map((type: ITaskType) => type.color),
      },
      tooltip: {
        enabled: true,
        theme: 'dark'
      }
    }
  };

  /**
   * Get the list of percentages to be
   * shown in the Pie chart given the
   * provided task type mapping.
   *
   * @param types ITaskType[]
   * @returns number[]
   */
  public getPieChartData(types: ITaskType[]): number[] {
    const theTypes = types.map((type: ITaskType) => type.percentage);
    return theTypes.find((type: number) => isNaN(type)) ? [] : theTypes;
  }

  public getBarChartOptions(types: ITaskType[]): any {
    return {
      labels: types.map((type: ITaskType) => type.type),
      colors: types.map((type: ITaskType) => type.color),
      chart: {
        width: '100%',
        stacked: true,
        stackType: "100%",
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          horizontal: true
        }
      },
      dataLabels: {
        dropShadow: {
          enabled: false
        }
      },
      stroke: {
        width: 0
      },
      xaxis: {
        categories: [""],
        labels: {
          show: false
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        }
      },
      fill: {
        opacity: 1,
        type: "gradient",
        colors: types.map((type: ITaskType) => type.color),
        gradient: {
          shade: "dark",
          type: "vertical",
          shadeIntensity: 0.35,
          gradientToColors: undefined,
          inverseColors: false,
          opacityFrom: 0.85,
          opacityTo: 0.85,
          stops: [90, 0, 100]
        }
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
    };
  }

  public getBarChartData(types: ITaskType[]): any[] {
    return types.map((type: ITaskType) => ({ name: type.type, data: [type.percentage] }));
  }
}

export default ChartHelper;

export const optionsBar: any = {
  chart: {
    stacked: true,
    stackType: "100%",
    toolbar: {
      show: false
    }
  },
  plotOptions: {
    bar: {
      horizontal: true
    }
  },
  dataLabels: {
    dropShadow: {
      enabled: true
    }
  },
  stroke: {
    width: 0
  },
  xaxis: {
    categories: ["Fav Color"],
    labels: {
      show: false
    },
    axisBorder: {
      show: false
    },
    axisTicks: {
      show: false
    }
  },
  fill: {
    opacity: 1,
    type: "gradient",
    gradient: {
      shade: "dark",
      type: "vertical",
      shadeIntensity: 0.35,
      gradientToColors: undefined,
      inverseColors: false,
      opacityFrom: 0.85,
      opacityTo: 0.85,
      stops: [90, 0, 100]
    }
  },

  legend: {
    position: "bottom",
    horizontalAlign: "right"
  }
};

export const seriesBar: any = [
  {
    name: "blue",
    data: [32]
  },
  {
    name: "green",
    data: [41]
  },
  {
    name: "yellow",
    data: [12]
  },
  {
    name: "red",
    data: [65]
  }
];