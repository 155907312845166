import { Badge, Flex, Td, Tr, Text, useColorModeValue, Stack, Icon } from "@chakra-ui/react";
import { format } from "date-fns";
import { TimeHelper } from "logic/Time.helper";
import { TypeHelper } from "logic/type.helper";
import { MdCheckBox, MdCheckBoxOutlineBlank } from "react-icons/md";
import { IEntry, IEntryTodo } from "state/Tasks/TasksDTO";

const InvoiceTasksListRow = (props: { task: IEntry, alternator: boolean }) => {
  const timeHelper = new TimeHelper();
  // Helps detecting task type indicators inside the label.
  const typeHelper = new TypeHelper();
  const alternatingBackgroundColor = useColorModeValue("#f4f6fe", "#1B2D6B");

  return (
    <Tr backgroundColor={props.alternator ? alternatingBackgroundColor : ''}>

      {/* Date. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        {format(props.task.created, 'dd/MM/yyyy')}
      </Td>

      {/* Type. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        {props.task.type ? (
          <Badge
            fontSize='10px'
            fontWeight='bold'
            variant='solid'
            h='28px'
            w='94px'
            display='flex'
            borderRadius='8px'
            alignItems='center'
            justifyContent='center'
            bg={typeHelper.getTypeColor(props.task.type)}
            colorScheme={typeHelper.getTypeColor(props.task.type)}>
            {props.task.type}
          </Badge>
        ) : null}
      </Td>

      {/* Description. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        <Flex
          direction={props.task.todos?.length ? 'column' : 'row'}
          alignItems={props.task.todos?.length ? 'left' : 'left'}
          gap={2}
        >
          <Text
            textDecoration={!!props.task.todos?.length ? 'underline' : ''}
            fontSize={!!props.task.todos?.length ? '16px' : '14px'}
            fontWeight={!!props.task.todos?.length ? 700 : 400}
          >
            {props.task.label}
          </Text>
          {!!props.task.todos?.length && props.task.todos.map((todo: IEntryTodo) =>
            <Stack direction="row" justifyContent="flex-start" gap={2} alignItems="center">
              {todo.timeSpent > 0 && <Icon as={MdCheckBox} />}
              {(!todo.timeSpent || todo.timeSpent === 0) && <Icon as={MdCheckBoxOutlineBlank} />}
              <Text key={todo.id}>{todo.description}</Text>
              <Badge fontSize="10px">{timeHelper.humanize(todo.timeSpent)}</Badge>
            </Stack>
          )}
        </Flex>
      </Td>

      {/* Time spent. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        {timeHelper.humanize(props.task.timeSpent)}
      </Td>

    </Tr>
  )
}

export default InvoiceTasksListRow;