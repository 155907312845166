import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from "@chakra-ui/react"
import { ReactNode } from "react";
import { useAppContext } from "state/useAppState";

interface IActionButton {
  callback: any;
  label: string;
  color: string;
}

interface Props {
  children: ReactNode;
  title: string;
}

const BasicModal: React.FC<Props> = ({
  children,
  title,
}) => {
  const { modalState, setModalState } = useAppContext();

  return (
    <Modal isOpen={true} onClose={() => setModalState(null)} size={modalState.size || 'md'}>
      <ModalOverlay />
      <ModalContent w='100%'>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody w='100%'>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default BasicModal