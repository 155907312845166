import { Flex, Select, Text } from "@chakra-ui/react"
import { IContract } from "state/Contracts/ContractsService"
import { useContracts } from "state/useContractsState"

const SelectedContractChange = () => {
  const { contracts, currentContract, setCurrentContract } = useContracts();

  /**
   * Handle the change of the current
   * quantifier in the system.
   */
  const handleChange = (event: any) => {
    // Find the quantifier object that was selected.
    const foundContract = contracts.find((contract: IContract) => contract.id === event.target.value);
    if (foundContract) {
      setCurrentContract(foundContract)
    }
  }

  if (!currentContract) {
    return <></>
  }

  return (
    <Flex
      direction='column'
      justifyContent='center'
      alignItems='center'
      mt='20px'
      pl='10px'
      pr='25px'
    >

      {/* The explanation. */}
      <Text fontSize='sm' fontWeight='500' color='secondaryGray.600' pl='4px' pr='4px'>
        You can select here the current <strong>contract</strong> for which the daily tasks will be shown.
      </Text>

      {/* The dropdown. */}
      <Select
        fontSize='sm'
        mt='16px'
        mb='24px'
        fontWeight='500'
        size='lg'
        placeholder='Select contract'
        value={currentContract.id}
        onChange={handleChange}
      >
        {contracts.map((contract: IContract) =>
          <option key={contract.id} value={contract.id}>{contract.name}</option>
        )}

      </Select>
    </Flex>
  )
}

export default SelectedContractChange;