import { Flex, Th, Text, useColorModeValue } from "@chakra-ui/react"

export const InvoiceTableHeader = (props: { label: string }) => {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
  
  return (
    <Th
      pe='10px'
      borderColor={borderColor}
      cursor='pointer'>
      <Flex
        justifyContent='space-between'
        align='center'
        fontSize={{ sm: '10px', lg: '12px' }}
        color='gray.400'>
        <Text
          justifyContent='space-between'
          align='center'
          fontSize={{ sm: '10px', lg: '12px' }}
          color='gray.400'
        >
          {props.label}
        </Text>
      </Flex>
    </Th>
  )
}