/* eslint-disable */

import { NavLink, useLocation } from 'react-router-dom';
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { SidebarLink } from './SidebarLink';
import { ILink } from 'routes';

export function SidebarLinks(props: {links: ILink[]}) {
	const { links } = props;

  const acceptedLayouts = [
    '/dashboard',
    '/administrator',
    '/tasks',
    '/contracts',
  ]

	// this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
	const createLinks = (links: ILink[]) => {
		return links.map(
			(
				link: ILink,
				index: number
			) => {
				if (acceptedLayouts.includes(link.layout) && !link.hidden) {
					return (
						<SidebarLink key={index} index={index} link={link} />
					);
				}
			}
		);
	};
	//  BRAND
	return <>{createLinks(links)}</>
}

export default SidebarLinks;
