import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  doc,
  where,
  Query,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase';

export class ProjectsQueries {

  /**
   * It returns a reference to the invoices collection in the database
   * @returns A collection reference
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'projects');
  }

  /**
   * It returns a DocumentReference object that represents a document with the given id in the invoices
   * collection
   * @param {string} id - The id of the document you want to get.
   * @returns A DocumentReference<DocumentData>
   */
  public getDocById(id: string): DocumentReference<DocumentData> {
    return doc(db, 'projects', id);
  }

  /**
   * It returns a query that will return all projects that have the userId in the userIds array.
   * @param {string} userId - string
   * @returns A query object.
   */
  public getQueryProjectsByUserId(userId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("userIds", "array-contains", userId),
    );
  }

  /**
   * It returns a query that filters the invoices collection by contractId.
   * @param {string} contractId - string
   * @returns A Query object
   */
  public getQueryProjectsByContractId(contractId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("contractId", "==", contractId),
    );
  }

  /**
   * It returns a query that filters the invoices collection by the contractIds array.
   * @param {string[]} contractIds - string[]
   * @returns A Query object
   */
  public getQueryProjectsByContractIds(contractIds: string[]): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("contractId", "in", contractIds),
    );
  }
}