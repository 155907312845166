import { Icon } from '@chakra-ui/react';
import { MdBarChart, MdPerson, MdHome, MdLock, MdOutlineShoppingCart, MdOutlineCalendarToday, MdAdminPanelSettings, MdOutlineSettingsSuggest, MdAttachMoney, MdViewSidebar } from 'react-icons/md';

// Admin Imports

// Auth Imports
import DayView from 'views/day';

export interface ILink {
  name: string;
  layout: string;
  path: string;
  icon: JSX.Element;
  hidden?: boolean;
}

export const links: ILink[] = [
  { name: 'Dashboard', layout: '/dashboard', path: '/dashboard', icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' /> },
  { name: 'Projects', layout: '/dashboard', path: '/projects', icon: <Icon as={MdViewSidebar} width='20px' height='20px' color='inherit' /> },
  { name: 'Project', layout: '/dashboard', path: '/project', hidden: true, icon: <Icon as={MdAttachMoney} width='20px' height='20px' color='inherit' /> },
  { name: 'Administrator', layout: '/dashboard', path: '/administrator', icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' /> },
  { name: 'Day', layout: '/dashboard', path: '/day', icon: <Icon as={MdOutlineCalendarToday} width='20px' height='20px' color='inherit' /> },
  { name: 'Profile', layout: '/dashboard', path: '/profile', icon: <Icon as={MdOutlineSettingsSuggest} width='20px' height='20px' color='inherit' /> },
  { name: 'Invoices', layout: '/dashboard', path: '/invoices', icon: <Icon as={MdAttachMoney} width='20px' height='20px' color='inherit' /> },
]

const routes: RoutesType[] = [
  {
		name: 'Administrator Dashboard',
    layout: '/dashboard',
		path: '/administrator',
		icon: <Icon as={MdAdminPanelSettings} width='20px' height='20px' color='inherit' />,
	},
  {
		name: 'Developer Dashboard',
    layout: '/dashboard',
		path: '/developer',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	},
  {
		name: 'Day view',
    layout: '/dashboard',
		path: '/day',
		icon: <Icon as={MdOutlineCalendarToday} width='20px' height='20px' color='inherit' />,
	},
  {
		name: 'Login',
    layout: '/auth',
		path: '/login',
		icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
	},
	// {
	// 	name: 'Main Dashboard',
	// 	layout: '/admin',
	// 	path: '/default',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	component: MainDashboard
	// },
	// {
	// 	name: 'Tasks',
	// 	layout: '/tasks',
	// 	path: '/default',
	// 	icon: <Icon as={MdOutlineCalendarToday} width='20px' height='20px' color='inherit' />,
	// 	component: NFTMarketplace,
	// 	secondary: true
	// },
	// {
	// 	name: 'Contracts',
	// 	layout: '/contracts',
	// 	icon: <Icon as={MdBarChart} width='20px' height='20px' color='inherit' />,
	// 	path: '/default',
	// 	component: DataTables
	// },
	// {
	// 	name: 'Profile',
	// 	layout: '/admin',
	// 	path: '/profile',
	// 	icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
	// 	component: Profile
	// },
	// {
	// 	name: 'Sign In',
	// 	layout: '/auth',
	// 	path: '/sign-in',
	// 	icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
	// 	component: SignInCentered
	// },
	// {
	// 	name: 'RTL Admin',
	// 	layout: '/rtl',
	// 	path: '/rtl-default',
	// 	icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
	// 	component: RTL
	// }
];

export default routes;
