// Chakra imports
import { Flex, FormLabel, Input, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components

export default function Default(props: {
  id: string;
  label: string;
  extra?: JSX.Element;
  placeholder: string;
  type: string;
  mb?: any;
  value?: string | number;
  onChange?: any;
  disabled?: boolean;
  explanation?: string;
}) {
  const { id, label, extra, placeholder, type, mb, value, onChange, disabled, explanation, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = useColorModeValue('secondaryGray.600', 'white');

  return (
    <Flex direction='column' mb={mb ? mb : '30px'}>
      <FormLabel
        display='flex'
        ms='10px'
        htmlFor={id}
        fontSize='sm'
        color={textColorPrimary}
        fontWeight='bold'
        _hover={{ cursor: 'pointer' }}>
        {label}
        <Text fontSize='sm' fontWeight='400' ms='2px'>
          {extra}
        </Text>
      </FormLabel>
      <Input
        {...rest}
        type={type}
        id={id}
        fontWeight='500'
        variant='main'
        disabled={disabled}
        placeholder={placeholder}
        _placeholder={{ fontWeight: '400', color: 'secondaryGray.600' }}
        h='44px'
        value={value}
        onChange={onChange}
        maxH='44px'
      />
      {explanation &&
        <Text mt={2} px={2} fontSize='sm' color={textColorSecondary} flex={0.5}>
          {explanation}
        </Text>
      }
    </Flex>
  );
}
