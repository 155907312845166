import { Flex, useColorModeValue } from "@chakra-ui/react";
import CustomButton from "components/CustomButton/CustomButton";
import { MdSave } from "react-icons/md";
import { IContractInvoice } from "state/Invoices/Dto";

interface Props {
  invoices: IContractInvoice[];
  saveListCallback: any;
}

const ContractInvoicesButtons: React.FC<Props> = ({
  invoices,
  saveListCallback,
}) => {
  const buttonColor = useColorModeValue("blue.700", "white");

  return (
    <Flex direction='row'>
      <CustomButton
        icon={MdSave}
        label='Save list'
        callback={() => saveListCallback(invoices)}
        color={buttonColor}
      />
    </Flex>
  );
}

export default ContractInvoicesButtons;