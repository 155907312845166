/**
 * Helps with LocalStorage read / write operations.
 */
export class LocalStorageService {

  /**
   * Get a certain key from the localStorage.
   *
   * @param key string
   * @returns any
   */
  public get(key: string): any {
    return localStorage.getItem(key);
  }

  /**
   * Save a certain key to the localStorage.
   *
   * @param key string
   * @param data any
   * @returns void
   */
  public save(key: string, data: any): void {
    localStorage.setItem(key, data);
  }

  /**
   * Remove a certain key from the localStorage.
   *
   * @param key string
   * @returns void
   */
  public remove(key: string): void {
    localStorage.removeItem(key);
  }
}