import { Flex, FormControl, FormLabel, Icon, Input, Select, useColorModeValue } from "@chakra-ui/react"
import { setUserId } from "firebase/analytics"
import { MdOutlineCheckCircleOutline } from "react-icons/md"
import { IContract } from "state/Contracts/ContractsService"
import { IUserProfile } from "state/Users/UsersService"

export const ChangeTasksForm = (props: { 
  oldContractId: string,
  newContractId: string,
  contracts: IContract[],
  users: IUserProfile[],
  userId: string,
  setOldContractId: any,
  setNewContractId: any,
  setUserId: any
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColorSuccess = useColorModeValue('green.500', 'white');

  return (
    <Flex px='25px' mt='20px' mb='8px' flexDirection='row' justifyContent='space-between' alignItems='center'>

      {/* OLD CONTRACT ID. */}
      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          alignItems='center'
          mb='8px'>Step 3: Old Contract {props.oldContractId && <Icon ml='8px' w='16px' h='16px' as={MdOutlineCheckCircleOutline} color={iconColorSuccess} />}
        </FormLabel>
        <Select
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          mb='24px'
          fontWeight='500'
          size='lg'
          placeholder='Select contract'
          value={props.oldContractId}
          disabled={!!props.newContractId}
          onChange={(e: any) => props.setOldContractId(e.target.value)}
        >
          {props.contracts.map((contract: IContract) =>
            <option key={contract.id} value={contract.id}>{contract.name}</option>
          )}

        </Select>
      </FormControl>

      {/* USER ID. */}
      <FormControl mr='8px'>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          alignItems='center'
          mb='8px'
        >
          Step 2: User {props.userId && <Icon ml='8px' w='16px' h='16px' as={MdOutlineCheckCircleOutline} color={iconColorSuccess} />}
        </FormLabel>
        <Select
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          mb='24px'
          fontWeight='500'
          size='lg'
          placeholder='Select user'
          value={props.userId}
          disabled={!!props.newContractId}
          onChange={(e: any) => props.setUserId(e.target.value)}
        >
          {props.users.map((user: IUserProfile) =>
            <option key={user.uid} value={user.userId}>{user.firstName} {user.lastName} {user.businessName && `(${user.businessName})`}</option>
          )}
        </Select>
      </FormControl>

      <FormControl>
        <FormLabel
          display='flex'
          ms='4px'
          fontSize='sm'
          fontWeight='500'
          color={textColor}
          alignItems='center'
          mb='8px'>Step 3: New Contract {props.newContractId && <Icon ml='8px' w='16px' h='16px' as={MdOutlineCheckCircleOutline} color={iconColorSuccess} />}</FormLabel>
        {/* <Input
            isRequired={true}
            fontSize='sm'
            ms={{ base: "0px", md: "0px" }}
            type='text'
            placeholder='MzHDbRhYOpPHrBi5y7c5clcUdgR3'
            mb='24px'
            fontWeight='500'
            size='lg'
            value={newContractId}
            onChange={(e: any) => setNewContractId(e.target.value)}
          /> */}
        <Select
          fontSize='sm'
          ms={{ base: "0px", md: "0px" }}
          mb='24px'
          fontWeight='500'
          size='lg'
          placeholder='Select contract'
          value={props.newContractId}
          onChange={(e: any) => props.setNewContractId(e.target.value)}
        >
          {props.contracts.map((contract: IContract) =>
            <option key={contract.id} value={contract.id}>{contract.name}</option>
          )}

        </Select>
      </FormControl>

    </Flex>
  )
}