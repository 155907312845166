// Chakra imports
import { Flex, useColorModeValue, Text } from '@chakra-ui/react';

// Custom components
import { HorizonLogo } from 'components/icons/Icons';
import { HSeparator } from 'components/separator/Separator';

export function SidebarBrand() {
	//   Chakra color mode
	let logoColor = useColorModeValue('navy.700', 'white');

	return (
		<Flex alignItems='center' flexDirection='column'>
			<Text fontSize='2xl' fontWeight='400' color='secondaryGray.600' pl='4px' pr='4px'>
        TIME<strong>SPENT</strong>
      </Text>
			<HSeparator mt='20px' mb='20px' />
		</Flex>
	);
}

export default SidebarBrand;
