import { Icon, Flex, useColorModeValue, Text, Input } from "@chakra-ui/react";
import CustomButton from "components/CustomButton/CustomButton";
import { TimeHelper } from "logic/Time.helper";
import { useEffect, useRef, useState } from "react";
import { MdMoreTime, MdOutlineAvTimer, MdOutlineCancel } from "react-icons/md";

interface Props {
  duration: number | undefined;
  handleDurationChange: (event: any) => void;
}

interface IShowDurationProps {
  duration: number | undefined;
}

interface IAddDurationProps {
  handleDurationChange: (event: any) => void;
  setReadonly: any;
  timeSpent: number;
}

/**
 * Show a badge with the total duration
 * of the task.
 */
export const ShowDuration: React.FC<IShowDurationProps> = ({ duration }) => {
  const textColor = useColorModeValue('secondaryGray.700', 'white');
  // Helps manage time related tasks.
  const timeHelper = new TimeHelper();

  return (
    <Flex direction='row' justifyContent='flex-end' alignItems='center' w='110px'>
      <Text fontSize='md' color={textColor} fontWeight='semibold'>{timeHelper.humanize(duration || 0)}</Text>
      {/* <Icon as={MdOutlineAvTimer} w='24px' h='24px' color={textColor} ml='4px' /> */}
      <CustomButton
        icon={MdOutlineAvTimer}
        callback={() => {}}
        ml='8px'
      />
    </Flex>
  )
}

/**
 * Show a button to add a new duration on the
 * current task.
 */
const ShowAddButton: React.FC<{ setReadonly: any }> = ({ setReadonly }) => {

  return (
    <CustomButton
      callback={() => setReadonly(false)}
      icon={MdMoreTime}
    />
  )
}

/**
 * Show an input to add the duration for
 * a custom task.
 */
const AddDuration: React.FC<IAddDurationProps> = ({ timeSpent, handleDurationChange, setReadonly }) => {
  const timeHelper = new TimeHelper();
  /**
   * Holds the value for the entered duration as string.
   * This value will be transformed into minutes by the
   * Checkbox component after submit.
   */
  const [duration, setDuration] = useState<string>('');
  /**
   * Holds the reference to the input field.
   * We use it to focus on the input once the
   * edit mode is enabled so the user can type
   * in it right away.
   */
  const inputRef = useRef<any>(null);
  /**
   * Focus on the input field once
   * the field is rendered.
   */
  useEffect(() => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
    /**
     * If there was already timeSpent on the
     * current task then set that as initial
     * value for the textfield.
     */
    if (timeSpent) {
      setDuration(timeHelper.humanize(timeSpent));
    }
  }, []);
  /**
   * Handle the input changes from
   * the user when inputing the
   * duration.
   */
  const onChange = (event: any) => {
    setDuration(event.target.value);
  }
  /**
   * Handles the cancellation of the
   * new timeSpent.
   */
  const onCancel = (event: any) => {
    setTimeout(() => setReadonly(true), 100);
    handleDurationChange(timeHelper.humanize(timeSpent));
  }
  /**
   * Handle the event when the user
   * pressed the ENTER key.
   */
  const onKeyPress = (event: any) => {
    // If the user pressed ENTER.
    if (event.charCode == 13 || event.code === 'Enter') {
      handleDurationChange(duration);
      /**
       * Set back the parent component to readonly TRUE
       * which will disable the edit mode and make this
       * component unmount.
       */
      setReadonly(true);
    }
  }

  return (
    <Flex direction='row' justifyContent='flex-start' alignItems='center'>

      {/* The textfield for entering the duration in string format. */}
      <Input
        isRequired={true}
        fontSize='sm'
        ml='8px'
        p='4px'
        type='text'
        placeholder='1h 30m'
        fontWeight='500'
        w='80px'
        size='sm'
        value={duration}
        ref={inputRef}
        onChange={(event: any) => onChange(event)}
        onKeyPress={(event: any) => onKeyPress(event)}
        aria-label="Task duration"
      />

      {/* The cancel button. */}
      <CustomButton
        callback={(event: any) => onCancel(event)}
        icon={MdOutlineCancel}
      />
    </Flex>
  )
}

/**
 * Renders the components required for the user
 * to manage the duration for the current task.
 */
const TimeSpent: React.FC<Props> = ({ duration, handleDurationChange }) => {
  /**
   * Indicates if the component is in readonly mode.
   * This state is used to enable edit mode for the
   * time spent property.
   */
  const [readonly, setReadonly] = useState<boolean>(true);

  return (
    <Flex direction='row' justifyContent='flex-end' alignItems='center' gap='1' onClick={() => setReadonly(false)}>
      {!!duration && readonly && <ShowDuration duration={duration} />}
      {(!duration && readonly) && <ShowAddButton setReadonly={setReadonly} />}
      {!readonly && <AddDuration handleDurationChange={handleDurationChange} timeSpent={duration || 0} setReadonly={setReadonly} />}
    </Flex>
  )
}

export default TimeSpent