// Chakra imports
import { AvatarGroup, Avatar, Box, Button, Flex, Icon, Image, Link, Text, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
// Assets
import { useEffect, useState } from 'react';
import { IoHeart, IoHeartOutline } from 'react-icons/io5';
import { IUserProfile } from 'state/Users/UsersService';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import { IProject } from 'state/Projects/Dto';
import { useNavigate } from 'react-router-dom';
import { useProjects } from 'state/Projects/useProjectsState';

export default function Project(props: {
	image: string;
  project: IProject;
}) {
  const { image, project } = props;
	const [ like, setLike ] = useState(false);
	const textColor = useColorModeValue('navy.700', 'white');
	const textColorBid = useColorModeValue('brand.500', 'white');
  const navigate = useNavigate();
  // Store the currently selected project if viewing it.
  const { setCurrentProject } = useProjects();
  // The user object that created the project.
  const [createdByUser, setCreatedByUser] = useState<IUserProfile>(null);

  const openProject = (projectId: string) => {
    // Set the selected project as current.
    setCurrentProject(project);
    // Navigate to the project view page.
    navigate(`/project/${projectId}`);
  }

  /**
   * Get the user object that created
   * the current project.
   */
  useEffect(() => {
    console.log('project:', project);
    setCreatedByUser(project.userObjects.find((user: IUserProfile) =>
      user.userId === project.createdBy
    ));
  }, [project]);

	return (
		<Card p='20px'>
			<Flex direction={{ base: 'column' }} justify='center'>
				<Box mb={{ base: '20px', '2xl': '20px' }} position='relative'>
					<Image
						src={image}
						w={{ base: '100%', '3xl': '100%' }}
						h={{ base: '100%', '3xl': '100%' }}
						borderRadius='20px'
					/>
					<Button
						position='absolute'
						bg='white'
						_hover={{ bg: 'whiteAlpha.900' }}
						_active={{ bg: 'white' }}
						_focus={{ bg: 'white' }}
						p='0px !important'
						top='14px'
						right='14px'
						borderRadius='50%'
						minW='36px'
						h='36px'
						onClick={() => {
							setLike(!like);
						}}>
						<Icon
							transition='0.2s linear'
							w='20px'
							h='20px'
							as={like ? IoHeart : IoHeartOutline}
							color='brand.500'
						/>
					</Button>
				</Box>
				<Flex flexDirection='column' justify='space-between' h='100%'>
					<Flex
						justify='space-between'
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mb='auto'>
						<Flex direction='column'>
							<Text
								color={textColor}
								fontSize={{
									base: 'xl',
									md: 'lg',
									lg: 'lg',
									xl: 'lg',
									'2xl': 'md',
									'3xl': 'lg'
								}}
								mb='5px'
								fontWeight='bold'
								me='14px'>
								{project.name}
							</Text>
							<Text
								color='secondaryGray.600'
								fontSize={{
									base: 'sm'
								}}
								fontWeight='400'
								me='14px'>
								{createdByUser?.displayName}
							</Text>
						</Flex>
						<AvatarGroup
							max={3}
							color={textColorBid}
							size='sm'
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}
							fontSize='12px'>
							{!!project.userObjects?.length && project.userObjects?.map((user: IUserProfile, key: number) => <Avatar key={key} src={user.avatar || Avatar1} />)}
						</AvatarGroup>
					</Flex>
					<Flex
						justify='space-between'
						align={{
							base: 'center',
							md: 'start',
							lg: 'center',
							xl: 'start',
							'2xl': 'center'
						}}
						direction={{
							base: 'row',
							md: 'column',
							lg: 'row',
							xl: 'column',
							'2xl': 'row'
						}}
						mt='25px'>
						<Text fontWeight='700' fontSize='sm' color={textColorBid}>
							{/* Current Bid: {currentbid} */}
						</Text>
						<Link
							// href={download}
							mt={{
								base: '0px',
								md: '10px',
								lg: '0px',
								xl: '10px',
								'2xl': '0px'
							}}>
							<Button
								variant='darkBrand'
								color='white'
								fontSize='sm'
								fontWeight='500'
								borderRadius='70px'
								px='24px'
								py='5px'
                onClick={() => openProject(project.id)}>
								View
							</Button>
						</Link>
					</Flex>
				</Flex>
			</Flex>
		</Card>
	);
}
