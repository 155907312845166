import { Badge, Flex, Icon, useColorModeValue } from "@chakra-ui/react"
import { MdOutlineDelete } from "react-icons/md";

interface Props {
  tag: string;
  allTags: string[];
  setAllTags: any;
}

const Tag: React.FC<Props> = ({ tag, allTags, setAllTags }) => {
  const iconColor = useColorModeValue('red.500', 'white');

  return (
    <Badge colorScheme="blue" p='2px'>
      <Flex direction='row' justifyContent='space-between' alignItems='center' fontSize='xs'>
        {tag}
        <Icon
          as={MdOutlineDelete}
          color={iconColor}
          w='16px'
          h='16px'
          ml='4px'
          cursor='pointer'
          onClick={() => setAllTags(allTags.filter((currentTag: string) => currentTag !== tag))}
        />
      </Flex>
    </Badge>
  )
}

export default Tag;