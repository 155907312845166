import { Badge, Flex, useColorModeValue } from '@chakra-ui/react';
// Custom components
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { ToastTypeEnum } from 'components/Toast/Toast';
import { TimeHelper } from 'logic/Time.helper';
import { TypeHelper } from 'logic/type.helper';
import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import { IModalState, useAppContext } from 'state/useAppState';
import { useAuth } from 'state/useAuthState';
import TaskTags from './Other/TaskTags';
import TicketCardHeader from './Todos/TicketCardHeader';
import { TodosList } from './Todos/TodosList';

interface Props {
  entry: IEntry;
  addMode?: boolean;
  setShouldAddTask?: any;
}

export const TicketCard: React.FC<Props> = ({ entry, addMode, setShouldAddTask }) => {
  const { currentDate } = useAppContext();
  // The time helper class.
  const timeHelper = new TimeHelper();
  // Helps detecting task type indicators inside the label.
  const typeHelper = new TypeHelper();
  // Get the id of the currently logged in user.
  const { currentUser } = useAuth();
  /**
   * Get the list of tasks and the tasks list setter
   * from the app context.
   */
  const { addTask, editTask, markTask, updateTaskTimeSpent, deleteTask, setModalState } = useAppContext();
  // Indicates if the component is in readonly mode.
  const [readonly, setReadonly] = useState<boolean>(true);
  // Current complete state.
  const [state, setState] = useState<boolean>(false);
  // Holds the current label value.
  const [currentLabel, setCurrentLabel] = useState<string>(entry.label);
  // Holds the initial label value.
  const [initialLabel, setInitialLabel] = useState<string>(entry.label);
  // Holds the current task duration.
  const [duration, setDuration] = useState<string>('');
  // Holds the list of todos for the current task.
  const [showTodos, setShowTodos] = useState<boolean>(!!entry.todos && !!entry.todos.length);
  // Indicates the type of the current task.
  const [taskType, setTaskType] = useState<string>(entry.type || typeHelper.detectTaskTypeByLabel(entry.label));
  // Indicates if the edit mode for the tags is active or not.
  const [editTagsMode, setEditTagsMode] = useState<boolean>(false);
  // Holds the input reference for the task label.
  const inputRef: any = useRef(null);
  // Used to set the autohiding toast when required.
  const { setToast } = useAppContext();
  /**
   * Change the state of the current
   * selection.
   */
  const handleOnComplete = (event: any) => {
    const isChecked = event.target.checked;
    markTask(entry.id, isChecked);
  }
  /**
   * Handle the user input of the duration
   * for the current task.
   */
  const handleDurationChange = (event: any, isLabel?: boolean) => {
    // Convert the provided duration to minutes.
    const minutes = timeHelper.deHumanize(event);
    if (isLabel && !minutes) {
      return;
    }
    updateTaskTimeSpent(entry.id, minutes);
  }
  /**
   * Handle the deletion of the current
   * task.
   * 
   * @param id string
   * @returns void
   */
  const handleDelete = (id: string): void => {
    // Open the confirmation dialog.
    const modal: IModalState = {
      title: 'Delete confirmation',
      content: <ConfirmationModal />,
      props: { name: entry.label, id: entry.id },
      actions: [
        { label: 'Cancel', callback: setModalState, props: [null], color: 'slate-400' },
        {
          label: 'Confirm', callback: (taskId: string) => {
            deleteTask(taskId);
            setModalState(null)
          }, props: [entry.id], color: 'red.500'
        }
      ]
    }
    setModalState(modal);
  }
  /**
   * Resize the textarea automatically based
   * on the content update.
   */
  const resizeTextarea = (event: any) => {
    event.target.style.height = 'auto';
    event.target.style.height = (event.target.scrollHeight) + 'px'
  }
  /**
   * Handle the event of changing the label
   * of the current task.
   */
  const handleLabelChange = (taskId: string, event: any) => {
    // Get the new value from the event.
    const value = event.target.value;
    // Set the current label value.
    setCurrentLabel(value);
    // Resize the textarea.
    resizeTextarea(event);
  }
  /**
   * Save the current label value as the
   * initial label value.
   */
  const handleSave = () => {
    if (!initialLabel && !currentLabel) {
      setShouldAddTask && setShouldAddTask(null);
      return;
    }
    /**
     * Check if the user entered a task type
     * indicator ('feature:', 'bug:', 'improvement')
     * and remove it from the label.
     */
    const taskTypeIndicator = typeHelper.getTypeIndicator(currentLabel);
    // Replace the initial label with the current one.
    setInitialLabel(taskTypeIndicator.label);
    setCurrentLabel(taskTypeIndicator.label);
    // Set the current task type if an indicator was found.
    setTaskType(taskTypeIndicator.indicator || entry.type);
    // Change once again to readonly mode.
    setReadonly(true);
    /**
     * Check if the current task already has
     * an ID => then we just save the update
     * to the context tasks.
     */
    if (entry.id && !addMode) {
      editTask({ ...entry, label: taskTypeIndicator.label, type: taskTypeIndicator.indicator || entry.type || '' });
    }
    // Save new task to the task list.
    if (addMode) {
      // Compose the new task object.
      const newTask = { ...entry, label: taskTypeIndicator.label, userId: currentUser.uid, type: taskTypeIndicator.indicator || '' };
      // Dispatch the new task object to the context.
      addTask(newTask);
    }
    setShouldAddTask && setShouldAddTask(null);
  }
  /**
   * Handle the ENTER key press.
   */
  const handleKeyPress = (event: any) => {
    if ((event.charCode == 13 || event.code === 'Enter') && !event.shiftKey) {
      handleSave();
    }
    if ((event.keyCode == 27 || event.code === 'Escape') && !event.shiftKey) {
      handleCancel();
    }
  }
  /**
   * Cancels the edit of the current task.
   */
  const handleCancel = () => {
    // Set once again to readonly mode.
    setReadonly(true);
    // Revert back to the initial label value.
    setCurrentLabel(initialLabel);
    /**
     * Remove this component if the user tried
     * to add a new task.
     */
    setShouldAddTask && setShouldAddTask(null);
  }
  /**
   * Handle the click on the task label which
   * will enable the edit mode on current task.
   */
  const handleTaskClick = (taskId: string) => {
    // Enable the edit mode for the current task.
    setReadonly(false);
    // Set the focus on the textfield.
    setTimeout(() => {
      inputRef.current.focus();
      document.getElementById('textarea-description').setAttribute('style', 'height:' + (document.getElementById('textarea-description').scrollHeight) + 'px;overflow-y:hidden;')
    }, 0);
  }
  /**
   * Handle the editing of the current task
   * tags.
   */
  const handleEditTags = () => {
    setEditTagsMode(true);
  }
  /**
   * Copies the label of the currently selected
   * task to clipboard.
   */
  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(entry.label);
    setToast({ type: ToastTypeEnum.SUCCESS, content: 'Task label was copied to clipboard' });
  }
  /**
   * Set the initial state of the current
   * checkbox using the provided prop.
   */
  useEffect(() => {
    setState(entry.completed);
    if (addMode) {
      setReadonly(false);
      // Set the focus on the input.
      setTimeout(() => inputRef.current.focus(), 100);
    }
    setInitialLabel(entry.label);
    setDuration(timeHelper.humanize(entry.timeSpent || 0));
    setTaskType(entry.type || typeHelper.detectTaskTypeByLabel(entry.label));
  }, []);

  // Chakra color mode
  const shadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "transparent"
  );
  const kanbanCardBg = useColorModeValue("white", "navy.700");

  return (
    <Flex
      borderLeft='4px solid'
      borderColor={typeHelper.getTypeColor(taskType)}
      key={entry.id}
      boxShadow={shadow}
      mt='10px'
      flexDirection='column'
      bg={kanbanCardBg}
      p='25px'
      borderRadius='15px'
      w='100%'>
      <TicketCardHeader
        entry={entry}
        currentLabel={currentLabel}
        inputRef={inputRef}
        handleKeyPress={handleKeyPress}
        handleLabelChange={handleLabelChange}
        handleTaskClick={handleTaskClick}
        readonly={readonly}
        handleDurationChange={handleDurationChange}
        handleEditTags={handleEditTags}
        handleCopyToClipboard={handleCopyToClipboard}
        handleDelete={handleDelete}
      />
      {/* <Text fontSize='md' color={textColorSecondary}>
        {desc}
      </Text> */}
      <Flex w='100%' justifyContent='flex-start' alignItems='flex-start' align='left'>
        {taskType ? (
          <Badge
            fontSize='10px'
            fontWeight='bold'
            variant='solid'
            h='28px'
            w='94px'
            display='flex'
            borderRadius='8px'
            alignItems='center'
            justifyContent='center'
            bg={typeHelper.getTypeColor(taskType)}
            colorScheme={typeHelper.getTypeColor(taskType)}>
            {taskType}
          </Badge>
        ) : null}
      </Flex>
      <Flex w='100%' mt='20px' align='left'>
        <TodosList entry={entry} />
      </Flex>
      {entry.tags &&
        <Flex w='100' mt='20px' align='left'>
          {/* TAGS. */}
          <TaskTags
            editMode={editTagsMode}
            setEditMode={setEditTagsMode}
            tags={entry.tags}
            setTags={(tags: string[]) => {
              entry.tags = tags;
              editTask({ ...entry });
            }} />
        </Flex>
      }
    </Flex>
  )
}