import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Box, Flex, HStack, Text, useColorModeValue } from '@chakra-ui/react';
import { ILink } from 'routes';

interface Props {
  index: number;
  link: ILink;
}

// verifies if routeName is the one active (in browser input)
const activeRoute = (location: any, routeName: string) => {

  return location.pathname.includes(routeName);
};

export const SidebarLink: React.FC<Props> = ({ index, link }) => {
  const location = useLocation();
  const navigate = useNavigate();
  let activeColor = useColorModeValue('gray.700', 'white');
  let inactiveColor = useColorModeValue('secondaryGray.600', 'secondaryGray.600');
  let activeIcon = useColorModeValue('brand.500', 'white');
  let textColor = useColorModeValue('secondaryGray.500', 'white');
  let brandColor = useColorModeValue('brand.500', 'brand.400');
  /**
   * Redirect the user to the indicated
   * location by the provided location.
   */
  const loadPage = (event: any, layout: string, path: string) => {
    event.preventDefault();
    navigate(`${path}`);
  }

  return (
    <a onClick={(e: any) => loadPage(e, link.layout, link.path)} style={{ cursor: 'pointer' }}>
      {link.icon ? (
        <Box>
          <HStack
            spacing={activeRoute(location, link.path.toLowerCase()) ? '22px' : '26px'}
            py='5px'
            ps='10px'>
            <Flex w='100%' alignItems='center' justifyContent='center'>
              <Box
                color={activeRoute(location, link.path.toLowerCase()) ? activeIcon : textColor}
                me='18px'>
                {link.icon}
              </Box>
              <Text
                me='auto'
                color={activeRoute(location, link.path.toLowerCase()) ? activeColor : textColor}
                fontWeight={activeRoute(location, link.path.toLowerCase()) ? 'bold' : 'normal'}>
                {link.name}
              </Text>
            </Flex>
            <Box
              h='36px'
              w='4px'
              bg={activeRoute(location, link.path.toLowerCase()) ? brandColor : 'transparent'}
              borderRadius='5px'
            />
          </HStack>
        </Box>
      ) : (
        <Box>
          <HStack
            spacing={activeRoute(location, link.path.toLowerCase()) ? '22px' : '26px'}
            py='5px'
            ps='10px'>
            <Text
              me='auto'
              color={activeRoute(location, link.path.toLowerCase()) ? activeColor : inactiveColor}
              fontWeight={activeRoute(location, link.path.toLowerCase()) ? 'bold' : 'normal'}>
              {link.name}
            </Text>
            <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
          </HStack>
        </Box>
      )}
    </a>
  )
}