import { Avatar, Box, Flex, FormLabel, Grid, GridItem, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

import TasksDeleteWizard from 'components/modals/TasksDeleteWizard';
import TasksMoveWizard from 'components/modals/TasksMoveWizard';
import React, { ReactNode } from 'react';
import { IEntry } from '../../state/Tasks/TasksDTO';
import { IModalState, useAppContext } from '../../state/useAppState';
import { TaskAddType } from './ticket-list';
import { useState } from "react";
import { TimeHelper } from "logic/Time.helper";
import { MdAddBox, MdCalendarToday, MdContentPaste, MdDeleteSweep } from 'react-icons/md';
import CustomButton from 'components/CustomButton/CustomButton';

interface Props {
  handleOpenTasksMoveWizard: any;
  handleDeleteTasksWizard: any;
  setShouldAddTask: any;
}

const TicketGroupButtons: React.FC<Props> = ({
  handleOpenTasksMoveWizard,
  handleDeleteTasksWizard,
  setShouldAddTask,
}) => {

  return (
    <Flex
      w='250px'
      px='25px'
      justifyContent='space-between'
      alignItems='center'
    >

      {/* Move marked tasks to todays date. */}
      <CustomButton callback={handleOpenTasksMoveWizard} icon={MdCalendarToday} />

      {/* Delete marked tasks. */}
      <CustomButton callback={handleDeleteTasksWizard} icon={MdDeleteSweep} />

      {/* Add new task from clipboard. */}
      <CustomButton callback={() => setShouldAddTask(TaskAddType.CLIPBOARD)} icon={MdContentPaste} />

      {/* Add new task button. */}
      <CustomButton callback={() => setShouldAddTask(TaskAddType.NORMAL)} icon={MdAddBox} />

      {/* Add new task modal button. */}
      {/* <button
          title="Add task"
          className="shrink-0 text-indigo-500 hover:text-indigo-600 ml-3 mr-3"
          onClick={() => setModalState({
            title: 'Add new task',
            content: <TasksEditModal entry={null} />,
            props: null,
          })}
        >
          <svg className="w-4 h-4 fill-current" viewBox="0 0 16 16">
            <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
          </svg>
        </button> */}

    </Flex >
  )
}

export default TicketGroupButtons;