export class HumanizerHelper {

  /**
   * Convert the provided number of minutes
   * into a human readable time period.
   * 
   * @param duration number
   * @returns string
   */
  public humanize(duration: number): string {
    if (!duration) {
      return `0m`;
    }
    const hours = Math.floor(this.getHours(duration));
    const minutes = duration - (hours * 60);
    // First insert the hours if required.
    const theHoursString = hours && `${hours}h`;
    const theMinutesString = minutes && `${minutes}m`;
    // Then insert the remaining minutes.
    return `${hours ? theHoursString : ''} ${minutes ? theMinutesString : ''}`;
  }

  /**
   * Convert the given duration as string
   * into minutes.
   * 
   * @param duration string
   * @returns number
   */
  public deHumanize(duration: string): number {
    // Find the hours.
    const hourRegex = /(\d+)h/;
    // Find the minutes.
    const minuteRegex = /(\d+)m/;
    // Get the hours.
    const hoursMatch = duration.match(hourRegex);
    const hoursResult = hoursMatch && hoursMatch.length && hoursMatch[0];
    // Get the minutes.
    const minutesMatch = duration.match(minuteRegex);
    const minutesResult = (minutesMatch && minutesMatch.length && minutesMatch[0]) || '0';
    /**
     * Calculate the total of minutes from
     * the obtained hours and minutes.
     */
    return hoursResult ? (parseInt(hoursResult) * 60) + parseInt(minutesResult) : parseInt(minutesResult);
  }

  /**
   * Gets the total amound of hours
   * in the given amount of minutes
   * provided.
   * 
   * @param duration number
   * @returns number
   */
  private getHours(duration: number): number {
    return duration / 60;
  }
}