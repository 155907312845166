// Chakra imports
import {
  Flex,
  Image,
  Icon,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure
} from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';

// Assets
import balanceImg from 'assets/img/dashboards/balanceImg.png';
import fakeGraph from 'assets/img/dashboards/fakeGraph.png';
import { MdOutlineMoreHoriz, MdDomain } from 'react-icons/md';
// Assets
import { MdOutlinePerson } from 'react-icons/md';
import { useContracts } from 'state/useContractsState';
import { IModalState, useAppContext } from 'state/useAppState';
import { useCallback, useEffect, useState } from 'react';
import { IContract } from 'state/Contracts/ContractsService';
import { TasksHelper } from 'logic/Tasks.helper';
import { TimeHelper } from 'logic/Time.helper';
import ContractModal from 'components/modals/ContractModal';
import ContractItem from './ContractItem';

export default function Services(props: { [x: string]: any }) {
  const { ...rest } = props;
  // Ellipsis modals
  const { isOpen: isOpen1, onOpen: onOpen1, onClose: onClose1 } = useDisclosure();

  // Chakra Color Mode
  const blueIcon = useColorModeValue('blue.500', 'white');
  const balanceBg = useColorModeValue('red.900', '#1B254B');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  const textHover = useColorModeValue(
    { color: 'secondaryGray.900', bg: 'unset' },
    { color: 'secondaryGray.500', bg: 'unset' }
  );
  const bgList = useColorModeValue('white', 'whiteAlpha.100');
  const bgShadow = useColorModeValue('14px 17px 40px 4px rgba(112, 144, 176, 0.08)', 'unset');
  const headerBoxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const noContractsBoxBg = useColorModeValue('red.50', 'red.50');

  const { buyerContracts } = useContracts();
  const { buyerTasks, hideAmounts, setModalState } = useAppContext();
  const tasksHelper = new TasksHelper();
  const timeHelper = new TimeHelper();
  // Holds the list of processed contracts.
  const [contracts, setContracts] = useState<IContract[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [totalTime, setTotalTime] = useState<number>(0);

  const processContracts = useCallback(() => {
    // Parse through all the contracts.
    return buyerContracts.map((contract: IContract) => {
      const totalTime = tasksHelper.filterTimeSpentForContractForPeriod(buyerTasks, new Date(), contract.id);
      return ({
        ...contract,
        timeSpent: totalTime,
        price: ((totalTime / 60) * contract.rate) * -1
      })
    });
  }, [buyerContracts, buyerTasks]);

  /* A function that takes in an array of contracts and returns the total time. */
  const getTotalTime = useCallback((processedContracts: IContract[]) => {
    return processedContracts.reduce((acc: number, curr: IContract) => acc + curr.timeSpent, 0);
  }, [buyerContracts, buyerTasks]);

  /* A function that takes in an array of contracts and returns the total price. */
  const getTotalPrice = useCallback((processedContracts: IContract[]) => {
    return processedContracts.reduce((acc: number, curr: IContract) => acc + curr.price, 0);
  }, [buyerContracts, buyerTasks]);
  /**
   * Open the new service request modal
   * for creating a new contract.
   */
  const handleAddContract = () => {
    // Open the confirmation dialog.
    const modal: IModalState = {
      title: 'New service request',
      content: <ContractModal />,
      props: null,
      actions: [
        { label: 'Cancel', callback: setModalState, props: [null], color: 'slate-400' },
      ],
      size: 'lg'
    }
    setModalState(modal);
  }

  useEffect(() => {
    if (!buyerContracts?.length) return;
    const theProcessedContracts = processContracts();
    // Set the processed contracts state.
    setContracts(theProcessedContracts);
    // Set the total time state.
    setTotalTime(getTotalTime(theProcessedContracts));
    // Set the total price state.
    setTotalPrice(getTotalPrice(theProcessedContracts));
  }, [buyerContracts, buyerTasks]);

  return (
    <Card flexDirection='column' w='100%' {...rest}>
      <Flex
        justify='space-between'
        p='20px'
        mb='20px'
        borderRadius='16px'
        bgColor={balanceBg}
        bgImage={balanceImg}
        bgPosition='right'
        bgSize='cover'>
        <Flex align='center' justify='space-between' w='100%'>
          <Flex flexDirection='column' me='20px'>
            <Text color='white' fontSize='sm' fontWeight='500'>
              Current month expenses
            </Text>
            <Text color='white' fontSize='34px' fontWeight='700' lineHeight='100%'>
              {totalPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
            </Text>
          </Flex>
          <Flex flexDirection='column' ms='auto' justify='space-between' align='flex-end'>
            <Menu isOpen={isOpen1} onClose={onClose1}>
              <MenuButton onClick={onOpen1}>
                <Icon
                  cursor='pointer'
                  as={MdOutlineMoreHoriz}
                  color='white'
                  mt='-2px'
                  mb='12px'
                  w='30px'
                  h='30px'
                />
              </MenuButton>
              <MenuList
                w='150px'
                minW='unset'
                maxW='150px !important'
                border='transparent'
                backdropFilter='blur(63px)'
                bg={bgList}
                boxShadow={bgShadow}
                borderRadius='20px'
                p='15px'>
                <MenuItem
                  transition='0.2s linear'
                  color={textColor}
                  _hover={textHover}
                  p='0px'
                  borderRadius='8px'
                  _active={{
                    bg: 'transparent'
                  }}
                  _focus={{
                    bg: 'transparent'
                  }}
                  mb='1'>
                  <Flex align='center' onClick={() => handleAddContract()}>
                    <Icon as={MdOutlinePerson} h='16px' w='16px' me='8px' />
                    <Text fontSize='sm' fontWeight='400'>
                      New request
                    </Text>
                  </Flex>
                </MenuItem>
              </MenuList>{' '}
            </Menu>
            <Image src={fakeGraph} w='59px' h='17px' />
          </Flex>
        </Flex>
      </Flex>
      {/* The explanations. */}
      <Flex direction='column' bg={headerBoxBg} p='16px 20px' borderRadius='14px' mb='20px'>
        <Text fontSize='sm' fontWeight='700' color={textColor}>
          Services you are paying for
        </Text>
        <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
          The number of logged hours and the total amount that is due, all are in accordance with the already accepted contract details.
        </Text>
      </Flex>
      <Text color='secondaryGray.600' fontWeight='500' fontSize='sm' mb='10px'>
        All contracts
      </Text>
      {!!contracts.length &&
        <Flex direction='column'>
          {contracts.map((contract: IContract) =>
            <ContractItem
              key={contract.id}
              mb='20px'
              contract={contract}
              type='seller'
              icon={<Icon as={MdDomain} color={blueIcon} w='20px' h='18px' />}
            />
          )}
        </Flex>
      }
      {!contracts.length &&
        <Flex direction='column' bg={noContractsBoxBg} p='16px 20px' borderRadius='14px' mb='20px'>
          <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
            There are no contracts to be displayed yet.<br/>
            You can request services from other users that are part of the network and made themselves available for hire.
          </Text>
        </Flex>
      }
    </Card>
  );
}
