import { DocumentData } from 'firebase/firestore';
import { TasksDB } from './TasksDb';
import { IEntry, IEntryTodo } from './TasksDTO';

export class TasksStore extends TasksDB {

  /**
   * Get the entire list of formatted
   * tasks from the database.
   * 
   * @returns Promise<IEntry[]>
   */
  public async getAll(): Promise<IEntry[]> {
    return await this.fetchAllInDb().then((response: DocumentData) => {
      return response.docs.map((task: any) => ({
        ...task.data(),
        id: task.id
      }));
    })
  }

  /**
   * Get all the existing tasks in the DB
   * for the provided contractId (quantifierId).
   *
   * @param contractId string
   * @returns Promise<IEntry[]>
   */
  public async getTasksByContractForPeriod(contractId: string, start: Date, end: Date): Promise<IEntry[]> {
    return await this.getNotesByContractForPeriod(contractId, start.getTime(), end.getTime()).then((response: DocumentData) => {
      return response.docs.map((task: any) => ({
        ...task.data(),
        id: task.id
      }));
    })
  }

  /**
   * Get all the tasks from the DB using the
   * provided contract id (quantifierId).
   *
   * @param contractId string
   * @returns Promise<IEntry[]>
   */
  public async getAllTasksByContract(contractId: string): Promise<IEntry[]> {
    return await this.fetchByContract(contractId)
      .then((response: DocumentData) =>
        response.docs.map((task: any) => ({
          ...task.data(),
          id: task.id
        }))
      )
  }

  /**
   * It fetches all documents from the database that match the contractId and userId, and then returns
   * the data from each document
   * @param {string} contractId - string - The id of the contract that the note is associated with
   * @param {string} userId - The userId of the user who created the note
   * @returns An array of objects with the data and id of the document.
   */
  public async getAllByContractAndUser(contractId: string, userId: string): Promise<IEntry[]> {
    return this.fetchByContractAndUser(contractId, userId)
      .then((response: DocumentData) =>
        response.docs.map((note: any) => ({
          ...note.data(),
          id: note.id
        }))
      )
  }

  /**
   * Add a note to the remote database.
   *
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public async addNote(note: IEntry): Promise<boolean> {
    return await this.addInDb(note);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public async updateNote(note: IEntry): Promise<boolean> {
    return await this.updateInDb(note.id, note);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public updateCreatedDateForNotes(notes: IEntry[]): void {
    // Indentify the tasks which have unchecked todos.
    const toBeDuplicated = notes
      .filter(this.hasTodosIncomplete)
      // Remove the id property since it will be a duplicate.
      // Remove the finished todos from the notes.
      .map((note: IEntry) => ({ ...note, id: '', todos: note.todos.filter((todo: IEntryTodo) => !todo.completed) }))
    // Identify the tasks which are normal with finished todos.
    const toBeMoved = notes.filter(this.hasNoTodos || this.hasTodosComplete);
    /**
     * Prepare the list of tasks that need to be updated
     * where the unchecked todos should be removed since
     * they were duplicated in the new task.
     */
    const toUpdateWithRemovingTodos = notes
      // Get the notes which have todos that are unchecked.
      .filter(this.hasTodosIncomplete)
      // Filter out the todos which are unchecked.
      .map((note: IEntry) => ({ ...note, todos: note.todos.filter((todo: IEntryTodo) => !!todo.completed) }));

    /**
     * Methods for perform sequential db updates. 
     */
    const mapSeries = async (notes: IEntry[], action: any) => {
      for (const x of notes) {
        await action(x)
      }
    };
    const mapUpdateTodos = async (notes: IEntry[]) => {
      for (const note of notes) {
        await this.updateTodoInDb(note.id, note.todos);
        await this.updateInDb(note.id, { completed: true });
      }
    }
    // Perform the duplication process.
    toBeDuplicated && toBeDuplicated.length && mapSeries(toBeDuplicated, this.addInDb);
    // Perform the duplication process.
    toUpdateWithRemovingTodos && toUpdateWithRemovingTodos.length && mapUpdateTodos(toUpdateWithRemovingTodos);
    // Perform the move process.
    toBeMoved && toBeMoved.length && mapSeries(toBeMoved, this.updateCreatedDateInDb);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public deleteMultipleNotes(notes: IEntry[]): void {
    const mapSeries = async (notes: IEntry[], action: any) => {
      for (const x of notes) {
        await action(x.id)
      }
    };
    mapSeries(notes, this.deleteInDb);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public async updateNoteTodos(noteId: string, todos: IEntryTodo[]): Promise<boolean> {
    return await this.updateTodoInDb(noteId, todos);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public async deleteNote(id: string): Promise<boolean> {
    return await this.deleteInDb(id);
  }

  /**
   * Update the timeSpent property for the
   * provided note id.
   * 
   * @param id string
   * @param minutes number
   * @returns Promise<boolean>
   */
  public async setTimeSpent(id: string, minutes: number): Promise<boolean> {
    return await this.updateTimeSpentInDb(id, minutes);
  }

  /**
   * Check if the provided note has unfinished
   * todos or not.
   *
   * @param note IEntry
   * @returns boolean
   */
  public hasTodosIncomplete(note: IEntry): number {
    return (note.completed && !!note.todos && note.todos.filter((todo: IEntryTodo) => !todo.completed).length) ||
      (!note.completed && !!note.todos && note.todos.filter((todo: IEntryTodo) => !todo.completed).length);
  }

  /**
   * Check if the provided note is not marked
   * as complete.
   *
   * @param note IEntry
   * @returns boolean
   */
  public isTaskIncomplete(note: IEntry): boolean {
    return !note.completed;
  }

  /**
   * Check if the provided note is marked
   * as complete.
   * 
   * @param note IEntry
   * @returns boolean
   */
  public isTaskComplete(note: IEntry): boolean {
    return note.completed;
  }

  /**
   * Check if the provided note either doesn't have
   * todos or it has them all finished.
   * 
   * When task is marked as complete and has no todos.
   * When task is marked as complete and has all todos complete.
   * 
   * @param note IEntry
   * @returns boolean
   */
  public hasTodosComplete(note: IEntry): number {
    return !!note.todos && !!note.todos.length && note.todos.filter((todo: IEntryTodo) => todo.completed).length;
  }

  /**
   * Check if the provided note doesn't have
   * any todos.
   *
   * @param note IEntry
   * @returns boolean
   */
  public hasNoTodos(note: IEntry): boolean {
    return (!note.todos || !note.todos.length);
  }
}