import { IEntry } from "state/Tasks/TasksDTO";
import { TimeHelper } from "./Time.helper";
import moment from 'moment';
import { endOfDay, endOfMonth, startOfDay, startOfMonth } from "date-fns";

export default class TasksFilters {
  /**
   * Helps with various time related
   * operations that don't belong in
   * a component.
   */
  public timeHelper = new TimeHelper();

  constructor() {
  }

  /**
   * Get the list of all tasks for the provided list
   * of contract ids and the list of tasks provided.
   *
   * @param allTasks IEntry[]
   * @param contractIds string[]
   * @returns IEntry[]
   */
  public filterTasksByContracts(allTasks: IEntry[], contractIds: string[]): IEntry[] {
    return allTasks.filter((task: IEntry) => contractIds.includes(task.quantifierId));
  }

  /**
   * Get the list of tasks for the provided date (month).
   * 
   * @param tasks IEntry[]
   * @param date Date
   * @returns IEntry[]
   */
  public filterTasksForProvidedMonthByContract(tasks: IEntry[], date: Date, contractId: string): IEntry[] {
    const onlyForContract = this.filterTasksByContracts(tasks, [contractId]);
    return this.filterTasksForProvidedMonth(onlyForContract, date);
  }

  /**
   * Get the list of tasks belonging to the current
   * date (one month period).
   *
   * @param tasks IEntry[]
   * @param date Date
   * @returns IEntry[]
   */
  public filterTasksForProvidedMonth(tasks: IEntry[], date: Date): IEntry[] {
    return tasks.filter((task: IEntry) =>
      task.created >= startOfMonth(date).getTime() &&
      task.created <= endOfMonth(date).getTime()
    );
  }

  /**
   * Get the list of tasks belonging to the
   * provided date (one day period).
   * @param tasks 
   * @param date 
   * @returns 
   */
  public filterTasksForProvidedDay(tasks: IEntry[], date: Date): IEntry[] {
    return tasks.filter((task: IEntry) =>
      task.created >= startOfDay(date).getTime() &&
      task.created <= endOfDay(date).getTime()
    );
  }

  /**
  * Get the list of tasks that belong to the
  * provided date.
  * 
  * @param tasks IEntry[]
  * @param date Date
  * @returns IEntry[]
  */
  public filterTasksForProvidedDayByContract(tasks: IEntry[], date: Date, contractId: string): IEntry[] {
    const onlyForContract = this.filterTasksByContracts(tasks, [contractId]);
    return this.filterTasksForProvidedDay(onlyForContract, date);
  }

  /**
   * Filter the provided list of tasks given
   * the provided time period.
   *
   * @param tasks IEntry[]
   * @param start Date
   * @param end Date
   * @returns IEntry[]
   */
  public filterTasksForPeriod(tasks: IEntry[], start: Date, end: Date): IEntry[] {
    return tasks.filter((task: IEntry) =>
      task.created >= startOfDay(start).getTime() && task.created <= endOfDay(end).getTime()
    );
  }
}