import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  where,
  deleteDoc,
  Query,
  onSnapshot,
  QuerySnapshot,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase';

export class InvoicesQueries {

  /**
   * It returns a reference to the invoices collection in the database
   * @returns A collection reference
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'invoices');
  }

  /**
   * It returns a DocumentReference object that represents a document with the given id in the invoices
   * collection
   * @param {string} id - The id of the document you want to get.
   * @returns A DocumentReference<DocumentData>
   */
  public getDocById(id: string): DocumentReference<DocumentData> {
    return doc(db, 'invoices', id);
  }

  /**
   * It returns a query that filters the invoices collection by contractId.
   * @param {string} contractId - string
   * @returns A Query object
   */
  public getQueryContractInvoicesByContractId(contractId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("contractId", "==", contractId),
    );
  }

  /**
   * It returns a query that filters the invoices collection by the contractIds array.
   * @param {string[]} contractIds - string[]
   * @returns A Query object
   */
  public getQueryInvoicesByContractIds(contractIds: string[]): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("contractId", "in", contractIds),
    );
  }
}