import { IField } from "./form.helper";

export const required = (value: any): string => {
  return !!value ? null : 'This field is required';
}

export const isEmail = (value: any): string => {
  const isIt = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(value);
  return !!isIt ? null : 'This is not a valid email address';
}

export const oneUppercase = (value: any): string => {
  const isIt = /(?=.*[A-Z])/.test(value);
  return !!isIt ? null : 'Should have at least one uppercase letter';
}

export const specialChars = (value: any): string => {
  const isIt = /(?=.*[!@#$&*])/.test(value);
  return !!isIt ? null : 'Should have at leastone special character';
}

export const twoDigits = (value: any): string => {
  const isIt = /(?=.*[0-9].*[0-9])/.test(value);
  return !!isIt ? null : 'Should contain at least two digits';
}

export const oneLowercase = (value: any): string => {
  const isIt = /(?=.*[a-z])/.test(value);
  return !!isIt ? null : 'Should contain one lowercase letter';
}

export const minLength = (minLength: number) => {
  return (value: any): string => {
    return value.length >= minLength ? null : `Should have a length of ${minLength} characters`;
  }
}

export const isEqualTo = (fieldName: string) => {
  return (value: any, fields: IField[]): string => {
    // Get the reference field object.
    const foundField = fields.find((field: IField) => field.name === fieldName);
    return value === foundField.value ? null : `Should have the same value as ${fieldName} field`;
  }
}