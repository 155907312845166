import { Flex, Text } from "@chakra-ui/react"
import { HSeparator, VSeparator } from "components/separator/Separator"

export const InvoiceBannerAddress = (props: {
  number: string,
  sellerInformation: any,
}) => {

  return (
    <Flex mb={{ base: '0px', md: '50px' }} direction={{ base: 'column', md: 'row' }}>
      <Flex direction='column' color='white' h='100%' w='100%' mb={{ base: '20px', md: '0px' }}>
        <Text
          mt={{ base: '10px', md: '0px' }}
          fontSize={{ base: 'md', md: '24px', lg: '24px', xl: '24px' }}
          fontWeight='bold'>
          Invoice #: {props.number}
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='regular'>
          {props.sellerInformation.businessName}
        </Text>
        
        <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='regular'>
          IBAN: {props.sellerInformation.iban}
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='regular'>
          Bank Name: {props.sellerInformation.bankName}
        </Text>
        <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='regular'>
          SWIFT: {props.sellerInformation.bankSwift}
        </Text>
      </Flex>
      <VSeparator
        bg='whiteAlpha.300'
        mx={{ base: '10px', md: '40px' }}
        display={{ base: 'none', md: 'flex' }}
      />
      <Flex direction='column' color='white' h='100%' w='100%'>
        <Text fontSize={{ base: 'md', md: 'md' }} mt={{ base: '10px', md: '0px' }} fontWeight='regular'>
          {props.sellerInformation?.street || ''}
        </Text>
        <Text fontSize={{ base: 'md', md: 'md' }} fontWeight='regular'>
          {props.sellerInformation?.city || ''}
        </Text>
        <Text fontSize={{ base: 'md', md: 'md' }} fontWeight='regular'>
          {props.sellerInformation?.country || ''} {props.sellerInformation?.zip || ''}
        </Text>
        <HSeparator
          bg='whiteAlpha.300'
          my={{ base: '10px', md: '15px' }}
          display={{ base: 'none', md: 'flex' }}
        />
        <Text fontSize={{ base: 'lg', md: 'md' }} fontWeight='regular'>
          Company Registrar: {props.sellerInformation.companyRegistrarNr}
        </Text>
        <Text fontSize={{ base: 'lg', md: 'md' }} fontWeight='regular'>
          VAT Code: {props.sellerInformation.vatCode}
        </Text>
      </Flex>
    </Flex>
  )
}