// Chakra imports
import { Box, Flex, Grid, GridItem, SimpleGrid } from '@chakra-ui/react';
// Custom components
import { useAppContext } from 'state/useAppState';
import { useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import TicketList from 'components/Ticket/ticket-list';
import TotalTimeLogged from 'components/card/TotalTimeLogged';
import PersonalInfoSettings from './components/PersonalInfoSettings';
import Profile from './components/Profile';
// import profile from 'assets/img/crm/vbz.png';
import logoWhite from 'assets/img/layout/logoWhite.png';
import banner from 'assets/img/auth/banner.png';
import ActionHistory from './components/ActionHistory/ActionHistory';
import Password from './components/ChangePassword';
import ChangeEmail from './components/ChangeEmail';
import BusinessInfoSettings from './components/BusinessInfoSettings';
import ChangePassword from './components/ChangePassword';
import InvoicingInfoSettings from './components/InvoicingInfoSettings';

export default function ProfileSettingsView() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate } = useAppContext();
  const [tasks, setTasks] = useState<IEntry[]>([]);

  useEffect(() => {
    if (!tasksForSelectedDate) return;
    setTasks(tasksForSelectedDate);
  }, [tasksForSelectedDate]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        {/* Column Left */}
        <Flex direction='column'>
          <Profile name='Vlad Mihalache' avatar={logoWhite} banner={banner} />
          <PersonalInfoSettings />
          <ChangePassword />
          <ChangeEmail />
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
          <BusinessInfoSettings />
          <InvoicingInfoSettings />
          {/* <ActionHistory /> */}
        </Flex>
      </SimpleGrid>
    </Box>
  );
}
