import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { onSnapshot, QuerySnapshot, DocumentData, Unsubscribe } from "firebase/firestore";
import { useAuth } from "state/useAuthState";
import { ITicket } from "./Dto";
import { ticketsService } from "./TicketsService";
import { useProjects } from "state/Projects/useProjectsState";

export interface ITicketsProvider {
  tickets: ITicket[];
}

export const TicketsContext = createContext(null);
export const useTickets = () => useContext(TicketsContext);

const TicketsProvider = (props: { children: any }) => {
  /**
   * Holds the list of all contracts on which the current
   * user is listed as a seller of a service.
   */
  const [tickets, setTickets] = useState<ITicket[]>([]);
  // Get the currently logged in user.
  const { currentUser, personalData } = useAuth();
  // Get the currently selected project object.
  const { currentProject } = useProjects();
  /**
   * Holds the list of all subscriptions from
   * which we need to unsubscribe when this
   * provider unloads.
   */
  const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);

  /**
   * Read the list of projects and setup a
   * snapshot so we can have live updates.
   */
  useEffect(() => {
    if (!currentUser || !personalData || !currentProject) {
      return
    }

    const queryUserTickets = ticketsService.getQueryAllByProjectId(currentProject.id);

    /**
     * Create the callback that subscribes to changes on
     * the current user as a SELLER on the contracts collection.
     */
    const unsubscribeFromTicketsList = onSnapshot(queryUserTickets, (snapshot: QuerySnapshot<DocumentData>) => {
      const foundTickets = snapshot.docs.map((ticket: any) => ({
        ...ticket.data(),
        id: ticket.id
      }));
      setTickets(foundTickets);
    });

    setSubscriptions([unsubscribeFromTicketsList]);

    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [currentUser, personalData]);

  const value: ITicketsProvider = { tickets };

  return (
    <TicketsContext.Provider value={value}>
      {props.children}
    </TicketsContext.Provider>
  )
}

export default TicketsProvider