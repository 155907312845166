import { Navigate } from "react-router-dom";
import { useAuth } from "../state/useAuthState";

const IsLoggedIn = (props: { children: any }) => {
  // Use the auth state hook.
  const { currentUser } = useAuth();

  if (currentUser) return props.children;
  return <Navigate to="/login" replace />;
}

export default IsLoggedIn