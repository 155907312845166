import React from 'react';
import ReactApexChart from 'react-apexcharts';

type ChartProps = {
	// using `interface` is also ok
	[x: string]: any;
};
interface IChartState {
	chartData: any[];
	chartOptions: any;
  w: string;
  h: string;
};

const PieChart: React.FC<IChartState> = ({ chartData, chartOptions, w, h }) => {

  if (!chartData?.length || !chartOptions) {
    return <></>
  }

	return (
    <ReactApexChart
      options={chartOptions}
      series={chartData}
      type='pie'
      width={w || '100%'}
      height={h || '55%'}
    />
  )
}

export default PieChart;
