import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  doc,
  where,
  Query,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase';

export class TicketsQueries {

  /**
   * It returns a reference to the invoices collection in the database
   * @returns A collection reference
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'tickets');
  }

  public getQueryDocById(id: string): DocumentReference<DocumentData> {
    return doc(db, 'tickets', id);
  }

  public getQueryAllByProjectId(projectId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("projectId", "==", projectId),
    );
  }

  public getQueryAllByProjectIds(projectIds: string[]): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("projectId", "in", projectIds),
    );
  }

  public getQueryAllByParentId(id: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("parent", "==", id)
    );
  }

  public getQueryAllByBlockingIds(ids: string[]): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("id", "in", ids)
    );
  }

  public getQueryAllByStateId(stateId: string): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("state", "==", stateId)
    );
  }
}