import { Flex, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
import Project from "./Project";
import Nft1 from 'assets/img/nfts/Nft1.png';
import Avatar1 from 'assets/img/avatars/avatar1.png';
import Avatar2 from 'assets/img/avatars/avatar2.png';
import Avatar3 from 'assets/img/avatars/avatar3.png';
import Avatar4 from 'assets/img/avatars/avatar4.png';
import { useProjects } from "state/Projects/useProjectsState";
import { IProject } from "state/Projects/Dto";

const ListOfProjects = () => {
  const headerBoxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const textColor = useColorModeValue('secondaryGray.500', 'white');
  // Get the list of projects.
  const { projects } = useProjects();

  if (!projects) {
    return <></>;
  }

  return (
    <SimpleGrid columns={{ base: 1, md: 3 }} gap='20px'>
      
      {!!!projects?.length &&
        <Flex>
          {/* The explanations. */}
          <Flex direction='column' bg={headerBoxBg} p='16px 20px' borderRadius='14px' mb='20px'>
            <Text fontSize='sm' fontWeight='700' color={textColor}>
              You are not assigned to any project yet.
            </Text>
            <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
              Please ask your manager to add you to a project or create a new project.
            </Text>
          </Flex>
        </Flex>
      }

      {projects?.map((project: IProject) =>
        <Project
          key={project.id}
          // name={project.name}
          // author={project.createdBy}
          // bidders={[Avatar1, Avatar2, Avatar3, Avatar4, Avatar1, Avatar1, Avatar1, Avatar1]}
          // users={project.userObjects}
          image={Nft1}
          // currentbid='0.91 ETH'
          // download='#'
          project={project}
        />
      )}
    </SimpleGrid>
  );
}

export default ListOfProjects;