import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  where,
  deleteDoc,
  Query,
  getDoc,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase';
import { getStorage, ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { IUserProfile } from './UsersService';
import { IUserDisplayname } from 'state/useAuthState';
import { UsersQueries } from './UsersQueries';

export class UsersDB extends UsersQueries {

  /**
   * Fetch the user object by id.
   *
   * @param userId string
   * @returns Promise<DocumentData>
   */
  public async fetchByUserId(userId: string): Promise<IUserProfile> {
    const queryByUid = query(
      this.getCollection(),
      where('userId', '==', userId)
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByUid)
      .then((response: any) => {
        const foundUser = response.docs.find((doc: any) => doc.data().userId === userId);
        return foundUser ? { ...foundUser.data(), uid: foundUser.id } : null;
      })
      .catch((error: any) => console.error(error));
  }

  /**
   * Fetch the user object by ids.
   *
   * @param userId string
   * @returns Promise<DocumentData>
   */
  public async fetchByUserIdsInDb(userIds: string[]): Promise<IUserProfile[]> {
    const queryByUid = query(
      this.getCollection(),
      where('userId', 'in', userIds)
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByUid)
      .then((response: any) =>
        response.docs.map((doc: any) => ({ uid: doc.id, id: doc.id, ...doc.data() })))
      .catch((error: any) => console.error(error));
  }

  /**
   * Fetch the user object by ids.
   *
   * @param userId string
   * @returns Promise<DocumentData>
   */
  public async fetchByUserAndAvatarWithUserIdsInDb(userIds: string[]): Promise<IUserProfile[]> {
    const queryByUid = query(
      this.getCollection(),
      where('userId', 'in', userIds)
    );
    // Fetch the data using the timestamp.
    const userObjects = await getDocs(queryByUid);
    const usersWithAvatar = userObjects.docs.map((doc: any) =>
      ({ uid: doc.id, id: doc.id, ...doc.data(), avatarPromise: this.getAvatarInStorage(doc.data().userId) })
    )
    return usersWithAvatar;
  }

  /**
   * Fetch the user object by id.
   *
   * @param userId string
   * @returns Promise<DocumentData>
   */
  public async fetchAll(): Promise<IUserProfile[]> {
    const collectionRef = collection(db, 'users');
    const queryByUid = query(
      collectionRef,
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByUid)
      .then((users: any) => users.docs.map((user: any) => ({ ...user.data(), uid: user.id })))
      .catch((error: any) => console.error(error));
  }

  /**
   * Get the list of all user display
   * names currently in the database.
   * 
   * @returns Promise<IUserDisplayname[]>
   */
  public async fetchUsersDisplaynames(): Promise<IUserDisplayname[]> {
    const allUsers = await this.fetchAll();
    return allUsers.map((user: IUserProfile) => ({ displayName: user.displayName, userId: user.userId }))
  }

  /**
   * Fetch the list of notes that where created during the
   * provided date.
   *
   * @param date Date
   * @returns Promise<DocumentData>
   */
  public async fetchByEmailInDb(email: string): Promise<DocumentData> {
    const usersCollectionRef = collection(db, 'users');
    // Create our query to fetch the notes by the provided date.
    const queryByEmail = query(
      usersCollectionRef,
      where('email', '==', email)
    );
    // Fetch the data using the timestamp.
    return await getDocs(queryByEmail)
      .then((response: any) => {
        const foundUser = response.docs.find((doc: any) => doc.data().email === email);
        return foundUser ? { ...foundUser.data(), uid: foundUser.id } : null;
      })
      .catch((error: any) => console.log(error));
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async addInDb(user: IUserProfile): Promise<any> {
    const collectionRef = collection(db, 'users');
    return await addDoc(collectionRef, user)
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  /**
   * Add a notes entry to the
   * database.
   *
   * @param data IEntry
   * @returns boolean
   */
  public async updateInDb(userId: string, user: IUserProfile): Promise<any> {
    const docRef = doc(db, 'users', userId);
    return await updateDoc(docRef, { ...user });
  }

  /**
   * Update the avatar for the provided userId.
   *
   * @param userId string
   * @param image any
   * @returns Promise<any>
   */
  public async updateAvatarInDb(userId: string, file: any): Promise<any> {
    // Get the original file extension.
    const lastDot = file.name.lastIndexOf('.');
    const extension = file.name.substring(lastDot);
    /**
     * Upload the avatar to firebase storage,
     * to the dedicate profile-images folder.
     */
    const storage = getStorage();
    const storageRef = ref(storage, `profile-pictures/${userId}${extension}`);
    // 'file' comes from the Blob or File API
    return await uploadBytes(storageRef, file).then();
  }

  /**
   * Get the avatar for the currently logged in user.
   *
   * @param userId string
   * @returns Promise<any>
   */
  public getAvatarInStorage(userId: string): Promise<any> {
    const storage = getStorage();
    const avatarsFolderRef = ref(storage, `profile-pictures`);
    return listAll(avatarsFolderRef)
      .then((res) => {
        // res.prefixes.forEach((folderRef) => {});
        const foundAvatar = res.items.find((itemRef: any) => itemRef._location.path_.includes(userId));
        if (foundAvatar) {
          // const url = `${foundAvatar.bucket}/${foundAvatar.fullPath}`;
          return getDownloadURL(ref(storage, foundAvatar.fullPath))
            .then((url) => url)
            .catch((error: any) => {
              console.log('error getting download url:', error);
            });
        }
      }).catch((error) => {
        console.error('error reading storage files:', error);
      });
  }

  /**
   * Delete the note with the provided id.
   *
   * @param id string
   * @returns Promise<any>
   */
  public async deleteInDb(id: string): Promise<any> {
    const docRef = doc(db, 'users', id);
    return await deleteDoc(docRef);
  }

  public mapUser(id: string, data: any, avatar: any): IUserProfile {
    return ({
      id,
      ...data,
      avatar,
    })
  }
}

export const usersDB = new UsersDB();
