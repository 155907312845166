import { DocumentData, CollectionReference, Query } from "firebase/firestore";
import { usersService } from "state/Users/UsersService";
import { ContractsDB } from "./ContractsDb";

export interface IContract {
  id?: string;
  name: string;
  // The name of the seller entity.
  sellerName?: string;
  // The id of the seller user.
  sellerId: string;
  // The name of the buyer entity.
  buyerName?: string;
  // The id of the buyer user.
  buyerId: string;
  // The hourly rate.
  rate: number;
  // Required number of hours per day.
  hoursPerDay: number;
  // The total time spent during a specific period (depends on use case).
  timeSpent?: number;
  // The amount in euros for the time spent * hourPerDay.
  price?: number;
  // Indicates if it is marked as default.
  default: boolean;
  // The date when the contract was created.
  created?: number;
  // Indicates the purpose of the contract.
  type?: string;
  /**
   * Indicates the type of contract if it's a
   * buyer or a seller one.
   */
  invoiceType?: 'buyer' | 'seller';
  // Indicates the timestamp when the contract was accepted.
  acceptedDate?: number;
  rejectedDate?: number;
  // Indicates the timestamp when the contract should end.
  endDate?: number;
  // Indicates if this contract has been accepted or not.
  accepted?: boolean;
}

export class ContractsService {
  /**
   * The service that executes the required
   * changes inside the db.
   */
  private contractsDb = new ContractsDB();

  public getCollection(): CollectionReference<DocumentData> {
    return this.contractsDb.getCollection();
  }

  /**
   * Get the query for fetching all the quantifiers
   * belonging to the provided user id.
   * 
   * @param userId string
   * @returns Query<DocumentData>
   */
  public async getQueryByBuyerId(userId: string): Promise<IContract[]> {
    return await this.contractsDb.fetchByBuyerId(userId).then((response: DocumentData) => {
      return this.attachNameProperties(response.docs);
    });
  }

  /**
   * Get the entire list of formatted
   * tasks from the database.
   * 
   * @returns Promise<IEntry[]>
   */
  public async getAllBySellerId(userId: string): Promise<IContract[]> {
    return await this.contractsDb.fetchBySellerId(userId).then((response: DocumentData) => {
      return this.attachNameProperties(response.docs);
    });
  }

  /**
   * Get all the contracts that match the provided buyer
   * and seller id.
   *
   * @param buyerId string
   * @param sellerId string
   * @returns Promise<IContract[]>
   */
  public async findDuplicate(buyerId: string, sellerId: string, rate: number, hoursPerDay: number): Promise<IContract[]> {
    return await this.contractsDb.findDuplicateContracts(buyerId, sellerId, rate, hoursPerDay);
  }

  /**
   * Mark the provided contract id as accepted by
   * the seller of the service.
   *
   * @param contractId string
   * @returns Promise<void>
   */
  public async acceptRequest(contractId: string): Promise<void> {
    return await this.contractsDb.acceptRequestInDb(contractId);
  }

  /**
   * Mark the provided contract id as rejected by
   * the seller of the service.
   *
   * @param contractId string
   * @returns Promise<void>
   */
  public async rejectRequest(contractId: string): Promise<void> {
    return await this.contractsDb.rejectRequestInDb(contractId);
  }

  /**
   * Add the new contract to the DB.
   *
   * @param contract IContract
   * @returns Promise<boolean>
   */
  public async add(contract: IContract): Promise<boolean> {
    return await !!this.contractsDb.addInDb(contract);
  }

  /**
   * Update the provided properties for the quantifier
   * with the provided id.
   * 
   * @param id string
   * @param properties any 
   * @returns void
   */
  public async update(id: string, properties: any): Promise<void> {
    return await this.contractsDb.updateInDb(id, properties);
  }

  /**
   * Update the provided note object
   * on the remote database.
   * 
   * @param note IEntry
   * @returns Promise<boolean>
   */
  public async delete(id: string): Promise<boolean> {
    return await this.contractsDb.deleteInDb(id);
  }

  /**
   * Attach the buyer and seller name
   * properties to the list of provided
   * contracts.
   *
   * @param contracts any[]
   * @returns any[]
   */
  public async attachNameProperties(contracts: any[]): Promise<any[]> {
    return contracts.map(async (contract: any) => {
      // Get the seller name.
      const seller = await usersService.fetchByUserId(contract.sellerId);
      // Get the buyer name.
      const buyer = await usersService.fetchByUserId(contract.sellerId);

      return {
        ...contract.data(),
        sellerName: seller.businessName,
        buyerName: buyer.businessName,
        id: contract.id
      }
    });
  }
}