// Chakra imports
import { Flex, Text } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { VSeparator } from 'components/separator/Separator';

// Assets
import InvoiceBg from 'assets/img/account/InvoiceBg.png';
import { InvoiceBannerActions } from './InvoiceBanner/InvoiceBannerActions';
import { InvoiceBannerAddress } from './InvoiceBanner/InvoiceBannerAddress';
import { ContractInvoiceStatusEnum } from 'state/Invoices/Dto';

export interface IInvoiceViewBannerProps {
  number: string;
  status: ContractInvoiceStatusEnum;
  sellerInformation: any;
}

const InvoiceBanner: React.FC<IInvoiceViewBannerProps> = ({
  number,
  status,
  sellerInformation
}) => {

	return (
		<Card
			backgroundImage={InvoiceBg}
			backgroundRepeat='no-repeat'
			bgSize='cover'
			bgPosition='10%'
			p={{ base: '20px', md: '60px' }}
			pt={{ base: '40px', md: '40px' }}
			pb='140px'>
			

      {/* The banner status and action buttons. */}
      <InvoiceBannerActions
        downloadCallback={() => {}}
        paidCallback={() => {}}
        status={status}
      />

      {/* The seller information. */}
			{sellerInformation &&
        <InvoiceBannerAddress
          number={number}
          sellerInformation={sellerInformation}
        />
      }
		</Card>
	);
}

export default InvoiceBanner;
