import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import BasicModal from "components/Modal/BasicModal";
import { IModalStateAction, useAppContext } from "../../state/useAppState"

const ConfirmationModal = () => {
  // The app wide modal state props.
  const { modalState, setModalState } = useAppContext();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  
  return (
    <BasicModal title={modalState.title}>

      <Text fontSize='md' fontWeight='500' color='secondaryGray.800'>
        Are you sure you would like to delete the following?
      </Text>
      <Text fontSize='md' fontWeight='700' color={textColor}>
        {modalState.props.name}
      </Text>

      <Flex mt='20px' w='100%' direction='row' justifyContent='flex-end' alignItems='center' gap='2'>
        {/* Generate the action buttons from the modalState.actions array. */}
        {modalState.actions && modalState.actions.length && modalState.actions.map((action: IModalStateAction) =>
          <Button
            key={action.label}
            color={action.color}
            onClick={() => action.callback(...action.props)}
          >{action.label}</Button>
        )}
      </Flex>

    </BasicModal>
  )
}

export default ConfirmationModal