// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
// Assets
import TasksTable from 'views/admin/default/components/TasksTable';
import { useAppContext } from 'state/useAppState';
import { useEffect, useRef, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import DashboardBuyerContracts from 'components/DashboardContracts/DashboardBuyerContracts';
import { useAuth } from 'state/useAuthState';
import ChangeTasks from 'components/AdminComponents/ChangeContractIdForNotes/ChangeTasks';
import DashboardSellerContracts from 'components/DashboardContracts/DashboardSellerContracts';
import TotalTimeLogged from 'components/card/TotalTimeLogged';
import TotalEarningsCurrentMonth from 'components/card/TotalEarningsCurrentMonth';
import SellerContractsList from 'components/DashboardContracts/SellerContractsList';
import Balance from 'components/card/BalanceAndServices/Balance';
import Conversion from './components/PieCard';
import PieCard from 'components/card/PieCard';
import PercentBarChart from 'components/charts/PercentBarChart';
import ModalWrapper from 'components/modals/ModalWrapper';
import Services from 'components/card/BalanceAndServices/Services';

export default function DeveloperDashboard() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate: filteredTasks, modalState } = useAppContext();
  const { roles } = useAuth();
  const [tasks, setTasks] = useState<IEntry[]>([]);

  const containerReference = useRef();

  useEffect(() => {
    if (!filteredTasks) return;
    setTasks(filteredTasks);
  }, [filteredTasks]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      {/* 1st row grid. */}
      {roles.includes('seller') &&
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, '2xl': 3 }} gap='20px' mb='20px'>

          <TotalTimeLogged period="month" forAllContracts={true} />

        </SimpleGrid>
      }

      {roles.includes('buyer') &&
        <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 2 }} gap='20px' mb='20px'>

          {/* Show the list of buyer contracts the current user has with some statistics. */}
          {/* <DashboardBuyerContracts /> */}

          {/* <TasksTable tableData={tasks} /> */}

        </SimpleGrid>
      }

      {(roles.includes('seller') || roles.includes('buyer')) &&
        <Box flexDirection='row'>

          <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 2 }} gap='20px' mb='20px'>

            <Balance />

            <Services />

          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap='20px' mb='20px' ref={containerReference}>

            {/* <PercentBarChart reference={containerReference} /> */}

          </SimpleGrid>

          <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap='20px' mb='20px'>
            {/* Show the list of buyer contracts the current user has with some statistics. */}
            {/* <DashboardSellerContracts /> */}

            {/* <SellerContractsList /> */}

          </SimpleGrid>

          {/* <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} gap='20px' mb='20px'>
            <TasksTable tableData={tasks} />
          </SimpleGrid> */}
        </Box>
      }

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
				<TotalSpent />
				<WeeklyRevenue />
			</SimpleGrid> */}
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<CheckTable tableData={tableDataCheck} />
				<SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
					<DailyTraffic />
					<PieCard />
				</SimpleGrid>
			</SimpleGrid> */}
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 2 }} gap='20px' mb='20px' minChildWidth="120px">



      </SimpleGrid>

      {!!modalState &&
        <ModalWrapper>
          {modalState.content}
        </ModalWrapper>
      }
    </Box>
  );
}
