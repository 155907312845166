import { useEffect, useState } from "react";

interface IValidator {
  name: string;
  validate: (value: string) => boolean;
  message: string;
}

interface IFieldOption {
  id: number;
  name: string;
  value: string;
}

export interface IField {
  id: number;
  type: string;
  name: string;
  validators?: (((value: any) => string) | ((value: any, fields: IField[]) => string))[];
  value?: string;
  validationError?: string;
  options?: IFieldOption[];
}

/**
 * Check if all the provided fields are valid.
 * Each field has a validationError property
 * and if that property is null it means that
 * the field is valid.
 * 
 * @param fields IField[]
 * @returns boolean
 */
export const areAllFieldsValid = (fields: IField[]): boolean => {
  // Collect all the validation messages of each field if any.
  const validations = fields.map((field: IField) => !!field.validationError);
  // If there is even one field with validation error then return false.
  return validations.filter((v: boolean) => !!v).length === 0;
}

/**
 * Get the component that will display the validation
 * error when it's the case for the provided field.
 *
 * @param field IField
 */
export const getValidationError = (message: string) => {
  return (
    <label className="text-sx text-orange-500">{message}</label>
  )
}

interface IFieldError {
  name: string;
  message: string;
}

export const useForm = (fieldsConfig: IField[], formRef?: any) => {
  // Holds the list of fields we need for this component.
  const [fields, setFields] = useState<IField[]>(fieldsConfig);
  // Indicates if the current form is valid.
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  /**
   * Find the field by it's name.
   * 
   * @param fieldName string
   * @returns IField
   */
  const getFieldByName = (fieldName: string): IField => {
    return fields.find((field: IField) => field.name === fieldName) || null;
  }
  /**
   * Get the component that will display the validation
   * error when it's the case for the provided field.
   *
   * @param field IField
   */
  const getErrorLabel = (fieldName: string) => {
    const foundField = getFieldByName(fieldName);
    const errorMessage = foundField.validationError || '';
    return (
      <label className="text-sx text-orange-500">{errorMessage}</label>
    )
  }
  /**
   * Get the value for the field with the
   * provided name.
   */
  const getValue = (fieldName: string): string => {
    // Find the field using the provided name.
    const foundField = getFieldByName(fieldName);
    return foundField.value || '';
  }
  /**
   * Run all the validators and get the first
   * found validation error.
   */
  const validate = (validators: any[], value: any): string => {
    // Parse through the validators and get the error message if any.
    if (!!validators && !!validators.length) {
      const errors = validators.map((v: any) => v(value, fields));
      // Set the first error on the field.
      return errors.find((e: string) => !!e) || null;
    }
    return null;
  }
  /**
   * Parse through all the fields and check if
   * any of them have validation errors.
   */
  const checkFormValid = (): boolean => {
    // Parse through the fields and run the validators.
    const errors = fields.map((field: IField) => validate(field.validators, field.value));
    return !errors.length;
  }
  /**
   * Check if the user has pressed the ENTER key.
   * In this case we will try to submit the form.
   */
  const enterKeyPressed = (event: any) => {
    /**
     * If we detected
     */
    if ((event.keyCode == 13|| event.code === 'Enter') &&
        !event.shiftKey && !!formRef) {
        formRef.current.dispatchEvent(
            new Event("submit", { bubbles: true, cancelable: true })
        )
    }
  }
  /**
   * Handle the changes on the value of
   * a provided field.
   */
  const handleFieldChange = (event: any, fieldName: string): void => {
    // Get the field we need.
    const foundField = getFieldByName(fieldName);
    // Set the value of the current field.
    const newValue = foundField.type === 'boolean' ? event.target.checked : event.target.value;
    // Create a new list.
    const newList = [...fields];
    // Put the new value on the required field.
    const foundValueInNewList = newList.find((field: IField) => field.name === fieldName);
    foundValueInNewList.value = newValue;
    // Set the validation error if any.
    const hasError = validate(foundValueInNewList.validators, newValue);
    foundValueInNewList.validationError = hasError;
    // Set the new field.
    setFields(newList);
  }
  /**
   * Collect the field values into
   * an object.
   */
  const collectFieldValues = () => {
    return fields.reduce((acc: any, field: IField) => ({ ...acc, [field.name]: field.value }), {});
  }
  /**
   * Collect the field values and pass them
   * to the provided callback function.
   */
  const onSubmit = (event: any, callback: (values: any) => void) => {
    event.preventDefault();
    /**
     * Don't trigger the callback if the
     * current form is not valid.
     */
    if (checkFormValid()) {
      return;
    }
    const fieldValues = collectFieldValues();
    callback(fieldValues);
  }
  /**
   * Return the required properties for a
   * normal input element.
   *
   * @param fieldName 
   * @returns 
   */
  const setField = (fieldName: string) => {
    return {
      onChange: (event: any) => {
        return handleFieldChange(event, fieldName);
      },
      onKeyDown: (event: any) => {
        return handleFieldChange(event, fieldName);
      },
      value: getValue(fieldName)
    }
  }

  return {
    fields,
    getErrorLabel,
    setField,
    isFormValid,
    onSubmit
  }
}