import { Flex } from "@chakra-ui/react"
import CustomButton from "components/CustomButton/CustomButton"
import { MdSearch, MdChangeCircle, MdCloudDone, MdOutlineRestartAlt, MdDone } from "react-icons/md"
import { IEntry } from "state/Tasks/TasksDTO"
import { fetchAllTasksByContractAndUser, changeQuantifierIds, updateNotes } from "state/UpdateScripts/updateQuantifierInNotes"
import { IModalState, useAppContext } from "state/useAppState"

export const ChangeTasksActions = (props: {
  userId: string,
  setUserId: any,
  oldContractId: string,
  setOldContractId: any,
  newContractId: string,
  setNewContractId: any,
  tasks: IEntry[],
  setTasks: any,
  tasksToChange: IEntry[],
  setTasksToChange: any,
}) => {

  const resetAll = () => {
    props.setUserId('');
    props.setOldContractId('');
    props.setNewContractId('');
    props.setTasks([]);
    props.setTasksToChange([]);
  }

  const save = () => {
    updateNotes(props.tasksToChange, props.oldContractId, props.userId, false).then(() => {
      props.setTasksToChange([]);
      props.setTasks([]);
    });
  }

  return (
    <Flex flexDirection='row' justifyContent='space-between' gap={1}>

      {/* Find the tasks with the given criteria. */}
      <CustomButton
        icon={MdSearch}
        label="Find"
        disabled={!props.userId || !props.oldContractId}
        callback={() => fetchAllTasksByContractAndUser(props.oldContractId, props.userId).then((result: IEntry[]) => props.setTasks(result))}
      />

      {/* Save the changed tasks to DB. */}
      <CustomButton
        icon={MdCloudDone}
        label="Save"
        disabled={!props.newContractId || !props.userId || !props.oldContractId || !props.tasksToChange.length}
        callback={() => save()}
      />

      {/* Reset the form. */}
      <CustomButton
        icon={MdOutlineRestartAlt}
        label="Reset"
        disabled={!props.newContractId || !props.userId || !props.oldContractId}
        callback={() => resetAll()}
      />
    </Flex>
  )
}