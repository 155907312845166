import { Fragment, ReactNode } from "react"

interface Props {
  children: ReactNode;
}

const ModalWrapper: React.FC<Props> = ({ children }) => {

  return (
    <Fragment>
      {children}
    </Fragment>
  )
}

export default ModalWrapper