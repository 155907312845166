// Chakra imports
import { Button, Flex, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useAuth } from 'state/useAuthState';

export default function ChangePassword() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const { personalData, resetPasswordByEmail } = useAuth();
  const toast = useToast();
  // Send a request via email to reset the user password.
  const resetPassword = () => {
    resetPasswordByEmail(personalData.email).then(() => {
      toast({
        title: 'Link sent!',
        description: "Password reset link has been sent to your email address.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      });
    }).catch((e: any) => console.error(e));
  }

  if (!personalData) {
    return <></>
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='40px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Change password
        </Text>
        <Text fontSize='md' color={textColorSecondary}>
          For security reasons, the password reset works by sending a link to your set email address once you request the password reset. Please follow the instructions in the received email to change your password.
        </Text>
      </Flex>
      <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => resetPassword()}>
        Change Password
      </Button>
    </Card>
  );
}
