// Chakra imports
import { Box, SimpleGrid } from '@chakra-ui/react';
// Custom components
import { useAppContext } from 'state/useAppState';
import { useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import TicketList from 'components/Ticket/ticket-list';
import TotalTimeLogged from 'components/card/TotalTimeLogged';

export default function DayView() {
  // Get the list of tasks for the selected date.
  const { tasksForSelectedDate, sellerTasks } = useAppContext();
  const [tasks, setTasks] = useState<IEntry[]>([]);

  useEffect(() => {
    if (!tasksForSelectedDate) return;
    setTasks(tasksForSelectedDate);
  }, [tasksForSelectedDate, sellerTasks]);

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>

      <SimpleGrid columns={{ base: 3, md: 1, lg: 3, xl: 3 }} gap='20px' mb='20px' minChildWidth="120px">

        <TotalTimeLogged period="day" />

      </SimpleGrid>

      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 2 }} gap='20px' mb='20px' minChildWidth="120px">

        <TicketList entries={tasks} />

      </SimpleGrid>
    </Box>
  );
}
