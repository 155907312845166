import { IEntry } from "state/Tasks/TasksDTO";

export interface ITaskTypePattern {
  type: string;
  keywords: string[];
  color: string;
  description: string;
}

export interface ITaskType {
  type: string;
  percentage: number;
  timeSpent: number;
  color: string;
}

export class TypeHelper {
  /**
   * The list of patterns to be used when auto-detecting
   * the type of task based on the task label.
   */
  private patterns: ITaskTypePattern[] = [
    {
      type: 'feature',
      keywords: ['impleme', 'creat', 'feature', 'highlight', 'provid'],
      color: '#00c867',
      description: 'Tasks which are meant to add new functionality.'
    },
    {
      type: 'bug',
      keywords: ['fix', 'solv', 'bug', 'correct'],
      color: '#E14949',
      description: 'Tasks which are meant to fix a broken feature.'
    },
    {
      type: 'review',
      keywords: ['review'],
      color: '#0fe6e6',
      description: 'Review jobs like MRs and PRs.'
    },
    {
      type: 'improvement',
      keywords: ['improv', 'refactor', 'rebuild'],
      color: '#0770be',
      description: 'Tasks which are meant to improve an existing feature.'
    },
    {
      type: 'meeting',
      keywords: ['meet', 'call', 'discuss'],
      color: '#e69723',
      description: 'Meetings and calls where 2 or more people participate.'
    },
    {
      type: 'research',
      keywords: ['check', 'research', 'analy', 'think'],
      color: '#ce37f5',
      description: 'Tasks related to finding and analysing solutions.'
    },
    {
      type: 'documentation',
      keywords: ['document', 'writ', 'describ'],
      color: '#A2B989',
      description: 'Represents tasks related to documentation.'
    },
  ];

  /**
   * Get the list of patters so we can
   * parse through them.
   *
   * @returns ITaskTypePattern[]
   */
  public getPatterns(): ITaskTypePattern[] {
    return this.patterns;
  }

  /**
   * Check if the provided label contains
   * a task type indicator and return it.
   *
   * @param label string
   * @returns string
   */
  public getTypeIndicator(label: string): { label: string, indicator: string } {
    const knownTypes = this.patterns.map((pattern: ITaskTypePattern) => pattern.type);
    const indexOfComma = label.indexOf(':');
    const foundIndicator = knownTypes.find((indicator: string) => label.substring(0, indexOfComma).toLocaleLowerCase() === indicator);
    if (foundIndicator) {
      const regex = new RegExp(`${foundIndicator}:`, "i");
      return { label: label.replace(regex, '').trim(), indicator: foundIndicator };
    }
    return { label: label.trim(), indicator: null };
  }

  /**
   * Get the colour to be used based on the
   * provided indicator name.
   *
   * @param indicator string
   * @returns string
   */
  public getTypeColor(indicator: string): string {
    return this.patterns.find((pattern: ITaskTypePattern) => pattern.type === indicator)?.color || '';
  }

  /**
   * Get a list of all the existing task
   * type colors.
   */
  public getAllTypeColors(): string[] {
    return this.patterns.map((pat: ITaskTypePattern) => pat.color);
  }

  /**
   * Detect the task type by analysing the keywords used inside
   * the provided label.
   *
   * @param label string
   * @returns string
   */
  public detectTaskTypeByLabel(label: string): string {
    // Prepare a list of all matches found with the provided label.
    let foundTypes = this.patterns.map((pattern: ITaskTypePattern) => ({ type: pattern.type, matches: pattern.keywords.filter((keyword: string) => label.toLowerCase().indexOf(keyword) !== -1) }));
    // Add a score based on the number of occuring keywords for each of the matches.
    let occurences = foundTypes.map((match: any) => ({ ...match, score: match.matches.length }));
    // Filter out the occurences which have a score of 0.
    occurences = occurences.filter((occurence: any) => !!occurence.score);
    return occurences && occurences.length ? occurences[0].type : '';
  }

  /**
   * Get the percentage of the provided type
   * of task.
   */
  public getTimePercentForType(tasks: IEntry[], type: string): ITaskType {
    const timeSpentOnTaskType = tasks.filter((task: IEntry) => task.type === type).reduce((acc: number, task: IEntry) => acc + (task.timeSpent || 0), 0);
    const totalDailyTime = tasks.reduce((acc: number, task: IEntry) => acc + (task.timeSpent || 0), 0);
    const result = Math.round(timeSpentOnTaskType * 100 / totalDailyTime);
    return { type, percentage: result, timeSpent: timeSpentOnTaskType, color: this.getTypeColor(type) };
  }

  /**
   * Get a list of task type descriptions for the
   * provided list of tasks.
   * It represents how the time was split up between
   * the provided tasks.
   *
   * @param tasks IEntry[]
   * @returns ITaskType[]
   */
  public getTimePercentForTasks(tasks: IEntry[]): ITaskType[] {
    const allTypes = this.getAllTypes();
    return allTypes.map((type: string) => this.getTimePercentForType(tasks, type));
  }

  /**
   * Detect the types for each of the tasks inside
   * the provided list and return the enhanced version.
   *
   * @param tasks IEntry[]
   * @returns IEntry[]
   */
  public detectTypes(tasks: IEntry[]): IEntry[] {
    return tasks.map((task: IEntry) => !task.type ? ({ ...task, type: this.detectTaskTypeByLabel(task.label) }) : task);
  }

  /**
   * Get a list of all the existing types.
   * @returns string[]
   */
  public getAllTypes(): string[] {
    return [...this.patterns.map((pattern: ITaskTypePattern) => pattern.type), ''];
  }
}