import { Flex, Th, useColorModeValue } from "@chakra-ui/react";

const InvoiceTasksListHead = (props: { label: string }) => {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Th
      colSpan={1}
      pe='10px'
      borderColor={borderColor}
      cursor='pointer'
      onClick={() => { }}>
      <Flex
        justifyContent='space-between'
        align='center'
        fontSize={{ sm: '10px', lg: '12px' }}
        color='gray.400'
      >
        {props.label}
      </Flex>
    </Th>
  );
}

export default InvoiceTasksListHead;