import { Box, Icon, Table, Tbody, Td, Thead, Tr, useColorModeValue } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import { MdDone, MdErrorOutline, MdForward } from "react-icons/md"
import { IEntry } from "state/Tasks/TasksDTO"
import { ComplexTableHeader } from "views/admin/default/components/ComplexTable/ComplexTableHeader"
import { getMonth, getYear } from 'date-fns';
import CustomButton from "components/CustomButton/CustomButton"
import { changeQuantifierIds } from "state/UpdateScripts/updateQuantifierInNotes"

export const ListOfTasksByMonth = (props: {
  tasks: IEntry[],
  newContractId: string,
  tasksToChange: IEntry[],
  setTasksToChange: any
}) => {
  // const iconColorError = useColorModeValue('red.500', 'white');
  // const iconColorSuccess = useColorModeValue('green.500', 'white');
  // Holds the grouping of all tasks by month.
  const [months, setMonths] = useState<IEntry[][]>([]);

  /**
   * Group the provided list of tasks by month.
   * 
   * @param tasks IEntry[]
   * @returns IEntry[][]
   */
  const groupByMonth = (tasks: IEntry[]): IEntry[][] => {
    const grouped: Record<string, IEntry[]> = {};
    tasks.forEach((task: IEntry) => {
      const taskDate = new Date(task.created);
      const monthYearKey = `${getMonth(taskDate) + 1}-${getYear(taskDate)}`;
      if (!grouped[monthYearKey]) {
        grouped[monthYearKey] = [];
      }
      grouped[monthYearKey].push(task);
    });
    return Object.values(grouped);
  }

  /**
   * The provided tasks should be changed accordingly
   * and added to the tasksToChange list.
   * 
   * @param tasks IEntry[]
   * @returns void
   */
  const markForChange = (tasks: IEntry[]): void => {
    // Make the change on the provided tasks.
    const changed = changeQuantifierIds(tasks, props.newContractId);
    console.log('the changed ones:', changed);
    console.log('current list:', props.tasksToChange);
    // Save the tasks to the cumulative list.
    props.setTasksToChange([...props.tasksToChange, ...changed]);
  }

  useEffect(() => {
    if (!props.tasks) return;
    const groupedByMonths = groupByMonth(props.tasks);
    setMonths(groupedByMonths);
  }, [props.tasks]);

  if (!months.length) {
    return <>No tasks found yet...</>
  }

  return (
    <Box>
      <Table variant='simple' color='gray.500' mb='24px' mt="12px">
        <Thead>
          <Tr>
            <ComplexTableHeader colSpan={1} name="Date" />
            <ComplexTableHeader colSpan={1} name="# of tasks" />
            <ComplexTableHeader colSpan={1} name="Old QuantifierId" />
            <ComplexTableHeader colSpan={1} name="UserId" />
            <ComplexTableHeader colSpan={1} name="Actions" />
          </Tr>
          {/* <Tr>
                <ComplexTableHeader colSpan={1} name="Progress" />
              </Tr> */}
        </Thead>
        <Tbody>
          {months.map((month: IEntry[]) =>
            <Tr key={month[0].id}>
              <Td
                fontSize={{ sm: '16px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor='transparent'
              >
                {`${getMonth(month[0].created) + 1} - ${getYear(month[0].created)}`}
              </Td>
              <Td
                fontSize={{ sm: '16px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor='transparent'
              >
                {month.length}
              </Td>
              <Td
                fontSize={{ sm: '16px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor='transparent'>
                {month[0].quantifierId}
              </Td>
              <Td
                fontSize={{ sm: '16px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor='transparent'>{month[0].userId}</Td>
              <Td
                fontSize={{ sm: '16px' }}
                minW={{ sm: '150px', md: '200px', lg: 'auto' }}
                borderColor='transparent'
              >
                <CustomButton
                  icon={MdForward}
                  disabled={!!month[0].batchJobComplete || !props.newContractId}
                  callback={() => markForChange(month)}
                />
              </Td>

            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  )
}