import {
  Avatar,
  Button,
  Flex,
  Image,
  Menu,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  useColorMode
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'state/useAuthState';

export default function ProfileButton() {
  const shadow = useColorModeValue(
    '14px 17px 40px 4px rgba(112, 144, 176, 0.18)',
    '14px 17px 40px 4px rgba(112, 144, 176, 0.06)'
  );
  const borderColor = useColorModeValue('#E6ECFA', 'rgba(135, 140, 189, 0.3)');
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  let menuBg = useColorModeValue('white', 'navy.800');
  const navigate = useNavigate();
  /**
   * Get the currently logged in user
   * information and methods.
   */
  const { personalData, logout, roles } = useAuth();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (location: any, routeName: string) => {
    console.log('active route clicked');
    return location.pathname.includes(routeName);
  };
  /**
   * Redirect the user to the indicated
   * location by the provided location.
   */
  const loadPage = (event: any, path: string) => {
    event.preventDefault();
    navigate(`${path}`);
  }

  if (!personalData) {
    return <></>
  }

  return (
    <MenuList boxShadow={shadow} p='0px' mt='10px' borderRadius='20px' bg={menuBg} border='none'>
      <Flex w='100%' mb='0px' direction='column' h='100%'>
        {roles &&
          <Text
            ps='20px'
            pt='16px'
            w='100%'
            fontSize='sm'
            fontWeight='400'>{`Roles: ${roles.join(', ')}`}</Text>
        }
        <Text
          ps='20px'
          pt='16px'
          pb='10px'
          w='100%'
          borderBottom='1px solid'
          borderColor={borderColor}
          fontSize='sm'
          fontWeight='700'
          color={textColor}>
          👋&nbsp; Hey, {personalData.displayName}
        </Text>
      </Flex>
      <Flex flexDirection='column' p='10px'>
        <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px' onClick={(e: any) => loadPage(e, '/profile')}>
          <Text fontSize='sm'>Profile Settings</Text>
        </MenuItem>
        <MenuItem _hover={{ bg: 'none' }} _focus={{ bg: 'none' }} borderRadius='8px' px='14px' onClick={(e: any) => loadPage(e, '/invoices')}>
          <Text fontSize='sm'>Invoices</Text>
        </MenuItem>
        <MenuItem
          _hover={{ bg: 'none' }}
          _focus={{ bg: 'none' }}
          color='red.400'
          borderRadius='8px'
          px='14px'
          onClick={() => logout()}>
          <Text fontSize='sm'>Log out</Text>
        </MenuItem>
      </Flex>
    </MenuList>
  )
}