import { ContractsDB } from "state/Contracts/ContractsDb";
import { IContract } from "state/Contracts/ContractsService";

export default class ContractHelper {

  // Helps with quantifier DB operations.
  private contractsDB = new ContractsDB();

  /**
   * Check if the user with the provided ID
   * has any contracts that indicate he is
   * BUYING services from other users.
   */
  public async getBuyerContracts(uid: string): Promise<IContract[]> {
    return await this.contractsDB.getBuyContracts(uid)
      .then((contracts: IContract[]) => contracts);
  }

  /**
   * Check if the user with the provided ID
   * has any contracts that indicate he is
   * SELLING services from other users.
   */
  public getSellerContracts(uid: string): Promise<IContract[]> {
    return this.contractsDB.getSellContracts(uid);
  }

  /**
   * Get the type of contract using the provided
   * contract object. We need to figure out if
   * the contract is a buyer or a seller contract
   * so we can figure out if it represents an income
   * or an expense.
   */
  public getContractType(contract: IContract, currentUserId: string): 'buyer' | 'seller' {
    return contract.buyerId === currentUserId ? 'buyer' : 'seller';
  }
}