import { Box, Checkbox, Flex, FormControl, Icon, Input, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
import CustomButton from 'components/CustomButton/CustomButton';
import ConfirmationModal from 'components/modals/ConfirmationModal';
import { ToastTypeEnum } from 'components/Toast/Toast';
import { useState, useRef, useEffect } from 'react';
import { MdContentPaste, MdOutlineDeleteSweep } from 'react-icons/md';
import { IEntry, IEntryTodo } from 'state/Tasks/TasksDTO';
import { IModalState, useAppContext } from 'state/useAppState';
import TimeSpent from '../Other/TimeSpent';

interface Props {
  ticket: IEntry;
  todo: IEntryTodo;
  handleTodoSave: any;
  handleTodoDurationChange: any;
  handleTodoEdit: any;
  handleTodoCheck: any;
  handleTodoCancel: any;
  handleTodoDelete: any;
}

const TodoItem: React.FC<Props> = ({
  ticket,
  todo,
  handleTodoSave,
  handleTodoDurationChange,
  handleTodoEdit,
  handleTodoCheck,
  handleTodoCancel,
  handleTodoDelete,
}) => {
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  // Holds the description of the current todo.
  const [description, setDescription] = useState<string>('');
  // Holds the checked mode of the current todo.
  const [checked, setChecked] = useState<boolean>(false);
  // Indicates if the current todo is in edit mode or not.
  const [editMode, setEditMode] = useState<boolean>(false);
  // Used for focusing inside the todo field if in edit mode.
  const inputRef = useRef<any>(null);
  // Used to set the autohiding toast when required.
  const { setToast, setModalState } = useAppContext();

  useEffect(() => {
    setEditMode(todo.id.includes('_editing'));
    setChecked(todo.completed);
    setDescription(todo.description);
  }, [ticket, todo]);
  /**
   * Focus into the input field of the current
   * todo if the editMode is true.
   */
  useEffect(() => {
    if (editMode && inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  }, [editMode]);
  /**
   * Handle the deletion of the current
   * task.
   */
  const handleDelete = (todo: IEntryTodo) => {
    // Open the confirmation dialog.
    const modal: IModalState = {
      title: 'Delete confirmation',
      content: <ConfirmationModal />,
      props: { name: todo.description, id: todo.id },
      actions: [
        { label: 'Cancel', callback: setModalState, props: [null], color: 'slate-400' },
        {
          label: 'Confirm', callback: (taskId: string) => {
            handleTodoDelete(todo.id);
            setModalState(null)
          }, props: [todo.id], color: 'text-red-500'
        }
      ]
    }
    setModalState(modal);
  }
  /**
   * Copies the label of the currently selected
   * task to clipboard.
   */
  const handleCopyToClipboard = (description: string) => {
    navigator.clipboard.writeText(description);
    setToast({ type: ToastTypeEnum.SUCCESS, content: 'Task label was copied to clipboard' })
  }
  /**
   * Handle the ENTER key press.
   */
  const handleKeyPress = (event: any) => {
    if ((event.charCode == 13 || event.code === 'Enter') && !event.shiftKey) {
      const newTodo = { ...todo, description };
      handleTodoSave(newTodo);
    }
    if ((event.keyCode == 27 || event.code === 'Escape') && !event.shiftKey) {
      handleTodoCancel();
    }
  }

  return (
    <Box px='11px' w='100%'>
      <Flex
        mb='20px'
        w='100%'
        direction={{ base: 'column', md: 'column', lg: 'row', xl: 'row' }}
        justifyContent={{ base: 'center', md: 'center', lg: 'flex-start', xl: 'flex-start' }}
        alignItems={{ base: 'flex-end', md: 'flex-end', lg: 'center', xl: 'center' }}
        gap='1'
      >

        <Flex w='100%'>
          <Checkbox
            me='16px'
            colorScheme='brandScheme'
            isChecked={todo.completed}
            onChange={(e: any) => handleTodoCheck(todo.id)}
          />

          {/* The description. */}
          {!editMode &&
            <Text
              fontWeight='bold'
              color={textColor}
              fontSize='md'
              textAlign='start'
              cursor='auto'
              onClick={() => handleTodoEdit(todo.id)}
            >
              {todo.description}
            </Text>
          }

          {editMode &&
              <FormControl m='0'>
                <Input
                  ref={inputRef}
                  isRequired={true}
                  p='0'
                  fontSize='md'
                  placeholder='Write the todo description here...'
                  fontWeight='bold'
                  onChange={(e: any) => setDescription(e.target.value)}
                  onKeyDown={(event: any) => handleKeyPress(event)}
                  onKeyPress={(event: any) => handleKeyPress(event)}
                  value={description}
                />
              </FormControl>
          }
        </Flex>
        
        <Flex
          direction='row'
          justifyContent={{ base: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
          alignItems={{ base: 'flex-end', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' }}
          gap='1'
        >
          
          {/* The button to control the todo time spent. */}
          <TimeSpent duration={todo.timeSpent} handleDurationChange={handleTodoDurationChange} />

          {/* The button to copy to clipboard */}
          <CustomButton
            icon={MdContentPaste}
            callback={() => handleCopyToClipboard(todo.description)}
          />

          {/* The button to delete the todo.  */}
          <CustomButton
            icon={MdOutlineDeleteSweep}
            callback={() => handleTodoDelete(todo.id)}
            color='red.500'
          />
        </Flex>

      </Flex>
    </Box>
  )
}

export default TodoItem