import { IEntryTodo } from "../state/Tasks/TasksDTO";

export class TodoHelper {

  /**
   * Get a new list of todos based on the provided
   * existing todo list and a new todo object.
   *
   * @param todos IEntryTodo[]
   * @param newTodo IEntryTodo
   * @returns IEntryTodo[]
   */
  public updateList(todos: IEntryTodo[], newTodo: IEntryTodo): IEntryTodo[] {
    /**
     * Check if the provided newTodo object is a new one or not
     * and based on that decide if we need to update the list
     * or add it to the list of existing todos.
     */
    switch (this.isNew(newTodo)) {
      // In this case we passed in a NEW todo.
      case true:
        return this.add(todos, newTodo);
      // In this case we passed in an EXISTING todo.
      case false:
        return this.update(todos, newTodo);
    }
  }

  /**
   * Delete the todo with the provided id
   * from the list of provided todos.
   *
   * @param todos IEntryTodo[]
   * @param todoId string
   * @returns IEntryTodo[]
   */
  public delete(todos: IEntryTodo[], todoId: string): IEntryTodo[] {
    return todos.filter((todo: IEntryTodo) => todo.id !== todoId);
  }

  /**
   * Toggle completed state for the todo with
   * the provided id from the list of provided
   * todos.
   *
   * @param todos IEntryTodo[]
   * @param todoId string
   * @returns IEntryTodo[]
   */
  public check(todos: IEntryTodo[], todoId: string): IEntryTodo[] {
    return todos.map((todo: IEntryTodo) => todo.id === todoId ? { ...todo, completed: !todo.completed } : todo);
  }

  /**
   * Add the edit marker to the todo with the provided id
   * inside the provided list of todos.
   *
   * @param todos IEntryTodo[]
   * @param todoId string
   * @returns IEntryTodo[]
   */
  public addEditMarker(todos: IEntryTodo[], todoId: string): IEntryTodo[] {
    return todos.map((todo: IEntryTodo) => todo.id === todoId ? { ...todo, id: `${todo.id}_editing` } : todo)
  }

  /**
   * Remove the edit marker from all the
   * todos inside the provided list.
   *
   * @param todos IEntryTodo[]
   * @returns void
   */
  public removeEditMarker(todos: IEntryTodo[]): IEntryTodo[] {
    return todos.map((todo: IEntryTodo) => ({ ...todo, id: todo.id.replace('_editing', '') }))
  }

  /**
   * Add the provided todo as a new todo
   * to the existing provided list of todos.
   *
   * @param todos IEntryTodo[]
   * @param newTodo IEntryTodo
   * @returns IEntryTodo[]
   */
  private add(todos: IEntryTodo[], newTodo: IEntryTodo): IEntryTodo[] {
    // Generate a new id for the provided newTodo object.
    const id = Math.random().toString(36).substring(2, 20);
    return this.update(todos, newTodo, id);
  }

  /**
   * Update the provided list of todos with
   * the updated todo that was provided.
   *
   * @param todos IEntryTodo[]
   * @param updatedTodo IEntryTodo
   * @returns IEntryTodo[]
   */
  private update(todos: IEntryTodo[], updatedTodo: IEntryTodo, id?: string): IEntryTodo[] {
    /**
     * If an id was provided then we need to add
     * that id to the new todo and update the list.
     *
     * Otherwise we will simply replace the existing
     * todo.
     */
    if (!id) {
      return todos.map((todo: IEntryTodo) => todo.id === updatedTodo.id ? updatedTodo : todo);
    }
    return todos.map((todo: IEntryTodo) => todo.id === '' ? { ...updatedTodo, id } : todo);
  }

  /**
   * Indicates if the provided todo is a new
   * one based on the current logic.
   *
   * @param todo IEntryTodo
   * @returns boolean
   */
  private isNew(todo: IEntryTodo): boolean {
    return !!!todo.id;
  }
}