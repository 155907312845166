import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { Navigate, useLocation } from "react-router-dom";
import { auth } from "../state/firebase";

const IsLoggedOut = (props: { children: any }) => {
  // Check what the current route is.
  const location = useLocation();
  // Use the auth state hook.
  const [user, loading] = useAuthState(auth);
  const loginRoute = '/login';
  const dashboardRoute = '/dashboard';
  const cannotRedirectToLogin = !user && location.pathname !== loginRoute;
  /**
   * Check if the user is logged in and if
   * not then redirect him to the login page.
   */
  useEffect(() => {
    if (loading) {
      return;
    }
  }, [user, loading]);

  if (user) {
    return <Navigate to={dashboardRoute} replace />
  };
  if (cannotRedirectToLogin) return <></>;
  
  return props.children;
}

export default IsLoggedOut