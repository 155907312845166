import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react"
import { HSeparator } from "components/separator/Separator";

export const InvoiceContentFooter = (props: {
  total: number;
  paid: number;
  note: string;
}) => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex mt='70px' direction={{ base: 'column', md: 'row' }}>
      {!!props.note &&
        <Box me='auto' mb={{ base: '40px', md: '0px' }}>
          <Text fontSize='lg' fontWeight='700' color={textColor}>
            Note
          </Text>
          <Text fontSize='md' fontWeight='400' color='secondaryGray.600' maxW='292px'>
            {props.note}
          </Text>
        </Box>
      }
      <Box>
        {/* <Flex align='center' justifyContent='space-between' mb='12px'>
          <Text textAlign='end' color={textColor} fontSize='lg' fontWeight='400'>
            Total
          </Text>
          <Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
            {props.total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </Text>
        </Flex>
        <Flex align='center' justifyContent='space-between'>
          <Text me='70px' fontWeight='400' textAlign='end' color={textColor} fontSize='lg'>
            Paid to date
          </Text>
          <Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
            {props.paid.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </Text>
        </Flex>
        <HSeparator my='20px' /> */}
        <Flex align='center' justifyContent='space-between'>
          <Text me='70px' fontWeight='400' textAlign='end' color={textColor} fontSize='lg'>
            {/* Amount to pay */}
            Total
          </Text>
          <Text color={textColor} fontSize='lg' fontWeight='700' maxW='292px'>
            ${}
            {(props.total - props.paid).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </Text>
        </Flex>
      </Box>
    </Flex>
  )
}