import { Avatar, Box, Flex, FormLabel, Grid, GridItem, Icon, Select, SimpleGrid, useColorModeValue } from '@chakra-ui/react';

import TasksDeleteWizard from 'components/modals/TasksDeleteWizard';
import TasksMoveWizard from 'components/modals/TasksMoveWizard';
import React, { ReactNode } from 'react';
import { IEntry } from '../../state/Tasks/TasksDTO';
import { IModalState, useAppContext } from '../../state/useAppState';
import TicketGroupButtons from './ticket-group-buttons';

interface Props {
  children: ReactNode;
  listOfTasks: IEntry[];
  title: string;
  setShouldAddTask: any;
}

const TicketGroup: React.FC<Props> = ({ children, listOfTasks, title, setShouldAddTask }) => {
  const { setModalState } = useAppContext();
  /**
   * Initiate the tasks moving procedure.
   */
  const handleOpenTasksMoveWizard = () => {
    // Open the confirmation dialog.
    const modal: IModalState = {
      title: 'Move tasks to current working day',
      content: <TasksMoveWizard />,
      props: listOfTasks,
    }
    setModalState(modal);
  }
  /**
   * Initiate the tasks delete procedure.
   */
  const handleDeleteTasksWizard = () => {
    // Open the confirmation dialog.
    setModalState({
      title: 'Delete tasks',
      content: <TasksDeleteWizard />,
      props: listOfTasks,
    });
  }

  return (
    <Box flexDirection='column'>

      <Flex
        direction="row"
        justifyContent="flex-end"
        alignItems='center'
      >

        {/* The group of buttons. */}
        <TicketGroupButtons
          handleOpenTasksMoveWizard={handleOpenTasksMoveWizard}
          handleDeleteTasksWizard={handleDeleteTasksWizard}
          setShouldAddTask={setShouldAddTask}
        />

      </Flex>


      {/* The passed in list of tasks. */}
      {children}

    </Box>

  );
}

export default TicketGroup;