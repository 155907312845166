import { Box, Flex, Icon, Progress, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from '@chakra-ui/react';

interface Props {
  colSpan: number;
  name: string;
}


export const ComplexTableHeader: React.FC<Props> = ({ colSpan, name }) => {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');

  return (
    <Th
      colSpan={colSpan}
      pe='10px'
      borderColor={borderColor}
      cursor='pointer'>
      <Flex
        justifyContent='space-between'
        align='center'
        fontSize={{ sm: '10px', lg: '12px' }}
        color='gray.400'
      >{ name }</Flex>
    </Th>
  )
}