import { addDoc, deleteDoc, DocumentData, getDoc, getDocs, onSnapshot, QuerySnapshot, Unsubscribe, updateDoc } from "firebase/firestore";
import { IProject } from "./Dto";
import { ProjectsQueries } from "./ProjectsQueries";

export class ProjectsDb extends ProjectsQueries {

  constructor() {
    super();
  }

  /**
   * It gets a document from the database, and then returns a project object
   * @param {string} id - string - the id of the project you want to get
   * @returns A promise of an IProject
   */
  public async getByIdInDb(id: string): Promise<IProject> {
    const docSnapshot = await getDoc(this.getDocById(id));
    const docData = docSnapshot.data();
    return this.mapProject(id, docData);
  }

  /**
   * It takes a userId, creates a query, and then returns the results of that query
   * @param {string} userId - string - The user id of the user you want to get the projects for.
   * @returns An array of IProjects
   */
  public async getByUserIdInDb(userId: string): Promise<IProject[]> {
    const query = this.getQueryProjectsByUserId(userId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((project: any) => this.mapProject(project.id, project.data()))
    );
  }

  /**
   * It takes a contractId as a parameter, and returns a list of projects that are associated with that
   * contractId
   * @param {string} contractId - string - the contract id
   * @returns An array of IProject objects
   */
  public async getByContractIdInDb(contractId: string): Promise<IProject[]> {
    const query = this.getQueryProjectsByContractId(contractId);
    return await getDocs(query).then((response: any) =>
      response.docs.map((project: any) => this.mapProject(project.id, project.data()))
    );
  }

  /**
   * It takes a contractId and a project object and adds the project to the database
   * @param {string} contractId - string - The id of the contract that the invoice is for
   * @param {IProject} project - IProject - this is the interface for the project object.
   * @returns A promise that resolves to a boolean.
   */
  public async addInDb(contractId: string, project: IProject): Promise<any> {
    return await addDoc(this.getCollection(), { ...project, contractId })
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  /**
   * It updates a project for a contract.
   * @param {string} id - the id of the contract
   * @param {IProject} project - IProject - this is the interface for the project object.
   * @returns The updated document.
   */
  public async updateInDb(id: string, project: IProject): Promise<any> {
    return await updateDoc(this.getDocById(id), { ...project });
  }

  /**
   * It deletes a project from the database
   * @param {string} id - the id of the project you want to delete
   * @returns A promise that resolves to the result of the delete operation.
   */
  public async deleteInDb(id: string): Promise<any> {
    return await deleteDoc(this.getDocById(id));
  }

  /**
   * It takes an id and a document data object and returns an IProject object
   * @param {string} id - string - The id of the document
   * @param {any} docData - any - this is the data that is returned from the database.
   * @returns An object with the id and the docData
   */
  private mapProject(id: string, docData: any): IProject {
    return ({
      id,
      ...docData
    })
  }
}