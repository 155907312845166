import { LocalStorageService } from "./localStorage.service";

/**
 * Manage user based preferences using the
 * localStorage of the browser.
 */
class PreferenceService extends LocalStorageService {

  /**
   * Save the preference indicated by the
   * provided key for the currently provided
   * user email.
   * 
   * @param email string
   * @param key string
   * @param data any
   * @returns void
   */
  public savePreference(uid: string, key: string, data: any): void {
    // Generate a unique key based on the current users email.
    const uniqueKey = `${uid}${key}`;
    this.save(uniqueKey, JSON.stringify(data));
  }

  /**
   * Get the preference indicated by the
   * provided key for the currently provided
   * user email.
   * 
   * @param email string
   * @param key string
   * @returns any
   */
  public getPreference(uid: string, key: string): any {
    const uniqueKey = `${uid}${key}`;
    const savedString = this.get(uniqueKey);
    if (savedString === undefined) {
      return null;
    } else {
      return JSON.parse(savedString);
    }
  }

  /**
   * Remove a preference indicated by the
   * provided key for the currently provided
   * user email.
   * 
   * @param email string
   * @param key string
   * @returns void
   */
  public removePreference(uid: string, key: string): void {
    const uniqueKey = `${uid}${key}`;
    this.remove(uniqueKey);
  }
}

export const preferenceService = new PreferenceService();