import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  where,
  deleteDoc,
  Query,
  getDoc,
  DocumentReference,
} from 'firebase/firestore';
import { db } from '../firebase';

export class UsersQueries {

  /**
   * Returns the notes collection reference
   * so we can use it outside of this service.
   *
   * @returns CollectionReference<DocumentData>
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'users');
  }

  public getDocByUserId(id: string): DocumentReference<DocumentData> {
    return doc(db, 'users', id);
  }
}