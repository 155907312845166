import { Flex, useColorModeValue } from "@chakra-ui/react";
import { TimeHelper } from "logic/Time.helper";
import { IEntry } from "state/Tasks/TasksDTO";
import TimeSpent, { ShowDuration } from "./TimeSpent";
import CustomButton from "components/CustomButton/CustomButton";
import { MdBookmarkAdd, MdContentPaste, MdOutlineAvTimer, MdOutlineBookmark, MdOutlineDeleteOutline } from "react-icons/md";

interface Props {
  entry: IEntry;
  handleDurationChange: any;
  handleEditTags: any;
  handleCopyToClipboard: any;
  handleDelete: any;
}

const TicketRightInfo: React.FC<Props> = ({ entry, handleDurationChange, handleEditTags, handleCopyToClipboard, handleDelete }) => {
  // The time helper class.
  const timeHelper = new TimeHelper();
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  return (
    <Flex direction="row" justifyContent='flex-end' alignItems='center' w='100%'>

      {/* The ticket action buttons. */}
      <Flex direction='row' justifyContent='flex-end' alignItems='center' w='100%'>

        {/* Check if the task has todos in which case show the cumulated timeSpent of all todos. */}
        {entry.todos && !!entry.todos.length &&
          <ShowDuration duration={entry.timeSpent} />
        }

        {/* If the task has no todos then show the timeSpent from the task object. */}
        {/* Display the timeSpent on the current task. */}
        {!entry.todos &&
          <TimeSpent duration={entry.timeSpent} handleDurationChange={handleDurationChange} />
        }

        {/* Copy task description to clipboard. */}
        <CustomButton
          callback={() => handleCopyToClipboard()}
          icon={MdContentPaste}
          color='brand.500'
          ml='10px'
        />

        {/* Delete the ticket. */}
        <CustomButton
          callback={() => handleDelete()}
          icon={MdOutlineDeleteOutline}
          color='red.500'
          ml='10px'
        />

        {/* Add / remove tags. */}
        <CustomButton
          callback={() => handleEditTags()}
          icon={MdBookmarkAdd}
          color={!!entry.tags?.length ? 'brand.500' : 'brand.50'}
          ml='10px'
        />

      </Flex>


    </Flex>
  )
}

export default TicketRightInfo;