import moment from 'moment';
import { IEntry, IEntryTodo } from "../state/Tasks/TasksDTO";
import { IContract } from "../state/Contracts/ContractsService";
import TasksFilters from './Tasks.filters';
import { startOfMonth } from 'date-fns';

/**
 * Helps with various task related
 * operations that don't belong inside
 * a component.
 */
export class TasksHelper extends TasksFilters {

  constructor() {
    super()
  }

  /**
   * Get the number of completed days
   * for the entire month symbolised
   * by the provided date.
   * 
   * @param date Date
   * @returns number
   */
  public getNumberOfCompletedDaysByContract(tasks: IEntry[], date: Date, contract: IContract): { completedDays: number, totalMinutes: number } {
    // Get the list of tasks for the current date.
    const currentMonthTasks = tasks.filter(t => !!moment(t.created).isSame(date, 'month'));
    // Split tasks by day.
    const daysInMonth = moment(date).daysInMonth();
    // Create an array with the number of days in the month.
    const daysArray = [];
    for (let i = 1; i <= daysInMonth; i++) {
      daysArray.push(i);
    }
    /**
     * Parse through the list of daysInMonth and 
     * create a list with the tasks for each day.
     */
    const yearAndMonthString = `${moment(date).format('YYYY')}-${moment(date).format('MM')}-`;
    const taskGroupsByDay = daysArray.map((currentDay: number) => currentMonthTasks.filter(t => moment(t.created).isSame(moment(`${yearAndMonthString}${currentDay}`), 'day')));
    // Count the number of days that have at least this.requiredMinutesForComplete minutes.
    const completedDays = taskGroupsByDay.filter(tg => {
      const totalMinutesCurrentDay = tg.reduce((acc: number, task: IEntry) =>
        acc + (task.timeSpent || 0), 0);
      return (totalMinutesCurrentDay / 60) >= contract.hoursPerDay;
    });
    const totalMinutes = currentMonthTasks.reduce((acc: number, task: IEntry) => acc + (task.timeSpent || 0), 0);
    return { completedDays: completedDays.length, totalMinutes };
  }

  /**
   * Get the number of minutes in timeSpent for
   * the provided list of tasks.
   *
   * @param tasks IEntry[]
   * @returns number
   */
  public getTotalMinutesSpent(tasks: IEntry[], applyReduction?: number): number {
    const timeSpent = tasks.reduce((acc, task: IEntry) => acc + (task.timeSpent || 0), 0);
    return applyReduction ? (timeSpent * applyReduction) / 100 : timeSpent;
  }

  /**
   * Get the number of timeSpent during the current
   * month for the provided quantifier id.
   * 
   * @param allTasks IEntry[]
   * @param quantifierId string
   * @returns number
   */
  public filterTimeSpentForContractForPeriod(allTasks: IEntry[], date: Date, contractId: string, period: 'day' | 'month' = 'month'): number {
    let listOfTasks = null;
    switch (period) {
      case 'day':
        listOfTasks = this.filterTasksForProvidedDayByContract(allTasks, date, contractId);
        break;
      case 'month':
        listOfTasks = this.filterTasksForProvidedMonthByContract(allTasks, date, contractId);
        break;
      default:
        listOfTasks = this.filterTasksForProvidedMonthByContract(allTasks, date, contractId);
    }
    // console.log('filterTimeSpentForContractForPeriod:', listOfTasks);
    return this.getTotalMinutesSpent(listOfTasks);
  }

  /**
   * Check if the provided list of tasks have
   * a total of at least 480 minutes of timeSpent.
   *
   * @param tasks IEntry[]
   * @returns number
   */
  public isDayComplete(tasks: IEntry[], contract: IContract): boolean {
    return tasks.reduce((acc, task: IEntry) => acc + (task.timeSpent || 0), 0) >= contract.hoursPerDay;
  }

  /**
   * Get a new empty task object that we
   * use when we want to create a new task.
   * 
   * @param currentDate timestamp
   * @returns IEntry
   */
  public getNewTask(currentDate: number, quantifierId: string, userId: string): IEntry {
    return {
      id: '',
      userId,
      label: '',
      completed: false,
      created: currentDate,
      quantifierId
    }
  }

  /**
   * Get the total minutes spent on the list of provided tasks
   * belonging to the provided contracts for the provided date
   * and provided period.
   *
   * @param contractIds string[]
   * @param allTasks IEntry[]
   * @param date Date
   * @param period 'day' | 'month'
   * @returns number
   */
  public getTimespentForContractsForPeriod(contractIds: string[], allTasks: IEntry[], date: Date, period: 'day' | 'month' = 'month'): number {
    // Get the list of all tasks belonging to the list of contracts provided.
    const tasksForContracts = this.filterTasksByContracts(allTasks, contractIds);
    switch (period) {
      case 'month':
        const tasksForMonth = this.filterTasksForProvidedMonth(tasksForContracts, date);
        return this.getTotalMinutesSpent(tasksForMonth);
      case 'day':
        const tasksForDay = this.filterTasksForProvidedDay(tasksForContracts, new Date());
        return this.getTotalMinutesSpent(tasksForDay);
    }
  }

  /**
   * Get a list of dates to which the provided list
   * of tasks belongs to.
   *
   * @param tasks IEntry[]
   * @returns Date[]
   */
  public getListOfDatesFromTasks(tasks: IEntry[]): Date[] {
    // Get the list of months (as first day of month) for all tasks.
    const timestamps = tasks.map((task: IEntry) => startOfMonth(new Date(task.created)).getTime());
    // Get only the unique dates.
    const uniqueMonthsSet = new Set(timestamps);
    return Array.from(uniqueMonthsSet).map((m: number) => new Date(m));
  }

  /**
   * "Get the total time spent on a contract invoice for a given month."
   * 
   * The function is broken down into two parts:
   * 
   * 1. Filter the tasks for the current month.
   * 2. Get the total time spent on this contract invoice
   * @param {IEntry[]} tasks - IEntry[] - This is the array of tasks that you want to filter.
   */
  public getTimeSpentFromTasksForPeriod(tasks: IEntry[], date: Date, applyReduction?: number): number {
    // Filter the tasks for the current month.
    const monthTasks = this.filterTasksForProvidedMonth(tasks, date);
    // Get the total time spent on this contract invoice.
    return this.getTotalMinutesSpent(monthTasks, applyReduction);
  }

  public cumulateTodoMinutesForTasks(tasks: IEntry[]): IEntry[] {
    return tasks.map((task: IEntry) => ({
      ...task,
      // Compute the time spent according to the todos or the task (if there are no todos yet).
      timeSpent: task.todos &&
        task.todos.length > 0 &&
        !!task.todos.find((todo: IEntryTodo) => !!todo.timeSpent) ?
          task.todos.reduce((acc: number, todo: IEntryTodo) => acc + (todo.timeSpent || 0), 0) : (task.timeSpent || 0),
      id: task.id
    }));
  }
}