// Chakra imports
import { Box, Flex, SimpleGrid } from '@chakra-ui/react';
// Custom components
import { useAppContext } from 'state/useAppState';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Participants from './components/Participants/Participants';
// import profile from 'assets/img/crm/vbz.png';

export default function ProjectView() {
  // Get the id of the selected project.
  // const { projectId } = useParams();

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      <SimpleGrid mb='20px' columns={{ sm: 1, md: 2 }} spacing={{ base: '20px', xl: '20px' }}>
        {/* Column Left */}
        <Flex direction='column'>
          {/* <Profile name='Vlad Mihalache' avatar={logoWhite} banner={banner} />
          <PersonalInfoSettings /> */}
        </Flex>
        {/* Column Right */}
        <Flex direction='column'>
        </Flex>
      </SimpleGrid>

      <SimpleGrid mb='20px' columns={{ sm: 1, md: 1, lg: 1 }} spacing={{ base: '20px', xl: '20px' }}>
          
          <Participants />

      </SimpleGrid>
    </Box>
  );
}
