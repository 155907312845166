import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  getDocs,
  addDoc,
  doc,
  updateDoc,
  orderBy,
  where,
  deleteDoc,
  Query,
  writeBatch,
  WriteBatch,
} from 'firebase/firestore';
import { db } from '../firebase';

export class TasksQueries {

  /**
   * Returns the notes collection reference
   * so we can use it outside of this service.
   *
   * @returns CollectionReference<DocumentData>
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'notes');
  }

  /**
   * Get the notes query by created at.
   * 
   * @returns Query<DocumentData>
   */
  public getNotesQueryByDate(userId: string): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    return query(
      notesCollectionRef,
      // !!! IMPORTANT !!! do not remove this where clause.
      where("userId", "==", userId),
      orderBy("created", "asc")
    );
  }

  /**
   * Get the notes query by current user and contract.
   * 
   * @param userId string
   * @param contractId string
   * @returns 
   */
  public getNotesQueryContractAndUser(contractId: string, userId: string): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    return query(
      notesCollectionRef,
      where("userId", "==", userId),
      where("quantifierId", "==", contractId),
      orderBy("created", "asc")
    );
  }

  /**
   * Get the notes query by created at.
   * 
   * @returns Query<DocumentData>
   */
  public getNotesQueryByContract(contractId: string): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    return query(
      notesCollectionRef,
      where("quantifierId", "==", contractId),
      orderBy("created", "asc")
    );
  }

  /**
   * Get the notes query by created at.
   * 
   * @returns Query<DocumentData>
   */
  public getNotesQueryByContractAndUser(contractId: string, userId: string): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    return query(
      notesCollectionRef,
      where("quantifierId", "==", contractId),
      where("userId", "==", userId),
      orderBy("created", "asc")
    );
  }

  public getNotesQueryByContractForPeriod(contractId: string, start: number, end: number): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    const queryContraints = [
      where("quantifierId", "==", contractId),
      where("created", ">=", start),
      where("created", "<", end)
    ];
    return query(
      notesCollectionRef,
      ...queryContraints,
      orderBy('created', 'desc')
    );
  }

  public getNotesQueryByContractsForPeriod(contractIds: string[], start: number, end: number): Query<DocumentData> {
    const notesCollectionRef = collection(db, 'notes');
    const queryContraints = [
      where("quantifierId", "in", contractIds),
      where("created", ">=", start),
      where("created", "<", end)
    ];
    return query(
      notesCollectionRef,
      ...queryContraints,
      orderBy('created', 'desc')
    );
  }

}