// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
import MiniCalendar from 'components/calendar/MiniCalendar';
import SelectedContractChange from 'components/DashboardContracts/SelectedContractChange/SelectedContractChange';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import { SidebarLinks } from 'components/sidebar/components/SidebarLinks/SidebarLinks';
import { ILink } from 'routes';

// FUNCTIONS

function SidebarContent(props: { links: ILink[] }) {
	const { links } = props;
	// SIDEBAR
	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Brand />

      {/* Show the selector of contract view. */}
      <SelectedContractChange />
      
			<Stack direction='column' mt='8px' mb='auto'>
				<Box ps='20px' pe={{ lg: '16px', '2xl': '16px' }}>
					<SidebarLinks links={links} />
				</Box>
			</Stack>

			<Box pe={{ lg: '16px', '2xl': '20px' }} mt='60px' mb='40px' borderRadius='30px'>
        <MiniCalendar h='100%' minW='100%' selectRange={false} />
				{/* <SidebarCard /> */}
			</Box>
		</Flex>
	);
}

export default SidebarContent;
