// Chakra imports
import { Box, Flex, Text, Select, useColorModeValue } from '@chakra-ui/react';
// Custom components
import Card from 'components/card/Card';
import PieChart from 'components/charts/PieChart';
import ChartHelper from 'logic/Chart.helper';
import { TasksHelper } from 'logic/Tasks.helper';
import { TimeHelper } from 'logic/Time.helper';
import { ITaskType, TypeHelper } from 'logic/type.helper';
import { useCallback, useEffect, useState } from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import { useAppContext } from 'state/useAppState';
import { useContracts } from 'state/useContractsState';

export default function PieCard(props: { tasks: IEntry[] }) {
  const { ...rest } = props;

  // Chakra Color Mode
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const { hideAmounts } = useAppContext();
  const { currentContract } = useContracts();
  const typeHelper = new TypeHelper();
  const tasksHelper = new TasksHelper();
  const chartHelper = new ChartHelper();
  const timeHelper = new TimeHelper();
  // Holds the processed types.
  const [processedTasks, setProcessedTasks] = useState<IEntry[]>([]);
  const [taskTypes, setTaskTypes] = useState<ITaskType[]>([]);
  const [data, setData] = useState<number[]>([]);
  const [options, setOptions] = useState<any>(null);
  const [totalTime, setTotalTime] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  const processedTypes = useCallback(() => typeHelper.getTimePercentForTasks(processedTasks), [processedTasks]);

  useEffect(() => {
    if (!props.tasks?.length) return;
    const theProcessedTasks = typeHelper.detectTypes(props.tasks);
    setProcessedTasks(theProcessedTasks);
    // The total time spent on the current tasks.
    const theTotalTime = tasksHelper.getTotalMinutesSpent(theProcessedTasks);
    setTotalTime(theTotalTime);
    setTotalPrice((theTotalTime / 60) * currentContract.rate);
  }, [props.tasks]);

  /**
   * Refresh the pie chart data when the
   * list of processed tasks has changed.
   */
  useEffect(() => {
    if (!processedTasks?.length) return;
    setTaskTypes([]);
    setData([]);
    setOptions(null);
    const taskTypes = processedTypes();
    setTaskTypes(taskTypes);
    const theData = chartHelper.getPieChartData(taskTypes);
    const theOptions = chartHelper.getPieChartOptions(taskTypes);
    setData([...theData]);
    setOptions({ ...theOptions });
  }, [processedTasks]);

  if (!props.tasks?.length) {
    return <></>
  }

  return (
    <Card p='20px' alignItems='flex-start' justifyContent='flex-start' flexDirection='column' w='100%' {...rest}>
      <Flex
        px={{ base: '0px', '2xl': '10px' }}
        justifyContent='space-between'
        alignItems='center'
        w='100%'
        mb='8px'
      >
        <Text color={textColor} fontSize='md' fontWeight='600' mt='4px'>
          Daily productivity chart
        </Text>
        {/* <Select fontSize='sm' variant='subtle' defaultValue='monthly' width='unset' fontWeight='700'>
          <option value='daily'>Daily</option>
          <option value='monthly'>Monthly</option>
          <option value='yearly'>Yearly</option>
        </Select> */}
      </Flex>

      {data?.length && options &&
        <PieChart
          h='70%'
          w='100%'
          chartData={[...data]}
          chartOptions={{ ...options }}
        />
      }

      {data?.length !== 0 &&
        <Flex direction='column' bg={boxBg} p='16px 20px' borderRadius='14px'>
          <Text fontSize='sm' fontWeight='700' color={textColor}>
            How you spent your time
          </Text>
          <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
            The time spent on your tasks will be drawn here so you can have an idea how you day went.
          </Text>
        </Flex>
      }

      {data?.length === 0 &&
        <Flex direction='column' bg={boxBg} p='16px 20px' borderRadius='14px' mb='38px'>
          <Text fontSize='sm' fontWeight='700' color={textColor}>
            Please add some time spent to your tasks
          </Text>
          <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>
            The time spent on your tasks will be drawn here so you can get a feeling on how effective you are.
          </Text>

        </Flex>
      }

      <Box mt='20px' w='100%'>
        <Flex w='100%' justifyContent='space-between' mb='6px'>
          <Text fontSize='md' fontWeight='400' color='secondaryGray.600' me='4px'>
            Number of tasks:
          </Text>
          <Text fontSize='md' fontWeight='700' color={textColor}>
            {processedTasks.length}
          </Text>
        </Flex>
        <Flex w='100%' justifyContent='space-between' mb='6px'>
          <Text fontSize='md' fontWeight='400' color='secondaryGray.600' me='4px'>
            Time logged:
          </Text>
          <Text fontSize='md' fontWeight='700' color={textColor}>
            {timeHelper.humanize(totalTime)}
          </Text>
        </Flex>
        <Flex w='100%' justifyContent='space-between' mb='6px'>
          <Text fontSize='md' fontWeight='400' color='secondaryGray.600' me='4px'>
            Amount invoiced:
          </Text>
          <Text fontSize='md' fontWeight='700' color={textColor}>
            {totalPrice.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
          </Text>
        </Flex>
      </Box>
    </Card>
  );
}