
import { DocumentData } from "firebase/firestore";
import { IEntry } from "state/Tasks/TasksDTO";
import { TasksStore } from "state/Tasks/TasksStore";

const tasksStore = new TasksStore();

/**
 * Script for changing the quantifierId inside the NOTES
 * collection into the specified contractId.
 * 1. Fetch all the NOTES that have the provided quantifierId
 * and userId.
 * 2. Parse through the tasks and change the current quantifierId
 * to the provided contractId.
 */

/**
 * It returns a promise that resolves to a DocumentData object.
 * @param {string} contractId - The id of the contract that the task belongs to
 * @param {string} userId - The user id of the user who created the task
 * @returns A promise of a DocumentData object.
 */
export const fetchAllTasksByContractAndUser = async (contractId: string, userId: string): Promise<IEntry[]> => {
  return await tasksStore.getAllByContractAndUser(contractId, userId);
}

// Change the quantifierId as specified by parsing through the provided notes.
export const changeQuantifierIds = (notes: IEntry[], newQuantifierId: string) => {
  const changed = notes.map((note: IEntry) => ({ ...note, quantifierId: newQuantifierId }));
  console.log('changed:', changed);
  return changed;
}

// Update the provided notes.
export const updateNotes = (notes: IEntry[], oldContractId: string, userId: string, isTest: boolean) => {
  return tasksStore.updateMultipleInDb(notes, oldContractId, userId, isTest)
}

/**
 * It takes in an old quantifierId, a new quantifierId, and a userId. It then fetches all the tasks
 * that have the old quantifierId, changes the quantifierId to the new quantifierId, and then updates
 * the tasks in the Firestore
 * @param {string} oldQuantifierId - The old quantifierId that you want to change.
 * @param {string} newQuantifierId - The new quantifierId that you want to change to.
 * @param {string} userId - The userId of the user who is changing the quantifierId.
 */
export const runQuantifierIdChange = async (oldQuantifierId: string, newQuantifierId: string, userId: string, isTest: boolean) => {
  // Get all the tasks that need to have the quantifierId changed.
  const notes = await fetchAllTasksByContractAndUser(oldQuantifierId, userId);
  // Parse through all notes and make the change.
  const updatedNotes = changeQuantifierIds(notes, newQuantifierId);
  // Update all the notes inside the Firestore.
  updateNotes(updatedNotes, oldQuantifierId, userId, isTest);
}