/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex, Skeleton, Stack } from '@chakra-ui/react';

// Custom components
import Card from 'components/card/Card';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IContract } from 'state/Contracts/ContractsService';
import { IEntry } from 'state/Tasks/TasksDTO';
import { TasksStore } from 'state/Tasks/TasksStore';
import { useAppContext } from 'state/useAppState';
import { useAuth } from 'state/useAuthState';
import { useContracts } from 'state/useContractsState';
import ContractInvoicesList from './components/ContractInvoicesList/ContractInvoicesList';

export default function ContractInvoicesView() {
  // Get the contract id from the route params.
  let { contractId } = useParams();
  const [contract, setContract] = useState<IContract>(null);
  const [tasks, setTasks] = useState<IEntry[]>([]);
  const {allContracts } = useContracts();
  const {allTasks} = useAppContext();

  useEffect(() => {
    /**
     * Get the contract object and the list
     * of tasks required.
     */
    const foundContract = allContracts.find((c: IContract) => c.id === contractId);
    setContract(foundContract);
    setTasks(allTasks);
  }, [allContracts, allTasks]);

  return (
    <Card mt={{ base: '130px', md: '80px', xl: '80px' }} mx='auto'>
      <Flex direction='column'>

        {!contract &&
          <Stack>
            <Skeleton height='20px' />
            <Skeleton height='20px' />
            <Skeleton height='20px' />
          </Stack>
        }

        {!!contract && !!tasks.length &&
          <ContractInvoicesList contract={contract} tasks={tasks} />
        }

      </Flex>
    </Card>
  );
}
