import { ITicket } from "./Dto";
import { TicketsDb } from "./TicketsDb";
import { TicketsLabelDb } from "./TicketsLabelDb";
import { TicketsStateDb } from "./TicketsStateDb";

class TicketsService extends TicketsDb {
  public states = new TicketsStateDb();
  public labels = new TicketsLabelDb();

  constructor() {
    super();
  }

  public async getById(id: string): Promise<ITicket> {
    return await this.getByIdInDb(id);
  }

  /**
   * Get the list of tickets that are marked
   * as CHILDREN of this ticket.
   * 
   * @param id number
   * @returns Promise<ITicket[]>
   */
  public async getAllChildrenById(id: string): Promise<ITicket[]> {
    return await this.getAllByParentIdInDb(id);
  }

  /**
   * Get the list of tasks that are are marked as
   * being in the status with the provided id.
   *
   * @param stateId string
   * @returns Promise<ITicket[]>
   */
  public async getAllByStateId(stateId: string): Promise<ITicket[]> {
    return await this.getAllByStateIdInDb(stateId);
  }

  /**
   * Get the list of all tickets that are
   * being blocked by the provided id.
   * 
   * @param id number
   * @returns Promise<ITicket[]>
   */
  public async getAllBlockedById(ids: string[]): Promise<ITicket[]> {
    return await this.getAllBlockedByIdsInDb(ids);
  }

  public async add(contractId: string, project: ITicket): Promise<any> {
    return await this.addInDb(contractId, project);
  }

  public async update(id: string, project: ITicket): Promise<any> {
    return await this.updateInDb(id, project);
  }

  public async delete(id: string): Promise<any> {
    return await this.deleteInDb(id);
  }

}

export const ticketsService = new TicketsService();