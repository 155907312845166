import {
  collection,
  query,
  CollectionReference,
  DocumentData,
  doc,
  where,
  Query,
  DocumentReference,
  addDoc,
  deleteDoc,
  getDoc,
  getDocs,
  updateDoc
} from 'firebase/firestore';
import { db } from '../firebase';

export interface ITicketState {
  id?: string;
  name: string;
}

export class TicketsStateDb {

  /**
   * The usable queries.
   */
  public getCollection(): CollectionReference<DocumentData> {
    return collection(db, 'ticketStates');
  }

  public getQueryDocById(id: string): DocumentReference<DocumentData> {
    return doc(db, 'ticketStates', id);
  }

  public getQueryAll(): Query<DocumentData> {
    return query(
      this.getCollection(),
    );
  }

  public getQueryAllByIds(ids: string[]): Query<DocumentData> {
    return query(
      this.getCollection(),
      where("id", "in", ids)
    );
  }

  /**
   * The usable DB operations.
   */

  public async getByIdInDb(id: string): Promise<ITicketState> {
    const docSnapshot = await getDoc(this.getQueryDocById(id));
    const docData = docSnapshot.data();
    return this.mapState(id, docData);
  }

  public async getAllInDb(): Promise<ITicketState[]> {
    const query = this.getQueryAll();
    return await getDocs(query).then((response: any) =>
      response.docs.map((state: any) => this.mapState(state.id, state.data()))
    );
  }

  public async getAllByIdsInDb(ids: string[]): Promise<ITicketState[]> {
    const query = this.getQueryAllByIds(ids);
    return await getDocs(query).then((response: any) =>
      response.docs.map((state: any) => this.mapState(state.id, state.data()))
    );
  }

  /**
   * Basic CRUD operations in DB.
   */

  public async addInDb(state: ITicketState): Promise<any> {
    return await addDoc(this.getCollection(), { ...state })
      .then((response: any) => !!response)
      .catch((error: any) => error);
  }

  public async updateInDb(id: string, state: ITicketState): Promise<any> {
    return await updateDoc(this.getQueryDocById(id), { ...state });
  }

  public async deleteInDb(id: string): Promise<any> {
    return await deleteDoc(this.getQueryDocById(id));
  }

  private mapState(id: string, docData: any): ITicketState {
    return ({
      id,
      ...docData
    })
  }
 
}