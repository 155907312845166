import { Badge, Flex, Icon, Td, Tr, Text, useColorModeValue, Stack, Skeleton } from "@chakra-ui/react";
import CustomButton from "components/CustomButton/CustomButton";
import { format } from "date-fns";
import { TimeHelper } from "logic/Time.helper";
import { BsPercent } from "react-icons/bs";
import { MdAccessTimeFilled, MdCalendarToday, MdChecklistRtl, MdOutlineSave, MdOutlineVisibility, MdPaid } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { IContractInvoice } from "state/Invoices/Dto";
import { getMonth, getYear, addMonths } from 'date-fns';
import { useEffect, useState } from "react";
import { IInvoiceStatusDefinition, invoicesService } from "state/Invoices/InvoicesService";

const ContractInvoicesListRow = (props: {
  contractId: string;
  invoice: IContractInvoice;
  allInvoices: IContractInvoice[];
  refreshListCallback: any;
}) => {
  const markPaidButtonColor = useColorModeValue('green.500', 'green.200');
  const viewButtonColor = useColorModeValue('blue.500', 'blue.200');
  const percentButtonColor = useColorModeValue('blue.500', 'blue.200');
  const savedIconColor = useColorModeValue('green.500', 'green.200');
  const unsavedIconColor = useColorModeValue('red.500', 'red.200');
  const timeHelper = new TimeHelper();
  const navigate = useNavigate();
  const [definition, setDefinition] = useState<IInvoiceStatusDefinition>(null);

  const addedMonthOnInvoice = addMonths(new Date(props.invoice.invoiceDate), 1);

  /**
   * Get the invoice definition for 
   * the current invoice.
   */
  useEffect(() => {
    if (!props.invoice) return;
    const theDefinition = invoicesService.getStatusDefinition(props.invoice.status);
    setDefinition(theDefinition);
  }, [props.invoice]);

  if (!definition) {
    return (
      <Stack width="100%">
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return (
    <Tr>

      {/* Description. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        <Flex direction='row' alignItems='center' gap={2}>
          <Icon as={MdCalendarToday} width='20px' height='20px' color='inherit' />
          <Flex direction='column'>

            {/* Month and saved status. */}
            <Flex direction='row' alignItems='center' gap={2}>
              <Text fontSize='sm' fontWeight='500' color='black'>{format(props.invoice.invoiceDate, 'MMMM yyyy')}</Text>
              <Icon as={MdOutlineSave} width='15px' height='15px' color={props.invoice.savedDate ? savedIconColor : unsavedIconColor} />
            </Flex>

            {/* The name of the beneficiary of current invoice. */}
            <Text fontSize='sm' fontWeight='500' color='secondaryGray.600'>{props.invoice.for?.displayName || 'Unknown'}</Text>
          </Flex>
        </Flex>
      </Td>

      {/* Invoiced date. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        {format(props.invoice.invoiceDate, 'dd/MM/yyyy')}
      </Td>

      {/* Status */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        <Badge colorScheme={definition.color}>{definition.label}</Badge>
      </Td>

      {/* Invoiced time. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        {timeHelper.humanize(props.invoice.timeSpent)}
      </Td>

      {/* Amount. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        {props.invoice.amount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
      </Td>
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor='transparent'
      >
        <Flex direction='row' justifyContent='center' gap='2'>
          {/*  */}
          <CustomButton
            callback={() => invoicesService.markAsPaid(props.invoice.id, props.contractId, props.allInvoices, props.refreshListCallback)}
            color={markPaidButtonColor}
            icon={MdPaid}
          />
          {/* Show list of tasks performed. */}
          <CustomButton
            callback={() => navigate(`/invoices/${props.contractId}/${getYear(addedMonthOnInvoice)}/${getMonth(addedMonthOnInvoice)}/tasks`)}
            color={viewButtonColor}
            icon={MdChecklistRtl}
          />
          {/* Show invoice. */}
          <CustomButton
            callback={() => navigate(`/invoices/${props.contractId}/${getYear(addedMonthOnInvoice)}/${getMonth(addedMonthOnInvoice)}/invoice`)}
            color={viewButtonColor}
            icon={MdOutlineVisibility}
          />
          {/* Apply reduction. */}
          <CustomButton
            callback={() => invoicesService.markAsClosed(props.invoice.id, props.contractId, props.allInvoices, props.refreshListCallback)}
            color={percentButtonColor}
            icon={MdAccessTimeFilled}
          />
        </Flex>
      </Td>
    </Tr>
  )
}

export default ContractInvoicesListRow;