import { useEffect, useState } from "react";
import { IEntry } from "../../state/Tasks/TasksDTO";
import { TasksStore } from "../../state/Tasks/TasksStore";
import { IModalStateAction, useAppContext } from "../../state/useAppState"

interface IMarkableEntry extends IEntry {
  marked: boolean;
}

const TasksDeleteWizard = () => {
  const store = new TasksStore();
  // The app wide modal state props.
  const { modalState, setModalState } = useAppContext();
  // Holds the list of entries that the user checked for moving.
  const [entries, setEntries] = useState<IMarkableEntry[]>([]);
  /**
   * Set the list of entries received
   * via the modal params.
   */
  useEffect(() => {
    setEntries(modalState.props.map((entry: IEntry) => ({ ...entry, marked: false })));
  }, []);
  /**
   * 
   */
  const deleteEntries = (): void => {
    // Select the marked entries.
    const markedEntries = entries.filter((entry: IMarkableEntry) => !!entry.marked);
    // Call the store method to update the dates.
    store.deleteMultipleNotes(markedEntries);
    // Close the modal after action completed.
    setModalState(null);
  }

  return (
    // <!-- Modal backdrop. This what you want to place close to the closing body tag -->
    <div
      className="fixed inset-0 z-30 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center"
    >
      {/* <!-- Modal --> */}
      <div
        className="w-full px-6 py-4 overflow-hidden bg-white rounded-t-lg sm:rounded-lg sm:m-4 sm:max-w-xl"
        role="dialog"
        id="modal"
      >

        {/* <!-- Remove header if you don't want a close icon. Use modal body to place modal tile. --> */}
        <header className="flex justify-end">
          <button
            className="inline-flex items-center justify-center w-6 h-6 text-gray-400 transition-colors duration-150 rounded hover: hover:text-gray-700"
            aria-label="close"
            onClick={() => setModalState(null)}
          >
            <svg
              className="w-4 h-4"
              fill="currentColor"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
            >
              <path
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </button>
        </header>

        {/* <!-- Modal body --> */}
        <div className="mt-4 mb-6">
          {/* <!-- Modal title --> */}
          <p
            className="-mt-10 mb-2 text-lg font-semibold text-black text-center"
          >
            {modalState.title}
          </p>
          {/* <!-- Modal description --> */}
          <div
            className="px-4 py-3 mb-8 bg-white rounded-lg flex flex-col items-center"
          >

            {/* The explanation text. */}
            <span className="text-md mb-10">
              Please choose the tasks you would like to <span className="text-red-500">DELETE</span>:</span>

            <ul className="w-full text-sm font-medium text-gray-900 bg-white rounded-lg border border-gray-200">
              {entries.map((entry: IMarkableEntry, index: number) =>
                <li
                  key={entry.id}
                  className={`py-2 px-4 w-full ${index === 0 ? 'rounded-t-lg' : ''}
                  ${index === (modalState.props.length - 1) ? 'rounded-b-lg' : ''}
                  border-b border-gray-200 align-middle`}
                >
                  <div className="flex items-center w-full">
                    <input
                      type="checkbox"
                      className="w-4 h-4 shrink-0 text-blue-600 bg-gray-100 rounded border-gray-300"
                      onChange={(event: any) => entry.marked = !entry.marked}
                    />
                    <label className="ml-2 text-sm font-medium text-gray-900">{entry.label}</label>
                  </div>
                </li>
              )}
            </ul>

          </div>
        </div>

        {/* The modal footer. */}
        <footer
          className="flex flex-col items-center justify-end px-6 py-3 -mx-6 -mb-4 space-y-4 sm:space-y-0 sm:space-x-6 sm:flex-row bg-gray-50"
        >

          {/* The cancel button. */}
          <button
            type="button"
            className={`w-full px-5 py-3 text-sm font-medium leading-5 transition-colors duration-150 border border-slate-500 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray`}
            // className="w-full px-5 py-3 text-sm font-medium leading-5 text-black transition-colors duration-150 border border-slate-200 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            onClick={() => setModalState(null)}
          >
            Cancel
          </button>

          {/* The move button */}
          <button
            type="button"
            className={`w-full px-5 py-3 text-sm font-medium leading-5 transition-colors duration-150 border border-slate-500 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 bg-red-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray`}
            // className="w-full px-5 py-3 text-sm font-medium leading-5 text-black transition-colors duration-150 border border-slate-200 rounded-lg sm:px-4 sm:py-2 sm:w-auto active:bg-transparent hover:border-gray-500 focus:border-gray-500 active:text-gray-500 focus:outline-none focus:shadow-outline-gray"
            onClick={() => deleteEntries()}>
            Delete marked
          </button>

        </footer>

      </div>
    </div>

  )
}

export default TasksDeleteWizard