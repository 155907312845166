// Chakra imports
import { Button, Flex, FormControl, Grid, SimpleGrid, Skeleton, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import { useState } from 'react';
import { useAuth } from 'state/useAuthState';
import { IUserProfile } from 'state/Users/UsersService';

export default function BusinessInfoSettings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const { updateUser, personalData } = useAuth();
  // Holds the field values.
  const [fields, setFields] = useState<IUserProfile>({
    uid: personalData?.uid || '',
    displayName: personalData?.displayName || '',
    firstName: personalData?.firstName || '',
    lastName: personalData?.lastName || '',
    email: personalData?.email || '',
    businessName: personalData?.businessName || '',
    businessLocation: personalData?.businessLocation || '',
    street: personalData?.street || '',
    city: personalData?.city || '',
    country: personalData?.country || '',
    zip: personalData?.zip || '',
    quantifier: null,
    userId: personalData?.userId || '',
    jobName: personalData?.jobName || '',
    bio: personalData?.bio || '',
    role: personalData?.role || '',
    companyRegistrarNr: personalData.companyRegistrarNr || '',
    vatCode: personalData.vatCode || '',
    iban: personalData.iban || '',
    bankName: personalData.bankName || '',
    bankSwift: personalData.bankSwift || '',
  });
  // Update other personal information.
  const updatePersonalData = () => {
    // Collect the data into the required object format (IUserProfile).
    console.log('updating personal data:', fields);
    updateUser(fields)
      .then(() => toast({
        title: 'Info updated!',
        description: "Business information changes were applied.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Update failed!',
        description: "Business information changes were not applied.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      }));
  }

  if (!personalData) {
    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='40px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Business Information
        </Text>
        <Text fontSize='md' color={textColorSecondary}>
          Here you can change your business account information
        </Text>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }}>

        <Stack>

          {/* Business name field. */}
          <InputField type='string' mb='25px' id='businessName' label='Business name' placeholder='CompanyName Inc.' value={fields.businessName} onChange={(e: any) => setFields({ ...fields, businessName: e.target.value })} />

        </Stack>

        <Grid templateColumns='repeat(2, 1fr)' gap={4}>

          {/* Business location fields. */}

          {/* The street field. */}
          <InputField type='string' mb='25px' id='street' label='Street' placeholder='Berlinerstroos' value={fields.street} onChange={(e: any) => setFields({ ...fields, street: e.target.value })} />
          {/* The zipcode field. */}
          <InputField type='string' mb='25px' id='zicode' label='ZipCode' placeholder='4736' value={fields.zip} onChange={(e: any) => setFields({ ...fields, zip: e.target.value })} />
          {/* The city field. */}
          <InputField type='string' mb='25px' id='city' label='City' placeholder='Luxembourg' value={fields.city} onChange={(e: any) => setFields({ ...fields, city: e.target.value })} />
          {/* The country field. */}
          <InputField type='string' mb='25px' id='country' label='Country' placeholder='Luxembourg' value={fields.country} onChange={(e: any) => setFields({ ...fields, country: e.target.value })} />

        </Grid>

        <Grid templateColumns='repeat(2, 1fr)' gap={4}>

          {/* The street field. */}
          <InputField type='string' mb='25px' id='companyRegistrarNr' label='Company Registrar' placeholder='HR3242734' value={fields.companyRegistrarNr} onChange={(e: any) => setFields({ ...fields, companyRegistrarNr: e.target.value })} />
          {/* The zipcode field. */}
          <InputField type='string' mb='25px' id='vatCode' label='VAT Code' placeholder='47362736' value={fields.vatCode} onChange={(e: any) => setFields({ ...fields, vatCode: e.target.value })} />
          {/* The city field. */}
          <InputField type='string' mb='25px' id='iban' label='IBAN' placeholder='BE09 8374 3657 2347' value={fields.iban} onChange={(e: any) => setFields({ ...fields, iban: e.target.value })} />
          {/* The country field. */}
          <InputField type='string' mb='25px' id='bankName' label='Bank Name' placeholder='Wise' value={fields.bankName} onChange={(e: any) => setFields({ ...fields, bankName: e.target.value })} />
          {/* The country field. */}
          <InputField type='string' mb='25px' id='bankSwift' label='Bank SWIFT Code' placeholder='TRWIBEB1XXX' value={fields.bankSwift} onChange={(e: any) => setFields({ ...fields, bankSwift: e.target.value })} />

        </Grid>

      </SimpleGrid>

      <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => updatePersonalData()}>
        Save changes
      </Button>
    </Card>
  );
}
