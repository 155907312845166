import { Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import ContractEdit from "components/DashboardContracts/ContractWizard/ContractEdit";
import ContractWizard from "components/DashboardContracts/ContractWizard/ContractWizard";
import BasicModal from "components/Modal/BasicModal";
import { IModalStateAction, useAppContext } from "../../state/useAppState"

const ContractModal = () => {
  // The app wide modal state props.
  const { modalState, setModalState } = useAppContext();
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  
  return (
    <BasicModal title={modalState.title}>

      <ContractEdit />

    </BasicModal>
  )
}

export default ContractModal