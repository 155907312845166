/* eslint-disable */

import { Box, Flex, Table, Tbody, Td, Thead, Tr, useColorModeValue } from '@chakra-ui/react';
// Custom components
import * as React from 'react';
import { IInvoiceItem } from '../InvoiceContent';
import { InvoiceTableHeader } from './InvoiceTableHeader';
import { InvoiceTableRow } from './InvoiceTableRow';

type RowObj = {
	item: string;
	quantity: number;
	rate: number;
	amount: number;
};

// const columns = columnsDataCheck;
export default function InvoiceTable(props: { items: IInvoiceItem[] }) {

	return (
		<Flex direction='column' w='100%' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Box>
				<Table variant='simple' color='gray.500' mt='12px'>
					<Thead>
            <Tr>
              <InvoiceTableHeader label="ITEM" />
              <InvoiceTableHeader label="QUANTITY" />
              <InvoiceTableHeader label="RATE" />
              <InvoiceTableHeader label="AMOUNT" />
            </Tr>
					</Thead>
					<Tbody>
						{props.items.map((item: IInvoiceItem) =>
							<InvoiceTableRow
                key={item.id} 
                label={item.label}
                quantity={item.quantity}
                rate={item.rate}
                total={item.total}
              />
						)}
					</Tbody>
				</Table>
			</Box>
		</Flex>
	);
}
