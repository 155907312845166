import { Flex, Text, useColorModeValue } from "@chakra-ui/react"
import Card from "components/card/Card"
import { format } from 'date-fns';

export const InvoiceContentHeader = (props: {
  total: number,
  dueDate: number,
  buyerInformation: any,
}) => {
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const bgCard = useColorModeValue('white', 'navy.700');

  return (
    <Card backgroundRepeat='no-repeat' bg={bgCard} p='30px' mb='30px' mt='-100px'>
      <Flex direction={{ base: 'column', md: 'row' }}>
        <Flex direction='column' me='auto' mb={{ base: '30px', md: '0px' }}>
          <Text w='max-content' mb='8px' fontSize='md' color='secondaryGray.600' fontWeight='400'>
            Invoice for:
          </Text>
          <Text color={textColor} fontSize='xl' fontWeight='700'>
            {props.buyerInformation?.businessName}
          </Text>
          <Text
            w='max-content'
            mb='10px'
            fontSize='md'
            color='secondaryGray.600'
            fontWeight='400'
            lineHeight='26px'
          >
            {props.buyerInformation?.street}, {props.buyerInformation?.city} 
            <br /> {props.buyerInformation?.country}, {props.buyerInformation?.zip}
          </Text>
        </Flex>
        <Flex direction='column'>

          {/* Label. */}
          <Text w='max-content' mb='4px' fontSize='md' color='secondaryGray.600' fontWeight='400'>
            Amount due
          </Text>

          {/* The total. */}
          <Text color={textColor} fontSize='24px' fontWeight='700'>{props.total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>

          {/* Due date. */}
          <Text
            w='max-content'
            mb='10px'
            fontSize='md'
            p='6px 12px'
            bg='linear-gradient(108.54deg, #FF416C 6.56%, #FF4B2B 95.2%)'
            color='white'
            borderRadius='10px'
            fontWeight='700'
          >
            {format(new Date(props.dueDate), 'dd/MM/yyyy')}
          </Text>
        </Flex>
      </Flex>
    </Card>
  )
}