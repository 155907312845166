import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { onSnapshot, QuerySnapshot, DocumentData, Unsubscribe } from "firebase/firestore";
import { IProject } from "./Dto";
import { useAuth } from "state/useAuthState";
import { projectsService } from "./ProjectsService";
import { preferenceService } from "state/LocalStorage/preference.service";
import { IUserProfile, usersService } from "state/Users/UsersService";

export interface IProjectsProvider {
  projects: IProject[];
  setCurrentProject: (project: IProject) => void;
  currentProject: IProject;
}

export const ProjectsContext = createContext(null);
export const useProjects = (): IProjectsProvider => useContext(ProjectsContext);

const ProjectsProvider = (props: { children: any }) => {
  const PROJECT_SETTINGS_KEY = '_selected_project';
  /**
   * Holds the list of all contracts on which the current
   * user is listed as a seller of a service.
   */
  const [projects, setProjects] = useState<IProject[]>([]);
  // Holds the currently selected quantifier.
  const [currentProject, setCurrentProject] = useState<IProject>(null);
  // Get the currently logged in user.
  const { currentUser, personalData } = useAuth();
  /**
   * Holds the list of all subscriptions from
   * which we need to unsubscribe when this
   * provider unloads.
   */
  const [subscriptions, setSubscriptions] = useState<Unsubscribe[]>([]);

  const getProjectsForUserId = (userId: string): Unsubscribe => {
    const queryUserProjects = projectsService.getQueryProjectsByUserId(userId);
    return onSnapshot(queryUserProjects, (snapshot: QuerySnapshot<DocumentData>) => {
      const foundProjects = snapshot.docs.map(async (project: any) => {
        // Get the project document data.
        const projectData = project.data();
        // console.log('userIds:', projectData.userIds);
        return usersService.fetchByUserIdsInDb(projectData.userIds)
          .then((users: IUserProfile[]) => {
            const foundUsers: IUserProfile[] = [];
            // Get the avatar for each of the returned users.
            users.forEach((user: IUserProfile) => {
              usersService.getAvatarInStorage(user.userId)
                .then((avatar: string) => foundUsers.push(({
                  ...user,
                  avatar
                })));
            })
            return ({
              id: project.id,
              ...projectData,
              userObjects: foundUsers
            })
          });
      });

      // ({
      //   id: project.id,
      //   ...projectData,
      //   userObjects: users
      // })
      foundProjects.forEach((project: Promise<IProject>) =>
        project.then((proj: IProject) => {
          setProjects([...projects, proj])
        })
      );
    });
  }

  /**
   * Read the list of projects and setup a
   * snapshot so we can have live updates.
   */
  useEffect(() => {
    if (!currentUser || !personalData) {
      return
    }
    const unsubscribeFromProjectsList = getProjectsForUserId(personalData.userId);
    setSubscriptions([unsubscribeFromProjectsList]);

    return () => {
      subscriptions.forEach(unsub => unsub());
    };
  }, [currentUser, personalData]);

  /**
   * Make sure to save locally the currently
   * selected project as default each time
   * it changes.
   */
  useEffect(() => {
    if (!currentProject) return;
    preferenceService.savePreference(personalData.userId, PROJECT_SETTINGS_KEY, currentProject);
  }, [currentProject]);

  /**
   * Check if there is any project saved as a default
   * in the local user data and if not then pick the
   * first project that the user has access to.
   *
   * @param foundProjects IProject[]
   * @returns void
   */
  const setDefaultProject = (foundProjects: IProject[]): void => {
    // Check if there is a default project saved in the user local data.
    let preselectedProject: IProject = null;
    // Check if there is a project stored as preference in the browser localstorage.
    const savedProject = preferenceService.getPreference(personalData.userId, PROJECT_SETTINGS_KEY);
    // Check if there is a project marked as default from the ones fetched.
    if (!savedProject) {
      // Try to get the project from the list that is marked as default. 
      let defaultProject = foundProjects.find((c: IProject) => !!c.default);
      /**
       * If there is none marked as default then mark the first one as default
       * and set is as current project for the context.
       */
      if (!defaultProject && !!foundProjects?.length) {
        defaultProject = foundProjects[0];
      }
      // Save the found default one in the user preferences storage.
      preferenceService.savePreference(personalData.userId, PROJECT_SETTINGS_KEY, defaultProject);
      // Select the current project for the context.
    } else {
      preselectedProject = savedProject;
    }
    setCurrentProject(preselectedProject);
  }

  const value: IProjectsProvider = {
    projects,
    currentProject,
    setCurrentProject,
  };

  return (
    <ProjectsContext.Provider value={value}>
      {props.children}
    </ProjectsContext.Provider>
  )
}

export default ProjectsProvider