import { Td, Tr, Text, useColorModeValue } from "@chakra-ui/react"
import { TimeHelper } from "logic/Time.helper";

export const InvoiceTableRow = (props: {
  label: string,
  quantity: number,
  rate: number,
  total: number,
}) => {
  const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
	const textColor = useColorModeValue('navy.700', 'white');
  const timeHelper = new TimeHelper();

  return (
    <Tr>

      {/* The item label. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor={borderColor}
        mt='20px !important'
        py='36px !important'>
        <Text color={textColor} fontSize='md' fontWeight='500'>{props.label}</Text>
      </Td>

      {/* Quantity. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor={borderColor}
        mt='20px !important'
        py='36px !important'>
        <Text color={textColor} fontSize='md' fontWeight='500'>{timeHelper.humanize(props.quantity)}</Text>
      </Td>

      {/* Rate. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor={borderColor}
        mt='20px !important'
        py='36px !important'>
        <Text color={textColor} fontSize='md' fontWeight='500'>{props.rate.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>
      </Td>

      {/* Total. */}
      <Td
        fontSize={{ sm: '14px' }}
        minW={{ sm: '150px', md: '200px', lg: 'auto' }}
        borderColor={borderColor}
        mt='20px !important'
        py='36px !important'>
        <Text color={textColor} fontSize='md' fontWeight='500'>{props.total.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</Text>
      </Td>
    </Tr>
  )
}