// Chakra imports
import { Button, Flex, FormControl, Grid, SimpleGrid, Skeleton, Stack, Text, useColorModeValue, useToast } from '@chakra-ui/react';
import Card from 'components/card/Card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { useState } from 'react';
import { useAuth } from 'state/useAuthState';
import { IUserProfile } from 'state/Users/UsersService';

export default function InvoicingInfoSettings() {
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue('secondaryGray.900', 'white');
  const textColorSecondary = 'secondaryGray.600';
  const toast = useToast();
  const { updateUser, personalData } = useAuth();
  // Holds the field values.
  const [fields, setFields] = useState<IUserProfile>({
    ...personalData,
    invoicePrefix: personalData?.invoicePrefix || '',
    invoiceItemLabel: personalData?.invoiceItemLabel || '',
    invoicePaymentDueDays: personalData?.invoicePaymentDueDays || 30,
    invoiceNote: personalData?.invoiceNote || '',
  });
  // Update other personal information.
  const updatePersonalData = () => {
    // Collect the data into the required object format (IUserProfile).
    updateUser(fields)
      .then(() => toast({
        title: 'Info updated!',
        description: "Business information changes were applied.",
        status: 'success',
        duration: 9000,
        isClosable: true,
      }))
      .catch(() => toast({
        title: 'Update failed!',
        description: "Business information changes were not applied.",
        status: 'error',
        duration: 9000,
        isClosable: true,
      }));
  }

  if (!personalData) {
    return (
      <Stack>
        <Skeleton height='20px' />
        <Skeleton height='20px' />
        <Skeleton height='20px' />
      </Stack>
    )
  }

  return (
    <Card mb='20px'>
      <Flex direction='column' mb='40px' ms='10px'>
        <Text fontSize='xl' color={textColorPrimary} fontWeight='bold'>
          Invoice Settings
        </Text>
        <Text fontSize='md' color={textColorSecondary}>
          Here you can make changes to your invoicing preferences
        </Text>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 1 }} spacing={{ base: '20px', xl: '20px' }} mb={5} gap={1}>

          {/* Invoice prefix. */}
          <InputField
            type='string'
            id='invoicePrefix'
            label='Invoice number prefix'
            placeholder='INV####'
            value={fields.invoicePrefix}
            onChange={(e: any) => setFields({ ...fields, invoicePrefix: e.target.value })}
            explanation="The prefix to be used before each invoice that is generated. Changing this value will also affect all invoices that have been generated."
          />
          {/* The item label. */}
          <InputField
            type='string'
            mb='25px'
            id='invoiceItemLabel'
            label='The item label'
            placeholder='Consulting services...'
            value={fields.invoiceItemLabel} onChange={(e: any) => setFields({ ...fields, invoiceItemLabel: e.target.value })}
            explanation="The text to show on the invoice item that is being invoiced."
          />
          {/* Payment due in days. */}
          <InputField
            type='number'
            mb='25px'
            id='invoicePaymentDueDays'
            label='Due in days'
            placeholder='30 (days)'
            value={fields.invoicePaymentDueDays} onChange={(e: any) => setFields({ ...fields, invoicePaymentDueDays: e.target.value })}
            explanation="The delay in days during which the invoice receiver is legally entitled to pay. This is usually mentioned in the contract agreement."
          />
          {/* The note. */}
          <InputField
            type='string'
            mb='25px'
            id='invoiceNote'
            label='Footer note'
            placeholder='Sample note...'
            value={fields.invoiceNote} onChange={(e: any) => setFields({ ...fields, invoiceNote: e.target.value })}
            explanation="A footer note to add to all the invoices."
          />

      </SimpleGrid>

      <Button variant='brand' minW='183px' fontSize='sm' fontWeight='500' ms='auto' onClick={() => updatePersonalData()}>
        Save changes
      </Button>
    </Card>
  );
}
