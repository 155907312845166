import { Button, Icon, useColorMode, useColorModeValue, Text, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface Props {
  callback: any;
  label?: string;
  icon?: any;
  color?: string;
  ml?: string;
  mr?: string;
  disabled?: boolean;
}

const CustomButton: React.FC<Props> = ({
  callback,
  label,
  icon,
  color,
  ml,
  mr,
  disabled,
}) => {
  const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
  const bgHover = useColorModeValue({ bg: 'secondaryGray.400' }, { bg: 'whiteAlpha.50' });
  const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const iconColor = useColorModeValue(color || 'brand.500', 'white');

  return (
    <Button
      alignItems='center'
      justifyContent='center'
      bg={bgButton}
      _hover={bgHover}
      _focus={bgFocus}
      _active={bgFocus}
      w={label ? '100%' : '37px'}
      h='37px'
      lineHeight='100%'
      borderRadius='10px'
      onClick={() => callback()}
      ml={ml}
      mr={mr}
      disabled={disabled}
    >
      <Flex direction='row' justifyContent='flex-start' alignItems='center' gap={2}>
        {icon && <Icon as={icon} color={color || iconColor} w='24px' h='24px' />}
        {label && <Text color={textColor} fontSize='sm' fontWeight='500'>{label}</Text>}
      </Flex>
    </Button>
  )
}

export default CustomButton;