import { Box, Flex, FormControl, Text, Textarea, useColorModeValue } from '@chakra-ui/react';
// Custom components
import * as React from 'react';
import { IEntry } from 'state/Tasks/TasksDTO';
import TicketRightInfo from '../Other/TicketRightInfo';

interface Props {
  entry: IEntry;
  readonly: boolean;
  currentLabel: string;
  handleTaskClick: any;
  inputRef: any;
  handleLabelChange: any;
  handleKeyPress: any;
  handleDurationChange: any;
  handleEditTags: any;
  handleCopyToClipboard: any;
  handleDelete: any;
}

const TicketCardHeader: React.FC<Props> = ({
  entry,
  readonly,
  currentLabel,
  handleTaskClick,
  inputRef,
  handleLabelChange,
  handleKeyPress,
  handleDurationChange,
  handleEditTags,
  handleCopyToClipboard,
  handleDelete
}) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex direction={{ sm: 'column', md: 'row' }} justifyContent='space-between' alignItems={{ md: 'center ' }} mb='20px'>

        {/* The description. */}
        {readonly &&
          <Text
            fontWeight='700'
            fontSize='lg'
            color={textColor}
            onClick={() => handleTaskClick(entry.id)}
            whiteSpace='pre-line'
          >
            {entry.label}
          </Text>
        }

        {/* The input. */}
        {!readonly &&
          <Box w='90%'>
            <FormControl>
              <Textarea
                ref={inputRef}
                isRequired={true}
                fontSize='lg'
                p='0'
                m='2px'
                ms={{ base: "0px", md: "0px" }}
                placeholder='The content of the ticket comes here...'
                fontWeight='700'
                onChange={(event: any) => handleLabelChange(entry.id, event)}
                onKeyDown={(event: any) => handleKeyPress(event)}
                onKeyPress={(event: any) => handleKeyPress(event)}
                value={currentLabel}
              />
            </FormControl>
          </Box>
        }

        {/* The right side. */}
        <Flex w={{ sm: '100%', md: '250px' }} mt={{ sm: 3, md: 0 }} justifyContent={{ sm: 'flex-start', md: 'flex-end' }} alignItems={{ sm: 'flex-start', md: 'center' }}>
          <TicketRightInfo
            entry={entry}
            handleDurationChange={handleDurationChange}
            handleEditTags={handleEditTags}
            handleCopyToClipboard={handleCopyToClipboard}
            handleDelete={handleDelete}
          />
        </Flex>
      </Flex>
  )
}

export default TicketCardHeader